import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {SimyoNormalizadorService} from '../../services/simyo-normalizador.service';
import {SimyoService} from '../../services/simyo.service';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {SimyoModalAddressComponent} from '../simyo-modal-address/simyo-modal-address.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from 'src/environments/environment';
import {PermissionService} from 'src/app/shared/services/permission.service';
import {availableTechnologies, technologyIVTypes, technologyTypes} from 'src/app/shared/constantes';
import {allPermissions} from 'src/app/shared/permissions';
import {getUnique} from 'src/app/utils/getUniqueFromArray';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';

@Component({
    selector: 'app-simyo-cobertura',
    templateUrl: './simyo-cobertura.component.html',
    styleUrls: ['./simyo-cobertura.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoCoberturaComponent implements OnInit, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() address: any;
    @Input() retryCoverageEvents: Observable<void>;

    public formularioNormalizacion: FormGroup;
    public muestraCobertura: boolean;
    public muestraErrorPeticion = false;
    public muestraBotonesCobertura = false;
    public muestraBotonNinguna = false;
    public isLoading = false;
    public textDefault = 'Comprobar cobertura';
    public datosNormalizacion: any = null;
    public localidades: any = null;
    public direccionesSugeridas: any = null;
    public plantas: any = null;
    public bisOption: any = null;
    public bloqueOption: any = null;
    public bloqueLabel: any = null;
    public portalOption: any = null;
    public escaleraOption: any = null;
    public tipoPuertaOption: any = null;
    public puertaOption: any = null;
    public consulta: any = null;
    public msgErrorPeticion: any;
    public orderDraftId = null;
    public htmlErrorCobertura: string = 'Lo sentimos, no tienes cobertura de fibra a través de la red de Simyo';

    public pdv: any;
    public typeOrder: string;
    public loadIframeMode: boolean = false;
    public environment = environment;
    private allSubscription: Subscription[] = [];

    constructor(
        private normalizadorService: SimyoNormalizadorService,
        private simyoService: SimyoService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private permSv: PermissionService
    ) {
        this.route.queryParams
            .subscribe(params => {
                if (params && params.frame && params.frame === 'true') {
                    this.loadIframeMode = true;
                }
            });
    }

    ngOnInit(): void {
        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                if (data != null) {
                    this.typeOrder = data;
                }
            })
        );

        this.allSubscription.push(this.normalizadorService.getMuestraCobertura()
            .subscribe((value) => {
                this.muestraCobertura = value;
            })
        );

        // Recogemos los datos de la normalización cuando hay cambios
        this.allSubscription.push(this.normalizadorService.getDatosNormalizacion()
            .subscribe((valor) => {
                this.datosNormalizacion = valor;
            })
        );

        // Rellenamos las localidades
        this.allSubscription.push(this.normalizadorService.getLocalidades()
            .subscribe(async (local) => {
                this.localidades = local;
                await this.callIfOnlyOneResult();
            })
        );

        // Recuperamos el formulario de normalización
        this.allSubscription.push(this.normalizadorService.getFormularioNormalizacion()
            .subscribe((formulario) => {
                this.formularioNormalizacion = formulario;
            })
        );

        // Errores petición http o direcciones vacias
        this.allSubscription.push(this.normalizadorService.getErrorPeticionCobertura()
            .subscribe((value) => {
                this.muestraErrorPeticion = value;
            })
        );

        this.allSubscription.push(this.normalizadorService.getMsgErrorCobertura()
            .subscribe((value) => {
                this.msgErrorPeticion = value;
            })
        );

        this.allSubscription.push(this.retryCoverageEvents
            .subscribe(() =>
                this.onConsultarCobertura()
            )
        );

        if (environment.enableMyAddress) {
            this.allSubscription.push(this.formGroup.valueChanges
                .subscribe((value) => {
                    const rawForm = this.formGroup.getRawValue();
                    this.muestraBotonesCobertura = Boolean(rawForm.plantaCobertura);
                    this.muestraCobertura = Boolean(rawForm.plantaCobertura);
                 })
            );
        }
    }

    callIfOnlyOneResult() {
        if (this.localidades !== null && this.localidades.length === 1) {
            this.formGroup.get('localidadCobertura').patchValue(this.localidades[0]);
            this.onChangeLocalidad(this.localidades[0]);
        }
    }

    // SETTERS
    // Filtramos y seteamos el valor de las direcciones sugeridas
    setDireccionesSugeridas(localidad) {
        const dirSugeridas = this.datosNormalizacion
            .filter((el) => {
                if (el.streetName == null) {
                    return null;
                }
                // return el.city ? el.city.toUpperCase() : el.city === localidad ? localidad.toUpperCase() : localidad;
                return el.city && el.city !== '' ? (el.city.toUpperCase() === localidad.toUpperCase()) : null;
            })
            .map((el) => {
                const valorTexto = (el.streetType ? el.streetType.toUpperCase() + ' , ' : '') +
                    (el.streetName ? el.streetName.toUpperCase() + ' , ' : '') +
                    (el.streetNr ? el.streetNr + ' , ' : '') +
                    (el.postCode ? el.postCode : '');
                return {
                    streetType: el.streetType ? el.streetType.toUpperCase() : el.streetType,
                    streetName: el.streetName ? el.streetName.toUpperCase() : el.streetName,
                    streetNr: el.streetNr,
                    postCode: el.postCode,
                    stateOrProvince: el.stateOrProvince,
                    texto: valorTexto
                };
            });

        this.direccionesSugeridas = getUnique(dirSugeridas, 'texto');
        if (this.direccionesSugeridas.length === 1) {
            this.onChangeDireccionesSugeridas(this.direccionesSugeridas[0]);
        }

    }

    setPlantas(dir) {
        const auxPlantas = this.datosNormalizacion
            .filter((el) => {
                return (el.city ? el.city.toUpperCase() : el.city) === (this.formGroup.value.localidadCobertura ? this.formGroup.value.localidadCobertura.toUpperCase() : this.formGroup.value.localidadCobertura) &&
                    (el.streetType ? el.streetType.toUpperCase() : el.streetType) === (dir.streetType ? dir.streetType.toUpperCase() : dir.streetType) &&
                    (el.streetName ? el.streetName.toUpperCase() : el.streetName) === (dir.streetName ? dir.streetName.toUpperCase() : dir.streetName) &&
                    el.streetNr === dir.streetNr &&
                    el.postCode === dir.postCode;
            });
        if (auxPlantas && auxPlantas.length) {
            this.plantas = auxPlantas[0].vertical_addresses
                .map((el) => el.floorNumber_translation)
                .filter((value, index, self) => self.indexOf(value) === index)
                .map(p => {
                    return {id: p, value: p}
                });
            this.plantas = [{id: "", value: "Selecciona"}, ...this.plantas];
        } else {
            this.plantas = null;
        }
    }

    // Asignamos en este punto las direcciones filtradas por si solo queda una (no hay opciones que mostrar)
    setConsulta() {
        const auxConsulta = this.datosNormalizacion
            .filter((el) => {
                return (el.city ? el.city.toUpperCase() : el.city) === (this.formGroup.value.localidadCobertura ? this.formGroup.value.localidadCobertura.toUpperCase() : this.formGroup.value.localidadCobertura) &&
                    (el.streetType ? el.streetType.toUpperCase() : el.streetType) === (this.formGroup.value.tipoViaCobertura ? this.formGroup.value.tipoViaCobertura.toUpperCase() : this.formGroup.value.tipoViaCobertura) &&
                    (el.streetName ? el.streetName.toUpperCase() : el.streetName) === (this.formGroup.value.nombreViaCobertura ? this.formGroup.value.nombreViaCobertura.toUpperCase() : this.formGroup.value.nombreViaCobertura) &&
                    el.streetNr === this.formGroup.value.numeroCobertura &&
                    el.postCode === this.formGroup.value.codigoPostalCobertura;
            });
        if (auxConsulta && auxConsulta.length) {
            this.consulta = auxConsulta[0].vertical_addresses
                .filter((el) => el.floorNumber_translation === this.formGroup.value.plantaCobertura);
        } else {
            this.consulta = null;
        }
    }

    // Consultamos si hay opciones para bis duplicado
    setBis(selectedEmpty?) {
        let options = this.consulta
            // .filter((el) => el.streetNrSuffix_translation)
            .map((el) => el.streetNrSuffix_translation ? el.streetNrSuffix_translation : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index)

        if (options && options.length === 1 && options[0] === 'No tiene' && this.formGroup.get('bisCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.bisOption = null;
        } else  {
            options = options.map(op => {
                return {id: op, value: op}
            });
            let emptyOption = {id: "", value: "Selecciona"};
            this.bisOption = options.length >= 5 ? [emptyOption, ...options] : options;
        }
    }

    // Consultamos si hay opciones para bloque
    setBloque(selectedEmpty?) {
        let options = this.consulta
            // .filter((el) => el.geographicSubAddress[0].subUnitNumber_translation)
            .map((el) => el.geographicSubAddress[0].subUnitNumber_translation ? el.geographicSubAddress[0].subUnitNumber_translation : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (options && options.length === 1 && options[0] === 'No tiene' && this.formGroup.get('bloqueCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.bloqueOption = null;
        } else  {
            options = options.map(op => {
                return {id: op, value: op}
            });
            let emptyOption = {id: "", value: "Selecciona"};
            this.bloqueOption = options.length >= 5 ? [emptyOption, ...options] : options;
        }

        this.bloqueLabel = this.consulta
            .filter((el) => el.geographicSubAddress[0].subUnitType_translation)
            .map((el) => el.geographicSubAddress[0].subUnitType_translation)
            .filter((value, index, self) => self.indexOf(value) === index);

        if (this.bloqueLabel && this.bloqueLabel.length > 0) {
            this.bloqueLabel = this.bloqueLabel[0];
        }
    }

    // Consultamos si hay opciones para portal
    setPortal(selectedEmpty?) {
        let options = this.consulta
            // .filter((el) => el.geographicSubAddress[0].buildingName)
            .map((el) => el.geographicSubAddress[0].buildingName ? el.geographicSubAddress[0].buildingName : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (options && options.length === 1 && options[0] === 'No tiene' && this.formGroup.get('portalCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.portalOption = null;
        } else {
            options = options.map(op => {
                return {id: op, value: op}
            });
            let emptyOption = {id: "", value: "Selecciona"};
            this.portalOption = options.length >= 5 ? [emptyOption, ...options] : options;
        }
    }

    // Consultamos si hay opciones para escalera
    setEscalera(selectedEmpty?) {
        let options = this.consulta
            // .filter((el) => el.geographicSubAddress[0].stairCase_translation)
            .map((el) => el.geographicSubAddress[0].stairCase_translation ? el.geographicSubAddress[0].stairCase_translation : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (options && options.length === 1 && options[0] === 'No tiene' && this.formGroup.get('escaleraCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.escaleraOption = null;
        } else {
            options = options.map(op => {
                return {id: op, value: op}
            });
            let emptyOption = {id: "", value: "Selecciona"};
            this.escaleraOption = options.length >= 5 ? [emptyOption, ...options] : options;
        }
    }

    // Consultamos si hay opciones para tipo puerta
    setTipoPuerta(selectedEmpty?) {
        let options = this.consulta
            // .filter((el) => el.geographicSubAddress[0].doorType_translation)
            .map((el) => el.geographicSubAddress[0].doorType_translation ? el.geographicSubAddress[0].doorType_translation : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (options && options.length === 1 && options[0] === 'No tiene' && this.formGroup.get('tipoPuertaCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.tipoPuertaOption = null;
        } else {
            options = options.map(op => {
                return {id: op, value: op}
            });
            let emptyOption = {id: "", value: "Selecciona"};
            this.tipoPuertaOption = options.length >= 5 ? [emptyOption, ...options] : options;
        }
    }

    // Consultamos si hay opciones para puerta
    setPuerta(selectedEmpty?) {
        let options = this.consulta
            // .filter((el) => el.geographicSubAddress[0].door)
            .map((el) => el.geographicSubAddress[0].door ? el.geographicSubAddress[0].door : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (options && options.length === 1 && options[0] === 'No tiene' && this.formGroup.get('puertaCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.puertaOption = null;
        } else {
            //this.puertaOption = options
            options = options.map(op => {
                return {id: op, value: op}
            });
            let emptyOption = {id: "", value: "Selecciona"};
            this.puertaOption = options.length >= 5 ? [emptyOption, ...options] : options;
        }
    }

    // METODOS ON
    onChangeLocalidad(localidad) {
        // Limpiamos todas las variables para que afecte a la vista
        this.formGroup.patchValue({
            direccionesCobertura: '',
            tipoViaCobertura: '',
            nombreViaCobertura: '',
            numeroCobertura: '',
            codigoPostalCobertura: '',
            provinciaCobertura: '',
            plantaCobertura: '',
            bisCobertura: null,
            bloqueCobertura: null,
            portalCobertura: null,
            escaleraCobertura: null,
            tipoPuertaCobertura: null,
            puertaCobertura: null
        });
        this.direccionesSugeridas = null;
        this.plantas = null;
        this.bisOption = null;
        this.bloqueOption = null;
        this.portalOption = null;
        this.escaleraOption = null;
        this.tipoPuertaOption = null;
        this.puertaOption = null;
        // Ocultamos botones de consulta
        this.muestraBotonesCobertura = false;
        this.muestraBotonNinguna = false;
        this.formGroup.get('localidadCobertura').patchValue(localidad);
        this.setDireccionesSugeridas(localidad);
    }

    onChangeDireccionesSugeridas(dir) {
        this.formGroup.patchValue({
            plantaCobertura: '',
            bisCobertura: null,
            bloqueCobertura: null,
            portalCobertura: null,
            escaleraCobertura: null,
            tipoPuertaCobertura: null,
            puertaCobertura: null
        });
        this.plantas = null;
        this.bisOption = null;
        this.bloqueOption = null;
        this.portalOption = null;
        this.escaleraOption = null;
        this.tipoPuertaOption = null;
        this.puertaOption = null;
        // Mostramos boton ninguna anteriores
        this.muestraBotonNinguna = true;
        this.muestraBotonesCobertura = false;
        // Asignamos los valores al formGroup para que sea accesible desde todo el formulario
        this.formGroup.get('direccionesCobertura').patchValue(dir.texto ? dir.texto : (dir.streetName ? dir.streetName.toUpperCase() : dir.streetName));
        this.formGroup.get('tipoViaCobertura').patchValue(dir.streetType);
        this.formGroup.get('nombreViaCobertura').patchValue(dir.streetName ? dir.streetName.toUpperCase() : dir.streetName);
        this.formGroup.get('numeroCobertura').patchValue(dir.streetNr);
        this.formGroup.get('codigoPostalCobertura').patchValue(dir.postCode);
        this.formGroup.get('provinciaCobertura').patchValue(dir.stateOrProvince ? dir.stateOrProvince.toUpperCase() : dir.stateOrProvince);

        this.setPlantas(dir);
    }

    onChangePlanta() {
        this.resetControlsForm();
        // Limpiamos todas las variables para que afecte a la vista
        this.formGroup.patchValue({
            bisCobertura: null,
            bloqueCobertura: null,
            portalCobertura: null,
            escaleraCobertura: null,
            tipoPuertaCobertura: null,
            puertaCobertura: null
        });
        this.bisOption = null;
        this.bloqueOption = null;
        this.portalOption = null;
        this.escaleraOption = null;
        this.tipoPuertaOption = null;
        this.puertaOption = null;
        // Mostramos botones de consulta
        this.muestraBotonesCobertura = true;
        // Rellenamos las opciones si hay resultado para ellas
        this.setConsulta();
        this.setBis();
        this.setBloque();
        this.setPortal();
        this.setEscalera();
        this.setTipoPuerta();
        this.setPuerta();
    }

    // Retroalimentamos la consulta para que solo nos muestre las opciones correctas para lo seleccionado
    onSelectOpcion(key, value) {
        let selectedEmptyMain = false;
        let selectedEmpty = {
            bis: false,
            bloque: false,
            portal: false,
            escalera: false,
            tipoPuerta: false,
            puerta: false
        };
        if (value === '') {
            return;
        }
        if (value === 'No tiene') {
            value = null;
            selectedEmptyMain = true;
        }
        // bis
        if (key === 'streetNrSuffix') {
            this.consulta = this.consulta
                .filter((el) => el[key + '_translation'] === value)
                .map((el) => el);

            selectedEmpty.bis = selectedEmptyMain;
        }
        // Bloque
        if (key === 'subUnitNumber') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key + '_translation'] === value)
                .map((el) => el);

            selectedEmpty.bloque = selectedEmptyMain;
        }
        // Portal
        if (key === 'buildingName') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key] === value)
                .map((el) => el);

            selectedEmpty.portal = selectedEmptyMain;
        }
        // Escalera
        if (key === 'stairCase') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key + '_translation'] === value)
                .map((el) => el);

            selectedEmpty.escalera = selectedEmptyMain;
        }
        // Tipo puerta
        if (key === 'doorType') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key + '_translation'] === value)
                .map((el) => el);

            selectedEmpty.tipoPuerta = selectedEmptyMain;
        }
        // Puerta
        if (key === 'door') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key] === value)
                .map((el) => el);

            selectedEmpty.puerta = selectedEmptyMain;
        }
        this.setBis(selectedEmpty.bis);
        this.setBloque(selectedEmpty.bloque);
        this.setPortal(selectedEmpty.portal);
        this.setEscalera(selectedEmpty.escalera);
        this.setTipoPuerta(selectedEmpty.tipoPuerta);
        this.setPuerta(selectedEmpty.puerta);
        this.addAllValidationsCobertura();
    }

    onConsultarCobertura() {
        let currentZip = this.formGroup.get('codigoPostalCobertura').value;

        if (this.formularioNormalizacion != null && (currentZip === null || currentZip === '')) {
            currentZip = this.formularioNormalizacion.get('codigoPostalNormaliza').value;
            this.formGroup.get('codigoPostalCobertura').patchValue(currentZip);
        }

        this.normalizadorService.setErrorPeticionCobertura(false);
        this.normalizadorService.setThereIsCoverage(undefined);
        this.normalizadorService.setMsgErrorCobertura('');
        this.addAllValidationsCobertura();
        // this.compruebaSiExisteConsulta();
        this.validateAllFormFields(this.formGroup);
        if (this.formGroup.status === 'VALID') {
            this.onChangeLoading(true);
            this.formGroup.disable();
            // Seteamos el código de provincia del normalizador ante errores del servicio por "provincia incorrecta"
            this.formGroup.get('provinciaCobertura').patchValue(this.formularioNormalizacion.get('provinciaNormaliza').value);
            this.normalizadorService.setResultadoCobertura(this.formGroup);
            this.normalizadorService.setMuestraNormalizacion(false);
            this.normalizadorService.setMuestraCobertura(false);

            let body;

            if (environment.enableMyAddress) {
                body = this.getBodyMyaddress()
            } else {
                if (this.consulta.length > 1) {
                    const found = this.consulta.find(x => x.geographicSubAddress && x.geographicSubAddress.length && x.geographicSubAddress[0].subUnitNumber_translation === this.formGroup.get('bloqueCobertura').value);
                    body = found ? found : this.consulta[0];
                } else {
                    body = this.consulta[0];
                }
            }
            this.consultaCoberturaSimyo(body);
        } else {
            this.onChangeLoading(false);
        }
    }

    getBodyMyaddress() {
        const formDetail = this.address;
        return {
            "id": null,
            "href": null,
            "ospAddressExternalId": [{
                    "refId": "arvato",
                    "externalId": formDetail?.ospGeographicAddress[0].externalIdentifier.find(item => item.name === 'ospAddressId')?.value
                },
                {
                    "refId": "gescal",
                    "externalId": formDetail?.ospGeographicAddress[0].externalIdentifier.find(item => item.name === 'gescal37')?.value
                }
            ],
            // formDetail.ospGeographicAddress[0].externalIdentifier,
            "ospAccuracy": null,
            "streetNr": formDetail?.ospGeographicAddress[0].streetNr,
            "streetNrSuffix": formDetail?.ospGeographicAddress[0].streetNrSuffix,
            "streetNrLast": formDetail?.ospGeographicAddress[0].streetNrLast,
            "streetNrLastSuffix": formDetail?.ospGeographicAddress[0].streetNrLastSuffix,
            "streetName": formDetail?.ospGeographicAddress[0].streetName,
            "streetType": formDetail?.ospGeographicAddress[0].streetType,
            "postCode": formDetail?.ospGeographicAddress[0].postCode,
            "locality": formDetail?.ospGeographicAddress[0].locality,
            "city": formDetail?.ospGeographicAddress[0].city,
            "ospINECityCode": formDetail?.ospGeographicAddress[0].externalIdentifier.find(item => item.name === 'ospINECityCode')?.value,
            "ospSingularEntity": formDetail?.ospGeographicAddress[0].externalIdentifier.find(item => item.name === 'ospINESingularEntity')?.value,
            "stateOrProvince": formDetail?.ospGeographicAddress[0].stateOrProvince,
            "country": "SPAIN",
            "staircaseNumber": formDetail?.ospGeographicAddress[0].geographicSubAddress[0].stairCase,
            "floorNumber": formDetail?.ospGeographicAddress[0].geographicSubAddress[0].levelNumber,
            "apartmentNumber": null,
            "geocode": null,
            "geographicSubAddress": formDetail?.ospGeographicAddress[0].geographicSubAddress,
            "streetNrSuffix_translation": null,
            // "floorNumber_translation": "Planta 1"
        }
    }

    consultaCoberturaSimyo(body: any) {
        this.normalizadorService.consultaCobertura(body)
            .subscribe((data: any) => {
                if (data.msg) {
                    data = data.msg;
                }
                if (data.error?.errorCode === 500) {
                    this.errorCoberturaSimyo(data.error?.msg);
                } else {
                    if (data.cimaArvatoCode && data.cimaArvatoCode !== '' && data.technology && data.technology.length && availableTechnologies.includes(data.technology[0])) {
                        this.formGroup.get('resultadoConsultaCobertura').patchValue(data);
                        this.clearFormGroup();
                        this.normalizadorService.setResultadoCobertura(this.formGroup);
                        if (data.technology.includes(technologyTypes.dir) || data.technology.includes(technologyTypes.ind_vula)) {
                            this.simyoService.setVirtualInstallationData({
                                gescal37: body?.ospAddressExternalId?.find(x => x.refId === 'gescal')?.externalId,
                                footprint: data.technology.includes(technologyTypes.dir) ? technologyIVTypes.dir : technologyIVTypes.ind_vula
                            });
                        }
                        this.normalizadorService.setThereIsCoverage(true);
                    } else {
                        this.normalizadorService.setThereIsCoverage(false);
                        this.normalizadorService.setClientIsCover(false);
                        this.normalizadorService.setErrorPeticionCobertura(true);
                        this.normalizadorService.setMsgErrorCobertura(this.htmlErrorCobertura);
                    }
                }
                this.onChangeLoading(false);
                this.formGroup.enable();
            }, (error) => {
                this.errorCoberturaSimyo('');
            });
    }

    errorCoberturaSimyo(msg) {
        const msgError = 'Ha ocurrido un error al realizar la consulta. Por favor inténtalo de nuevo.';
        this.normalizadorService.setErrorPeticionCobertura(true);
        this.normalizadorService.setMsgErrorCobertura(msg && msg !== '' ? msg : msgError);
        this.normalizadorService.setThereIsCoverage(null);
        this.onChangeLoading(false);
        this.formGroup.enable();
    }

    // Restablece el formulario a la configuración inicial
    onReset(formCobertura) {
        this.formGroup.reset();
        this.formularioNormalizacion.enable();
        this.formularioNormalizacion.reset();
        this.localidades = null;
        this.direccionesSugeridas = null;
        this.plantas = null;
        this.bisOption = null;
        this.bloqueOption = null;
        this.portalOption = null;
        this.escaleraOption = null;
        this.tipoPuertaOption = null;
        this.puertaOption = null;
        this.normalizadorService.resetNormalizacion();
        this.normalizadorService.setLocalidades(null);
        this.normalizadorService.setErrorPeticionCobertura(false);
        this.normalizadorService.setMsgErrorCobertura('');

        this.resetControlsForm();
        // this.draftService.removeDraft(this.orderDraftId);
        // this.draftService.orderInit();

    }

    // VALIDACIONES
    // Añade validación al elemento enviado, solo si hay resultados para ese elemento
    addRequiredControl(nombreControl, elemento) {
        if (elemento) {
            const identificador = this.formGroup.get(nombreControl);
            if (elemento.length) {
                identificador.setValidators([Validators.required, blankSpaceValidator()]);
            } else {
                this.clearValidations(nombreControl);
            }
        } else {
            this.clearValidations(nombreControl);
        }
    }

    // Añade validaciones para todo el formulario de cobertura
    addAllValidationsCobertura() {
        this.addRequiredControl('bisCobertura', this.bisOption);
        this.addRequiredControl('bloqueCobertura', this.bloqueOption);
        this.addRequiredControl('portalCobertura', this.portalOption);
        this.addRequiredControl('escaleraCobertura', this.escaleraOption);
        this.addRequiredControl('tipoPuertaCobertura', this.tipoPuertaOption);
        this.addRequiredControl('puertaCobertura', this.puertaOption);
    }

    // Reset errores de validación para la consulta ninguna de las anteriores
    resetControlsForm() {
        this.formGroup.get('bisCobertura').clearValidators();
        this.formGroup.get('bloqueCobertura').clearValidators();
        this.formGroup.get('portalCobertura').clearValidators();
        this.formGroup.get('escaleraCobertura').clearValidators();
        this.formGroup.get('tipoPuertaCobertura').clearValidators();
        this.formGroup.get('puertaCobertura').clearValidators();
    }

    // Comprueba si existen errores de validacion y hace saltar estos errores
    validateAllFormFields(formGroup: FormGroup | FormArray): boolean {
        const keysArray = formGroup instanceof FormGroup ? Object.values(formGroup.controls) : formGroup.controls;
        keysArray.forEach(control => {
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: false});
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup || control instanceof FormArray) {
                this.validateAllFormFields(control);
            }
        });
        return formGroup.invalid;
    }

    // Funcion para limpiar las validaciones de un campo del formulario
    clearValidations(nameForm: string) {
        this.formGroup.get(nameForm).clearValidators();
        this.formGroup.get(nameForm).setValidators([]);
        this.formGroup.get(nameForm).updateValueAndValidity();
    }

    // Función para manejar los mensajes de error
    public errorHandling = (control: string, error: string) => {
        if (this.formGroup.get(control).invalid && (this.formGroup.get(control).dirty || this.formGroup.get(control).touched)) {
            return this.formGroup.controls[control].hasError(error);
        }
    };

    // UTILS
    onChangeLoading(state) {
        if (state === true) {
            this.isLoading = true;
            this.textDefault = 'Comprobando ...';
        } else {
            this.isLoading = false;
            this.textDefault = 'Comprobar cobertura';
        }
    }

    clearFormGroup() {
        for (const field in this.formGroup.controls) {
            if (this.formGroup.get(field).value === 'No tiene') {
                this.formGroup.get(field).patchValue(null);
            }
        }
    }

    getValueForCobertura(formGroup, resultCobertura?) {
        if (resultCobertura) {
            formGroup.get('plantaCobertura').patchValue(resultCobertura.floorNumber);
            formGroup.get('bisCobertura').patchValue(resultCobertura.streetNrSuffix);
            if (resultCobertura.geographicSubAddress && resultCobertura.geographicSubAddress.length) {
                formGroup.get('bloqueCobertura').patchValue(resultCobertura.geographicSubAddress[0].subUnitNumber);
                formGroup.get('escaleraCobertura').patchValue(resultCobertura.geographicSubAddress[0].stairCase);
                formGroup.get('tipoPuertaCobertura').patchValue(resultCobertura.geographicSubAddress[0].doorType);
            }
        }
        return formGroup;
    }

    openModalAddress() {
        const modalRef = this.modalService.open(SimyoModalAddressComponent, {size: 'lg', centered: true});
        modalRef.componentInstance.formularioNormalizacion = this.formularioNormalizacion;
        modalRef.componentInstance.pdv = this.pdv;
        modalRef.componentInstance.datosNormalizacion = this.datosNormalizacion;
        modalRef.componentInstance.formGroup = this.formGroup;
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
