import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment/moment';
import { take } from 'rxjs';
import { BaseComponent } from '../../baseComponent';

@Component({
    selector: 'digo-datepicker',
    templateUrl: './custom-datepicker.component.html',
    styleUrls: ['./custom-datepicker.component.css'],
})
export class CustomDatepickerComponent
    extends BaseComponent
    implements OnChanges
{
    @Input() options = [];
    @Input() minDate = 'value';
    @Input() maxDate = 'value';

    @Input() customeMessageError: string;
    @Input() placeholder: string;

    @Output() OnChange = new EventEmitter();
    @Output() OnDateInput = new EventEmitter();

    public faCalendarDay = faCalendarDay;

    constructor(public formBuilder: FormBuilder) {
        super(formBuilder);
    }

    ngOnInit(): void {
        if(this.formGroup && this.controlName) {
            this.control = this.getControl(this.controlName);
            this.control.valueChanges.subscribe((data) => {
                this.isEmpty = !data;
                if (this.control.valid) {
                    this.OnDateInput.emit(data);
                }
            });

            this.isEmpty = !this.control?.value;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.formGroup && this.controlName) {
            this.control = this.getControl(this.controlName);
        }

        if(changes.value) {
            this.control.setValue(changes.value.currentValue);
        }
        
        
        if (this.control) {
            this.isEmpty = !this.control?.value;
        }
    }

    formatDate(date) {
        if (date.includes('/') == false) {
            let format = 'DDMMYYYY';
            if (date.length >= 6 && date.length < 8) {
                format = 'DDMMYY';
            }
            this.control.patchValue(moment(date, format));
            return;
        }
        this.control.patchValue(moment(date, 'DD/MM/YYYY'));
    }

    todayClicked(picker: any) {
        this.control.setValue(moment());
        picker.close();
    }

    selectedDate(picker: any) {
        setTimeout(() => {
            picker._componentRef?.instance._calendar.monthView.selectedChange
                .pipe(take(1))
                .subscribe((res: any) => {
                    this.control.setValue(res);
                    picker.close();
                });
        });
    }

    clearStartDate(picker: any) {
        this.control.setValue(null);
        picker.close();
    }
}
