<!-- Notificacion No disponibilidad Simyo -->
<!-- <div class="alert px-3 py-3 text-bold info-notify" *ngIf="today.isSameOrAfter(fromSimyoNotAvailable) && today.isBefore(untilSimyoNotAvailable) && router.url === '/'">
    <div class="container">
        <div class="row">
            <div class="col-sm-1 d-flex justify-content-center">
                <img src="./assets/img/megaphone.png" class="img-fluid" width="48">
            </div>
            <div class="col-sm-11">
                <b>¡IMPORTANTE!</b>
                Desde el viernes 11 de junio de 2021 a las 20:30 h hasta el sábado 12 de junio de 2021 a las 9:30 h, no será posible realizar contrataciones de la marca Simyo. Disculpen las molestias
            </div>
        </div>
    </div>
</div> -->
<!-- Notificacion No disponibilidad Simyo -->
<app-notification-carousel *ngIf="router.url === '/' || router.url.includes('/?')" [pdv]="pdv"></app-notification-carousel>

<app-hm-header *ngIf="!loadIframeMode" [pdv]="pdv"></app-hm-header>

<!--- REPÚBLICA MÓVIL -->
<app-rm-subheader *ngIf="this.router.url.includes('republica-movil')"></app-rm-subheader>

<!-- SIMYO -->
<app-simyo-subheader [iFrameMode]="loadIframeMode" *ngIf="this.router.url.includes('simyo')"></app-simyo-subheader>
<app-simyo-shopping-cart *ngIf="this.router.url.includes('simyo')"></app-simyo-shopping-cart>

<!-- ORANGE -->
<app-orange-subheader *ngIf="router.url.includes('orange')"></app-orange-subheader>
<app-orange-shopping-cart *ngIf="router.url.includes('orange')"></app-orange-shopping-cart>

<!-- MASMOVIL -->
<app-masmovil-subheader *ngIf="this.router.url.includes('masmovil')"></app-masmovil-subheader>

<!-- PEPEPHONE -->
<app-pepephone-subheader *ngIf="this.router.url.includes('pepephone')"></app-pepephone-subheader>

<!-- GUUK -->
<app-guuk-subheader *ngIf="this.router.url.includes('guuk')"></app-guuk-subheader>

<app-main-loading [pdv]="pdv"></app-main-loading>
<div [class.container]="this.router.url != '/' && this.router.url != '/index' && !router.url.includes('/?')" [hidden]="!pdv">
    <router-outlet></router-outlet>
</div>

<ng-template #simyoNotAvailableModal let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pe-0 mt-2 text-center" style="font-size: 18px;">IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="m-4 row">
            <p>
                Desde el viernes 11 de junio de 2021 a las 20:30 h hasta el sábado 12 de junio de 2021 a las 9:30 h, no será posible realizar contrataciones de la marca Simyo.
            </p>
            <p>
                Disculpen las molestias
            </p>
        </div>
        <a (click)="modal.dismiss('Cross click'); backClicked()" class="btn btn-simyo-o2 d-block w-100 mb-1" role="button">
            Volver atrás
        </a>
    </div>
</ng-template>