<div class="container" *ngIf="showProgressBar">
    <div class="row">
        <div class="col-lg-8 offset-2">
            <section class="centered--carga">
                <!-- Barra de carga -->
                <ng-container *ngIf="!orderData.lines.length">
                    <h3 class="fw-bold text-center" >
                        Activando prepago
                    </h3>
                    <p class="text-center">
                        Manténgase a la espera, este proceso puede tardar varios segundos.
                    </p>
                    <br>

                    <div class="barra--carga">
                        <div id="load-bar" class="move"></div>
                    </div>
                </ng-container>

                <ng-container *ngIf="orderData.lines.length">
                    <h3 class="fw-bold text-center" >
                        Resultado de la operación
                    </h3>

                    <br><br>

                    <div class="card card-body bg-white mb-5" *ngFor="let line of orderData.lines; index as i">
                        <div class="d-flex justify-content-start align-items-start gap-20">
                            <h4 class="mb-0 no-wrap">
                                MSISDN: <b>{{ line.msisdn ?? 'Pendiente' }}</b>
                            </h4>

                            <h4 class="mb-0" *ngIf="line.status && line.status !== ''">
                                Estado: <b [ngStyle]="getTextColor(line.super_status)">{{ line.status_description }}</b>
                            </h4>
                        </div>

                        <div class="barra--carga mt-3" *ngIf="!line.exit_progress_bar && showAllProgressBar">
                            <div id="load-bar-{{ i }}" class="move"></div>
                        </div>

                        <div class="d-flex justify-content-between align-items-start mt-2" style="font-size: 19px !important;" *ngIf="showRecharge">
                            <ng-container *ngIf="confirmRecharge">
                                <span>
                                    Recarga realizada correctamente
                                </span>
                                <a [href]="confirmRecharge.justificante" target="_blank">
                                    <i-feather name="printer" class="icon-tables cursor-pointer" title="Descargar justificante recarga"></i-feather>
                                </a>
                            </ng-container>
                            <span *ngIf="loadingRecharge">
                                Recargando saldo. Por favor, espera...
                            </span>
                            <span *ngIf="errorRecharge && errorRecharge !== ''">
                                {{ errorRecharge }}
                            </span>
                        </div>
                    </div>

                    <button (click)="goToHome()" class="btn btn-simyo-o2 d-block w-100 mb-5 pt-3" style="padding-bottom: 20px !important;">
                        <b>{{ showAllProgressBar ? 'Tramitación en curso - Volver a DIGO' : 'Volver a DIGO' }}</b>
                    </button>
                </ng-container>
                <!-- ./ Barra de carga -->
            </section>
        </div>
    </div>
</div>

<ng-container *ngIf="!showProgressBar">
    <div class="container content">
        <div class="row">
            <div class="col-md-12">
                <h5 class="title-step text-center" >
                    <img src="./assets/img/credit-card.svg" class="img-fluid ico-title" width="20">
                    {{ getTitle() }}
                </h5>
                <!-- Notificación -->
                <div class="alert" *ngIf="(orange$ | async)?.orderInfo || showErrorOrder" [ngClass]="{'alert-danger': showErrorOrder || (orange$ | async)?.orderInfo?.super_status === superStatus.error || (orange$ | async)?.orderInfo?.super_status === superStatus.cancelled || (orange$ | async)?.orderInfo?.super_status === superStatus.rejected, 'alert-success': (orange$ | async)?.orderInfo?.super_status === superStatus.active, 'alert-warning': (orange$ | async)?.orderInfo?.super_status === superStatus.in_process }" role="alert">
                    <h4 class="alert-heading mt-2">
                        <b *ngIf="!showErrorOrder">
                            {{ (orange$ | async)?.orderInfo?.status_title || 'En proceso' }}
                        </b>
                        <b *ngIf="showErrorOrder">
                            Fallo en la tramitación
                        </b>
                    </h4>
                    <p *ngIf="!showErrorOrder">
                        {{ (orange$ | async)?.orderInfo?.status_description || 'El alta está en proceso de activación' }}
                    </p>
                    <p *ngIf="showErrorOrder">
                        {{ showErrorOrderMsg || 'No se ha podido realizar la tramitación. Por favor, inténtelo de nuevo más tarde.' }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!--------- Resumen de la tramitación ------------->
    <!-------------------------------------------------->

    <div class="container firma mt-3 mb-5">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2">
                                            <b>Cliente</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">
                                <div class="row mt-4">
                                    <div class="col-lg-8">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list title-flex ms-3">
                                            <a class="title-flex-name text-list me-5">
                                                <b>{{ (orange$ | async)?.user_data_form_values?.name + ' ' + (orange$ | async)?.user_data_form_values?.first_surname + ' ' + (orange$ | async)?.user_data_form_values?.second_surname }}</b>
                                            </a>
                                            <br><br>
                                            <!-- Documento de identidad -->
                                            <span class="title-flex-document font-list">
                                                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                                {{ (orange$ | async)?.user_data_form_values?.document_type?.name !== '' ? (orange$ | async)?.user_data_form_values?.document_type?.name + ': ' : '' }}<b>{{ (orange$ | async)?.user_data_form_values?.document_number }}</b>
                                            </span>
                                            <br>
                                            <!-- Fecha de solicitud: -->
                                            <ng-container *ngIf="(orange$ | async)?.orderInfo?.created_at && (orange$ | async)?.orderInfo?.created_at !== ''">
                                                <span class="font-list">
                                                    <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                    Fecha de solicitud: <b>{{ (orange$ | async)?.orderInfo?.created_at | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                </span>
                                                <br>
                                            </ng-container>
                                        </h5>
                                    </div>
                                    <!-- Antes de tramitar -->
                                    <!--<div  class="col-lg-4 d-flex justify-content-end" *ngIf="!(orange$ | async)?.orderInfo?.lines?.length">
                                        <ng-container *ngIf="(orange$ | async)?.welcome_pack_selected?.code !== orangeWelcomePackCodes.esim">
                                            <div class="col-lg-12 mb-2" *ngFor="let line of normalizedMsisdns((orange$ | async)?.configuration_form_values?.msisdns)">
                                                <span class="badge rounded-pill badge-simyo font-list"
                                                [innerHTML]="(orange$ | async)?.welcome_pack_selected?.name + ' - ' + (orange$ | async)?.configuration_form_values?.rate?.name + ': ' + (+(orange$ | async)?.configuration_form_values?.rate?.price).toFixed(2) + ' €'">
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="(orange$ | async)?.welcome_pack_selected?.code === orangeWelcomePackCodes.esim">
                                            <div class="col-lg-12 mb-2">
                                                <span class="badge rounded-pill badge-simyo font-list" [innerHTML]="(orange$ | async)?.welcome_pack_selected?.name + ' - ' + (orange$ | async)?.configuration_form_values?.rate?.name + ': ' + (+(orange$ | async)?.configuration_form_values?.rate?.price).toFixed(2) + ' €'">
                                                </span>
                                            </div>
                                        </ng-container>
                                    </div>
                                    &lt;!&ndash; Despues de tramitar &ndash;&gt;
                                    <div  class="col-lg-4 d-flex justify-content-end" *ngIf="(orange$ | async)?.orderInfo?.lines?.length">
                                        <div class="col-lg-12 mb-2" *ngFor="let line of (orange$ | async)?.orderInfo?.lines">
                                            <span class="badge rounded-pill badge-simyo font-list">
                                                {{ (orange$ | async)?.orderInfo?.welcome_pack + ' - ' + (orange$ | async)?.orderInfo?.rate + ': ' + (+line.contracted_price).toFixed(2) }} €
                                            </span>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ms-3">
                                            <img src="./assets/img/file-text-negro.svg" class="img-fluid ico-title me-1" width="13">
                                            <b>Resumen de la tramitación</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item cuerpo-pack-2">
                                                <!-- Antes de tramitar -->
                                                <ng-container *ngIf="!(orange$ | async)?.orderInfo?.lines?.length">
                                                    <!-- ESIM -->
                                                    <ng-container *ngIf="(orange$ | async)?.welcome_pack_selected?.is_esim">
                                                        <ng-container *ngIf="(configurationFormGroup.controls['esim_number_lines'].value > 0); else onceLine">
                                                            <div *ngFor="let line of getArrayMultiLine(); index as i">
                                                                <app-orange-prepaid-confirmation-card
                                                                    [lineNumber]="i"
                                                                    [title]="(permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? ('Alta eSIM prepago'  + ' ' + (i + 1) + 'ª línea móvil') : 'Alta eSIM prepago')"
                                                                    [welcomePackSelected]="(orange$ | async)?.welcome_pack_selected"
                                                                    [rate]="(orange$ | async)?.configuration_form_values?.rate"
                                                                    [typeOperation]="configurationFormGroup.controls['type_operation'].value">
                                                                </app-orange-prepaid-confirmation-card>
                                                            </div>
                                                        </ng-container>
                                                        <ng-template #onceLine>
                                                            <div *ngFor="let line of normalizedMsisdns((orange$ | async)?.configuration_form_values?.msisdns); index as i">
                                                            <app-orange-prepaid-confirmation-card
                                                                    [lineNumber]="i"
                                                                    [line]="line"
                                                                    [title]="(permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? (configurationFormGroup.controls['type_operation'].value === 'registration' ? 'Alta ' : 'Portabilidad ') : '') + 'eSIM prepago'"
                                                                    [welcomePackSelected]="(orange$ | async)?.welcome_pack_selected"
                                                                    [rate]="(orange$ | async)?.configuration_form_values?.rate"
                                                                    [typeOperation]="configurationFormGroup.controls['type_operation'].value">
                                                                </app-orange-prepaid-confirmation-card>
                                                            </div>
                                                        </ng-template>
                                                    </ng-container>
                                                    <!-- NO ESIM -->
                                                    <ng-container *ngIf="!(orange$ | async)?.welcome_pack_selected?.is_esim">
                                                        <div *ngFor="let line of normalizedMsisdns((orange$ | async)?.configuration_form_values?.msisdns); index as i">
                                                            <app-orange-prepaid-confirmation-card
                                                            [lineNumber]="i"
                                                            [line]="line"
                                                            [title]="getAltaType() + (type !== 'portabilidad' ? ' ' + (i + 1) + 'ª línea móvil' : ' línea móvil')"
                                                            [welcomePackSelected]="(orange$ | async)?.welcome_pack_selected"
                                                            [rate]="(orange$ | async)?.configuration_form_values?.rate">
                                                            </app-orange-prepaid-confirmation-card>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <!-- Despues de tramitar -->
                                                <ng-container *ngIf="(orange$ | async)?.orderInfo?.lines?.length">
                                                    <div *ngFor="let line of (orange$ | async)?.orderInfo?.lines; index as i">
                                                        <hr *ngIf="i > 0">
                                                        <!-- RESUMEN MOVIL PACK -->
                                                        <h5  class="tar-name-7 mt-2 ms-3">
                                                            <!-- <b>{{ 'eSIM prepago ' + (i + 1) + 'ª línea móvil' }}</b> -->
                                                            <b>{{ getAltaType() }}</b>
                                                        </h5>

                                                        <div class="resumen d-flex justify-content-between align-items-center">
                                                            <span class="sidebar-info4 simyo">
                                                                Conceptos
                                                            </span>
                                                            <small class="text-muted"></small>
                                                        </div>

                                                        <div class="resumen d-flex justify-content-between align-items-center">
                                                            <!-- tarifa -->
                                                            <span class="sidebar-info5">
                                                                <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                                <span [innerHTML]="line.welcome_pack + ' - ' + line.rate"></span>
                                                            </span>
                                                            <small class="text-muted">
                                                                <!-- Precio tarifa -->
                                                                <span class="sidebar-info3 custom-font">
                                                                    <b>{{ (+line.contracted_price).toFixed(2) }} €</b>
                                                                </span>
                                                            </small>
                                                        </div>

                                                        <div class="resumen d-flex justify-content-between align-items-center" *ngIf="line.status && line.status !== ''">
                                                            <!-- Estado tramitacion -->
                                                            <span class="sidebar-info5"><img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                                Estado: <b>{{ line.status_description }}</b>
                                                            </span>
                                                        </div>

                                                        <div class="resumen d-flex justify-content-between align-items-center" *ngIf="line.error_description && line.error_description !== ''">
                                                            <!-- Descripción -->
                                                            <span class="sidebar-info5"><img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                                Descripción: <b>{{ line.error_description }}</b>
                                                            </span>
                                                        </div>

                                                        <div class="resumen d-flex justify-content-between align-items-center" *ngIf="line.activation_date && line.activation_date !== ''">
                                                            <!-- Descripción -->
                                                            <span class="sidebar-info5"><img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                                Fecha de activación: <b>{{ line.activation_date | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <!-- Antes de tramitar -->
                                                <ng-container *ngIf="!(orange$ | async)?.orderInfo?.lines?.length">
                                                    <!--<div class="resumen d-flex justify-content-between align-items-center mt-2" *ngIf="getTotalPrice() > 0">
                                                        <span class="sidebar-info">
                                                            <b>Total</b>
                                                        </span>
                                                        <small class="text-muted trash-carrito">
                                                            <span class="sidebar-info2">
                                                                <b>{{ getTotalPrice().toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>-->
                                                    <hr>
                                                    <!-- Recarga -->
                                                    <div class="resumen d-flex justify-content-between align-items-baseline" *ngIf="(orange$ | async)?.configuration_form_values?.balance">
                                                        <h5  class="tar-name-7">
                                                            <b>Recarga</b>
                                                        </h5>
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info3 pt-0 custom-font">
                                                            <b>{{ (orange$ | async)?.configuration_form_values?.balance }} €</b>
                                                        </span>
                                                    </div>
                                                    <!-- Importe Total -->
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center" *ngIf="getTotalPrice() > 0">
                                                        <h5 class="total-color">
                                                            <b>Pago mensual</b> (Total)
                                                        </h5>
                                                        <h5 class="text-auto">
                                                            <b>{{ getTotalPrice().toFixed(2) }} €</b>
                                                        </h5>
                                                    </div>
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <span class="total-color">
                                                            <b>IVA incluido</b>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                                <!-- Despues de tramitar -->
                                                <ng-container *ngIf="(orange$ | async)?.orderInfo?.lines?.length">
                                                    <div class="resumen d-flex justify-content-between align-items-center mt-2" *ngIf="+(orange$ | async)?.orderInfo?.total_price > 0">
                                                        <span class="sidebar-info">
                                                            <b>Total</b>
                                                        </span>
                                                        <small class="text-muted trash-carrito">
                                                            <span class="sidebar-info2">
                                                                <b>{{ (+(orange$ | async)?.orderInfo?.total_price).toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                    <hr>
                                                    <!-- Importe Total -->
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center" *ngIf="+(orange$ | async)?.orderInfo?.total_price > 0">
                                                        <h5 class="total-color">
                                                            <b>Pago mensual</b>
                                                        </h5>
                                                        <h5 class="text-auto">
                                                            <b>{{ (+(orange$ | async)?.orderInfo?.total_price).toFixed(2) }} €</b>
                                                        </h5>
                                                    </div>
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <span class="total-color">
                                                            <b>IVA incluido</b>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card alert-success-orange" *ngIf="showAlertRemember((orange$ | async), msisdns, type)">
                            <div class="alert">
                                <p class="mb-0" style="color: black !important; font-size: 16px !important;">
                                    <strong [innerHTML]="messageAlertRemember"></strong>
                                </p>
                            </div>
                        </div>

                        <!--<div class="card alert-success-orange" *ngIf="(orange$ | async).rate_selected?.recharge && msisdns.length > 1">
                            <div class="alert">
                                <p class="mb-0" style="color: black !important; font-size: 16px !important;">
                                    <strong>Recuerde al cliente que debe recargar <span>{{ getRecharge((orange$ | async).rate_selected?.recharge, 0) }}€</span> en cada línea para su activación</strong>
                                </p>
                            </div>
                        </div>-->

                        <!--Resumen de Contrato -->
                        <div class="card mt-3 mb-3">
                            <div class="card-header bg-rm">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h6 class="subtitulo-pack2"><b>Resumen de contrato</b></h6>
                                    </div>
                                </div>
                            </div>

                            <div  class="card-body bg-white">
                                <div class="row">
                                    <div class="col-lg-12 enviar">
                                        <div>
                                            <button id="submit" type="submit" [disabled]="sended || loadingPrecontract || sendEmailTries < 3" class="btn btn-simyo-o2 btn-sm p-2" (click)="precontractSended()">
                                                Enviar resumen de contrato
                                            </button>
                                            <span *ngIf="loadingPrecontract">
                                                Enviando...
                                            </span>
                                            <span *ngIf="sendEmailTries > 0 && sendEmailTries < 3">
                                                Reintentando el envío de email...
                                            </span>
                                        </div>
                                        <div>
                                            <a *ngIf="precontract && hash !== '' && !loadingPrecontract" (click)="download()" class="ms-2 buttonss button5 cursor-pointer" style="position: relative;" [title]="errorDownload ? 'Error al descargar el documento. Inténtelo más tarde' : 'Descargar documento'">
                                                <i-feather name="download" class="icon-tables"></i-feather>
                                                <span *ngIf="errorDownload" class="dot-cancel-red icon-error"></span>
                                            </a>
                                            <mat-error *ngIf="precontract && hash === '' && !loadingPrecontract">
                                                Error al enviar resumen de contrato. Inténtelo más tarde
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- KYC -->
                        <div class="card mt-3 mb-3" *ngIf="(orange$ | async)?.welcome_pack_selected?.is_esim && (sended || downloaded) && permSv.hasPermFromV2(allPermissions.orange.permite_view_kyc_esim) && permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim)">
                            <div class="card-header bg-rm">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h6 class="subtitulo-pack2"><b>Documentación</b></h6>
                                    </div>
                                </div>
                            </div>

                            <div  class="card-body bg-white">
                                <div class="row">
                                    <div class="col-lg-12 enviar-document">
                                        <div>
                                            <button [disabled]="disableButtonSendDocumentation()" type="button" class="btn btn-simyo-o2 btn-sm p-2" (click)="openModalDocumentation()">
                                                Enviar documentación
                                            </button>
                                            <button *ngIf="showSkipKYC && pdv?.info?.username === 'demo_pdv'" [disabled]="disableButtonSendDocumentation()" type="button" class="btn btn-simyo-o2 btn-sm p-2 mx-2" (click)="skypKYC()">
                                                Saltar KYC
                                            </button>
                                        </div>
                                        <div *ngIf="disableButtonSendDocumentation()" class="kyc-result">
                                            <mat-icon>check_circle_outline</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--------- ACTIVAR SERVICIOS ------------>
    <!---------------------------------------->
    <div class="container firma">
        <br>
        <div class="row">
            <div class="col-md-12">
                <button (click)="create(false)" *ngIf="showCreateButton && (sended || downloaded) && checkIsEsimAndScanDocument((orange$ | async)?.welcome_pack_selected?.is_esim)" [disabled]="isCreateLoading" class="btn btn-simyo-o2 d-block w-100 mb-5 pt-3" style="padding-bottom: 20px !important;">
                    <b>{{isCreateLoading ? 'Activando servicios  ' : 'Tramitar solicitud'}}</b>
                    <span *ngIf="isCreateLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </div>
</ng-container>
