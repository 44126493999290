/*
Para agregar un error, si este es especifico de un campo ponemos primero un identificador del campo y
dentro de ese objeto el listado de errores
*/
export const inputErros = {
    required: "Campo obligatorio.",
    doc: "Número de documento erróneo",
    blankSpace: "Campo obligatorio",
    invalid: "Campo obligatorio.",
    codigoPostal: {
        zipCodeInvalid: "Formato de CP incorrecto.",
        sameTax: "CP incorrecto."
    },
    phone: {
        pattern: "El número de teléfono es incorrecto",
        minlength: "El número de teléfono debe tener 9 dígitos",
        phonepattern: "El número de teléfono debe empezar por 6 o 7 y sólo números",
        duplicate: "Número igual al antiguo titular",

    },
    email: {
        pattern: "Correo electrónico no válido"
    },
    email_confirm: {
        pattern: "Correo electrónico no válido",
        mustMatch: "El correo electrónico no coincide"
    },
    birthday: {
        date_maximum: "El cliente debe ser mayor de edad.",
        date_minimum: "Fecha inválida."
    },
    created_at: {
        maxlength: ""
    },
    matDatepickerParse: "",
    date_maximum: 'El cliente debe ser mayor de edad',
    msisdn: {
        minlength: "El número de teléfono debe tener 9 dígitos",
        pattern: "El número de teléfono debe empezar por 6 o 7 y sólo números",
        duplicate: "El número de teléfono no se puede repetir",
        used: "MSISDN con una petición en curso"
    },
    terminal_imei: {
        minlength: "El número de IMEI debe tener 15 dígitos",
        pattern: "El número de IMEI debe ser sólo números"
    },
    portability_old_iccid: {
        minlength: "El número de IMEI debe tener 19 dígitos",
        duplicate: "El valor no puede ser el mismo que la nueva tarjeta sim",
        pattern: "El número de IMEI debe ser sólo números"
    },
    portability_new_iccid: {
        minlength: "El número de IMEI debe tener 19 dígitos",
        duplicate: "El valor no puede ser el mismo que la nueva tarjeta sim",
        pattern: "El número de IMEI debe ser sólo números"
    },
    iccid:'ICCID no válido',
    iccid_client:{
        minlength: 'El valor debe de tener 19 dígitos'
    },
    iccid_portability:{
        minlength: 'El valor debe de tener 19 dígitos'
    },
    portability_phone: {
        minlength: "El valor debe tener al menos 9 dígitos",
        maxlength: "",
        required: "",
        notmustMatch: "Número de teléfono a portar ya utilizado en este flujo CUSTOMMMMMMM"
    }
}
