<div class="modal-header">
    <h5 class="modal-title fw-bold ms-3 mt-3" id="exampleModalLabel" [class.mb-3]="showAlert">
        {{ showAlert ? '¿Necesitas actualizar los datos de contacto para soporte?' : 'Contacto para soporte' }}
    </h5>

    <button type="button" class="close close-m2" mat-dialog-close="" *ngIf="!isLoading && !showAlert">
        <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row justify-content-between" *ngIf="showAlert">
            <div class="col-lg-4">
                <button class="btn btn-black-outline d-block w-100 spinner" mat-dialog-close="">No</button>
            </div>

            <div class="col-lg-4">
                <button class="btn btn-black-outline d-block w-100 spinner" (click)="showAlert = false">
                    <strong>Sí, editar</strong>
                </button>
            </div>
        </div>

        <div class="row" [formGroup]="formGroup" *ngIf="!showAlert">
            <div class="col-lg-12 mt-1">
                <digo-input
                    id="supportphone"
                    [label]="'Teléfono'"
                    [isRequired]="true"
                    [formGroup]="formGroup"
                    controlName="supportphone"
                    type="text"
                    minlength="9"
                    maxlength="9"
                    [isDisabled]="isLoading"
                    (keypress)="numberOnly($event)"
                />
            </div>

            <div class="col-lg-12 mt-1">
                <digo-input
                    id="supportemail"
                    [label]="'E-mail'"
                    [isRequired]="true"
                    [formGroup]="formGroup"
                    controlName="supportemail"
                    [isDisabled]="isLoading"
                    type="text"
                />
            </div>

            <div class="col-lg-12 mt-1">
                <digo-input
                    id="supportcontact"
                    [label]="'Contacto'"
                    [isRequired]="true"
                    [formGroup]="formGroup"
                    controlName="supportcontact"
                    type="text"
                    [isDisabled]="isLoading"
                />
            </div>

            <div class="col-lg-12" *ngIf="alertType">
                <ngb-alert [type]="alertType" (close)="alertType = null">{{ alertMessage }}</ngb-alert>
            </div>

            <div class="col-lg-12 mt-5 mb-3">
                <button class="btn btn-black-outline d-block w-100 spinner" (click)="validateForm()" [disabled]="isLoading">
                    <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    {{ isLoading ? 'Guardando...' : 'Guardar' }}
                </button>
            </div>
        </div>
    </div>
</div>
