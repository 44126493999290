<nav id="orange" class="navbar navbar-expand-xl"
     [ngClass]="{'navbar-dark bg-dark border-bottom-dark' : router?.url === '/' || router.url.includes('/?'), 'navbar-light bg-light border-bottom':  router?.url !== '/' && !router.url.includes('/?')}">
    <div class="container">
        <a class="navbar-brand">
            <img src="./assets/img/logo.png" class="img-fluid logo-orange">
            <span class="digo">digo</span>
            <img src="./assets/img/logo_fnac.png" class="img-fluid logo-fnac" *ngIf="+pdv?.id?.distribuidor === FNAC_DISTRIBUTOR">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav-list navbar-nav me-auto">
                <li class="nav-item order-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/" class="nav-link panel"><span class="visually-hidden">(current)</span>Inicio</a>
                </li>

                <!-- Simyo -->
                <li class="nav-item order-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender) && permSv.hasPermFromV2(allPermissions.others.access_night)">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" href="#">Simyo</a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_viejo_frontal)">
                                <h6 class="dropdown-header mt-1">Contratación</h6>
                                <li>
                                    <a href="{{ baseUrl }}smy_activa.itx" class="dropdown-item">Móvil (contrato)</a>
                                </li>
                                <li>
                                    <a href="{{ baseUrl }}smy_activa.itx?mode=prepaid" class="dropdown-item">Móvil (prepago)</a>
                                </li>
                            </div>

                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal)">
                                <h6 class="dropdown-header mt-1">Contratación</h6>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                                    <button (click)="checkSimyoTemporaryClosure('/simyo/convergente')" class="dropdown-item">Fibra y móvil</button>
                                </li>
                                <li>
                                    <button (click)="checkSimyoTemporaryClosure('/simyo/solo-movil')" class="dropdown-item">Móvil</button>
                                </li>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                                    <button (click)="checkSimyoTemporaryClosure('/simyo/solo-fibra')" class="dropdown-item">Fibra</button>
                                </li>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_duplicado_sim)">
                                    <button (click)="checkSimyoTemporaryClosure('/simyo/duplicados')" class="dropdown-item">Duplicados</button>
                                </li>
                                <li>
                                    <a routerLink="/simyo/listado" class="dropdown-item">Consultar operaciones</a>
                                </li>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_duplicado_sim)">
                                    <a routerLink="/simyo/listado-duplicados" class="dropdown-item">Consultar duplicados</a>
                                </li>
                            </div>
                            <!-- VerTarifasBlock
                            <div class="col-xl-12 themed-grid-col">
                                <h6 class="dropdown-header mt-1">Otras opciones</h6>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_viejo_frontal) || !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                                    <a href="javascript: void(0)" (click)="viewBrandRates(brands.simyo)" class="dropdown-item">Ver tarifas</a>
                                </li>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal) && permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                                    <a routerLink="/simyo/tarifas" class="dropdown-item">Ver tarifas</a>
                                </li>
                            </div>-->
                        </div>
                    </ul>
                </li>
                <!-- Simyo pedidos antiguos -->
                <li class="nav-item order-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.tuvo_activaciones) && permSv.hasPermFromV2(allPermissions.others.access_night)">
                    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" href="#">Simyo</a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <div class="col-xl-12 themed-grid-col bg-white">
                                <li>
                                    <a routerLink="/simyo/listado" class="dropdown-item">Consultar operaciones</a>
                                </li>
                            </div>
                        </div>
                    </ul>
                </li>

                <!-- Orange -->
                <li class="nav-item order-3"
                    *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago) || permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_prepago) || permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_pospago)">
                    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" href="#">Orange</a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <ng-container *ngIf="permSv.hasPermFromV2(allPermissions.orange.nuevo_flujo_prepago_orange)">
                                <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)">
                                    <div>
                                        <h6 class="dropdown-header mt-1">Móvil prepago</h6>
                                        <li>
                                            <a routerLink="/orange/prepago/alta" class="dropdown-item">Alta</a>
                                        </li>
                                        <li>
                                            <a routerLink="/orange/prepago/portabilidad" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_portabilidad)">Portabilidad</a>
                                        </li>
                                        <!--<li>
                                            <a href="{{ baseUrl }}activa_pdv_terminal_sim.itx" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_venta_terminal_sim)">Venta terminal + SIM</a>
                                        </li>-->
                                        <li>
                                            <a href="{{ baseUrl }}rebo_activa.itx?marca=orange" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)">Renovación bonos</a>
                                        </li>

                                        <li>
                                            <a routerLink="/orange/bonos-adicionales" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">Bonos adicionales</a>
                                        </li>
                                        <li>
                                            <a routerLink="/orange/prepago/esim" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim)">{{ permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? 'Activación ' : 'Alta ' }}eSIM</a>
                                        </li>
                                        <li>
                                            <a routerLink="/orange/prepago-listado" class="dropdown-item">
                                                Consulta operaciones prepago
                                            </a>
                                        </li>
                                        <li>
                                            <a href="{{ baseUrl }}rebo_recogida_pdv.itx" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)">
                                                Consulta renovaciones bonos
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/orange/bonos-adicionales-listado" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">
                                                Consulta bonos adicionales
                                            </a>
                                        </li>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!permSv.hasPermFromV2(allPermissions.orange.nuevo_flujo_prepago_orange)">
                                <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)">
                                    <div>
                                        <h6 class="dropdown-header mt-1">Móvil prepago</h6>
                                        <li>
                                            <a href="{{ baseUrl }}activa_pdv.itx" class="dropdown-item">Alta</a>
                                        </li>
                                        <li>
                                            <a href="{{ baseUrl }}activa_pdv_portabilidad.itx" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_portabilidad)">Portabilidad</a>
                                        </li>
                                        <li>
                                            <a href="{{ baseUrl }}activa_pdv_terminal_sim.itx" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_venta_terminal_sim)">Venta terminal + SIM</a>
                                        </li>
                                        <li>
                                            <a href="{{ baseUrl }}rebo_activa.itx?marca=orange" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)">Renovación bonos</a>
                                        </li>
                                        <li>
                                            <a routerLink="/orange/bonos-adicionales" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">Bonos adicionales</a>
                                        </li>
                                        <li>
                                            <a routerLink="/orange/prepago/esim" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim)">{{ permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? 'Activación ' : 'Alta ' }}eSIM</a>
                                        </li>
                                        <li>
                                            <a href="{{ baseUrl }}recogida_pdv.itx" class="dropdown-item">
                                                Consulta operaciones prepago
                                            </a>
                                        </li>
                                        <li>
                                            <a href="{{ baseUrl }}rebo_recogida_pdv.itx" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)">
                                                Consulta renovaciones bonos
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/orange/bonos-adicionales-listado" class="dropdown-item"
                                               *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">
                                                Consulta bonos adicionales
                                            </a>
                                        </li>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Orange pedidos antiguos -->
                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_prepago)">
                                <div>
                                    <h6 class="dropdown-header mt-1">Móvil prepago</h6>
                                    <li>
                                        <a href="{{ baseUrl }}recogida_pdv.itx" class="dropdown-item">
                                            Consulta operaciones prepago
                                        </a>
                                    </li>
                                </div>
                            </div>
                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
                                <h6 class="dropdown-header mt-1">Móvil Pospago</h6>
                                <li>
                                    <a href="{{ baseUrl }}pos_recogida_pdv.itx" class="dropdown-item">
                                        Consulta operaciones Mi Fijo
                                    </a>
                                </li>
                                <li>
                                    <a href="{{ baseUrl }}cancelar_mi_fijo.itx" class="dropdown-item">
                                        Anular portab. Mi Fijo
                                    </a>
                                </li>
                            </div>
                            <!-- Orange pedidos antiguos -->
                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_pospago)">
                                <h6 class="dropdown-header mt-1">Móvil Pospago</h6>
                                <li>
                                    <a href="{{ baseUrl }}pos_recogida_pdv.itx" class="dropdown-item">
                                        Consulta operaciones Mi Fijo
                                    </a>
                                </li>
                            </div>

                            <div class="col-xl-12 themed-grid-col" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
                                <h6 class="dropdown-header mt-1">
                                    Otras opciones
                                </h6>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
                                    <a href="javascript: void(0)" (click)="viewBrandRates(brands.orange)" class="dropdown-item">
                                        Ver tarifas
                                    </a>
                                </li>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)">
                                    <a href="https://www.simmundo.es/" class="dropdown-item" target=”_blank”>
                                        Tarifas internacionales
                                    </a>
                                </li>
                                <li *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)">
                                    <a href="./assets/pdf/Orange - Codigos prepago.pdf" target="_blank" class="dropdown-item">
                                        Códigos prepago Orange
                                    </a>
                                </li>
                            </div>
                        </div>
                    </ul>
                </li>

                <!-- Jazztel -->
                <li class="nav-item order-3" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
                    *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" href="#">Jazztel</a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <div class="col-xl-12 themed-grid-col bg-white">
                                <h6 class="dropdown-header mt-1">Contratación</h6>
                                <li>
                                    <a href="https://pangea.orange.es" target="_blank" class="dropdown-item">Acceder a
                                        contrataciones {{ pdv?.info?.usuariopangea && pdv?.info?.usuariopangea !== '' ? '(' + pdv?.info?.usuariopangea + ')' : '' }}</a>
                                </li>
                                <li>
                                    <a href="{{ baseUrl }}jazztel_list_pdv.itx" target="_blank" class="dropdown-item">Consultar operaciones</a>
                                </li>
                            </div>
                        </div>
                    </ul>
                </li>

                <!-- MasMovil -->
                <li class="nav-item order-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
                    *ngIf="permSv.hasPermFromV2(allPermissions.masmovil.vende_masmovil)">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" href="#">MasMovil</a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <div class="col-xl-12 themed-grid-col bg-white">
                                <h6 class="dropdown-header mt-1">Contratación</h6>
                                <li>
                                    <a [href]="contractNewBrands" target="_blank" class="dropdown-item">Acceder a contrataciones</a>
                                </li>
                                <li>
                                    <a routerLink="/masmovil/listado" class="dropdown-item">Consultar operaciones</a>
                                </li>
                            </div>
                        </div>
                    </ul>
                </li>

                <!-- PepePhone -->
                <li class="nav-item order-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
                    *ngIf="permSv.hasPermFromV2(allPermissions.pepephone.vende_pepephone)">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" href="#">Pepephone</a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <div class="col-xl-12 themed-grid-col bg-white">
                                <h6 class="dropdown-header mt-1">Contratación</h6>
                                <li>
                                    <a [href]="contractPepephone" target="_blank" class="dropdown-item">Acceder a contrataciones</a>
                                </li>
                                <li>
                                    <a routerLink="/pepephone/listado" class="dropdown-item">Consultar operaciones</a>
                                </li>
                            </div>
                        </div>
                    </ul>
                </li>

                <!-- Guuk -->
                <li class="nav-item order-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
                    *ngIf="permSv.hasPermFromV2(allPermissions.guuk.vende_guuk)">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" href="#">Guuk</a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <div class="col-xl-12 themed-grid-col bg-white">
                                <h6 class="dropdown-header mt-1">Contratación</h6>
                                <li>
                                    <a [href]="contractNewBrands" target="_blank" class="dropdown-item">Acceder a contrataciones</a>
                                </li>
                                <li>
                                    <a routerLink="/guuk/listado" class="dropdown-item">Consultar operaciones</a>
                                </li>
                            </div>
                        </div>
                    </ul>
                </li>

                <li class="nav-item order-6">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" href="#"
                       *ngIf="(permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas) && permSv.hasPermFromV2(allPermissions.others.access_night)) ||
                    (permSv.hasPermFromV2(allPermissions.rep_movil.tuvo_activaciones) && permSv.hasPermFromV2(allPermissions.others.access_night)) ||
                    (permSv.hasPermFromV2(allPermissions.amena.permite_activaciones) && permSv.hasPermFromV2(allPermissions.others.access_night)) ||
                    (permSv.hasPermFromV2(allPermissions.amena.tuvo_activaciones) && permSv.hasPermFromV2(allPermissions.others.access_night))">
                        Otras marcas
                    </a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <!-- República Móvil -->
                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas) && permSv.hasPermFromV2(allPermissions.others.access_night)">
                                <div id="republica_movil">
                                    <h6 class="dropdown-header mt-1">Republica móvil</h6>
                                    <li *ngIf="permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas_convergente)">
                                        <a routerLink="republica-movil/convergente" class="dropdown-item">Fibra y Móvil</a>
                                    </li>
                                    <li>
                                        <a routerLink="republica-movil/solo-movil" class="dropdown-item">Móvil</a>
                                    </li>
                                    <li *ngIf="permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas_convergente)">
                                        <a routerLink="republica-movil/solo-fibra" class="dropdown-item">Fibra</a>
                                    </li>
                                    <li>
                                        <a routerLink="/republica-movil/listado" class="dropdown-item">Consultar operaciones</a>
                                    </li>
                                    <li>
                                        <a href="{{ baseUrl }}repm_recogida_pdv.itx" class="dropdown-item">Consultar ops. anteriores</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" (click)="viewBrandRates(brands.republica_movil)" class="dropdown-item">Ver tarifas</a>
                                    </li>
                                    <li>
                                        <a href="javascript: void(0)" (click)="viewBrandRates(brands.amena)" class="dropdown-item">Ver tarifas</a>
                                    </li>
                                </div>
                            </div>

                            <!-- República Móvil pedidos antiguos -->
                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.rep_movil.tuvo_activaciones) && permSv.hasPermFromV2(allPermissions.others.access_night)">
                                <div id="republica_movil_old">
                                    <h6 class="dropdown-header mt-1">Republica móvil</h6>
                                    <li>
                                        <a routerLink="/republica-movil/listado" class="dropdown-item">Consultar operaciones</a>
                                    </li>
                                    <li>
                                        <a href="{{ baseUrl }}repm_recogida_pdv.itx" class="dropdown-item">Consultar ops. anteriores</a>
                                    </li>
                                </div>
                            </div>

                            <!-- Amena -->
                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.amena.permite_activaciones) && permSv.hasPermFromV2(allPermissions.others.access_night)">
                                <div id="amena">
                                    <h6 class="dropdown-header mt-1">Amena</h6>
                                    <li>
                                        <a href="{{ baseUrl }}amena_activa.itx?convergente=1" class="dropdown-item">
                                            Fijo y Móvil <span *ngIf="!permSv.hasPermFromV2(allPermissions.amena.permite_ventas_convergentes)">(parte móvil)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="{{ baseUrl }}amena_activa.itx" class="dropdown-item">Móvil</a>
                                    </li>
                                    <li>
                                        <a href="{{ baseUrl }}amena_fijo.itx" class="dropdown-item"
                                           *ngIf="permSv.hasPermFromV2(allPermissions.amena.permite_ventas_convergentes)">Fijo
                                        </a>
                                    </li>
                                    <ng-container *ngIf="pdv?.info?.tipo_vista === PDV_MULTIMARCA">
                                        <h6 class="dropdown-header mt-1">Migraciones</h6>
                                        <li>
                                            <a href="{{ baseUrl }}amena_activa.itx?migracion_prepago=1" class="dropdown-item">
                                                Migración prepago
                                            </a>
                                        </li>
                                        <li>
                                            <a href="{{ baseUrl }}amena_migracion_activa.itx" class="dropdown-item">
                                                Migración contrato
                                            </a>
                                        </li>
                                    </ng-container>
                                    <li [ngClass]="{'mt-2': pdv?.info?.tipo_vista === PDV_MULTIMARCA}">
                                        <a href="{{ baseUrl }}amena_recogida_pdv.itx" class="dropdown-item">Consultar operaciones</a>
                                    </li>
                                    <li>
                                        <a href="javascript: void(0)" (click)="viewBrandRates(brands.amena)" class="dropdown-item">Ver tarifas</a>
                                    </li>
                                </div>
                            </div>

                            <!-- Amena pedidos antiguos -->
                            <div class="col-xl-12 themed-grid-col bg-white" *ngIf="permSv.hasPermFromV2(allPermissions.amena.tuvo_activaciones) && permSv.hasPermFromV2(allPermissions.others.access_night)">
                                <div id="amena_old">
                                    <h6 class="dropdown-header mt-1">Amena</h6>
                                    <li>
                                        <a href="{{ baseUrl }}amena_recogida_pdv.itx" class="dropdown-item">Consultar operaciones</a>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </ul>
                </li>

                <!-- Más operaciones -->
                <li class="nav-item order-last" [hidden]="hiddenMoreOperations()">
                    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" href="#">Más operaciones</a>
                    <ul class="dropdown-menu fade-up">
                        <div class="row">
                            <div class="col-xl-12 themed-grid-col bg-white">
                                <div id="credit_phone"
                                     *ngIf="pdv && ((permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && pdv.info_recargas.saldo_promo > 0) || !permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga)) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA">
                                    <h6 class="dropdown-header mt-1">Recargas de Saldo</h6>
                                    <li *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && pdv.info_recargas.usuario_dexga > 0">
                                        <a href="{{ baseUrl }}recargas_dexga_gestion.itx" class="dropdown-item">Mi cuenta</a>
                                    </li>
                                    <li *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && pdv.info_recargas.usuario_dexga === 0">
                                        <a href="{{ baseUrl }}recargas_dexga_sol_usuario.itx" class="dropdown-item">Solicitar</a>
                                    </li>
                                    <li *ngIf="permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && pdv.info_recargas.saldo_promo > 0 && !permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && pdv.info_recargas.usuario_dexga > 0">
                                        <a href="{{ baseUrl }}recarga_saldo_origen.itx" class="dropdown-item">Hacer una recarga</a>
                                    </li>
                                    <li *ngIf="permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && pdv.info_recargas.saldo_promo > 0">
                                        <a href="{{ baseUrl }}recarga_saldo.itx" class="dropdown-item">Hacer una recarga</a>
                                    </li>
                                    <li *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && pdv.info_recargas.usuario_dexga > 0">
                                        <a href="{{ baseUrl }}recargas_dexga_recarga.itx" class="dropdown-item">Hacer una recarga</a>
                                    </li>
                                    <li *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && pdv.info_recargas.usuario_dexga > 0 && pdv.info_recargas.bolsa_saldo === 1">
                                        <a href="{{ baseUrl }}bolsas_recarga_reparto.itx" class="dropdown-item">Gestión bolsa de saldo</a>
                                    </li>
                                </div>

                                <div id="commissions"
                                     *ngIf="(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA && permSv.hasPermFromV2(allPermissions.others.access_night)">
                                    <h6 class="dropdown-header mt-1">Comisiones</h6>
                                    <li *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_permitir)">
                                        <a (click)="goToCommissions()" class="dropdown-item cursor-pointer">Consultar</a>
                                    </li>
                                    <li *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc)">
                                        <a href="{{ baseUrl }}comision_empresa.itx" class="dropdown-item">Subir documentación</a>
                                    </li>
                                    <li *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)">
                                        <a href="{{ baseUrl }}comision_empresa.itx" class="dropdown-item">Tu documentación está siendo verificada</a>
                                    </li>
                                </div>

                                <div id="orders" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA">
                                    <h6 class="dropdown-header mt-1">Pedidos</h6>
                                    <li>
                                        <a href="{{ baseUrl }}app_albaran_pdv.itx" class="dropdown-item">Acceder</a>
                                    </li>
                                    <li>
                                        <a href="{{ baseUrl }}pedido_pdv.itx" class="dropdown-item">Hacer un pedido</a>
                                    </li>
                                </div>

                                <div id="free_terminals" *ngIf="pdv?.info?.tipo_vista === PDV_MULTIMARCA && permSv.hasPermFromV2(allPermissions.others.terminales_libres)">
                                    <h6 class="dropdown-header mt-1">Terminales libres</h6>
                                    <li>
                                        <a href="{{ baseUrl }}tlib_activa.itx" class="dropdown-item">Venta de terminales</a>
                                    </li>
                                    <li>
                                        <a href="{{ baseUrl }}tlib_recogida_pdv.itx" class="dropdown-item">Consulta tus ventas</a>
                                    </li>
                                    <li>
                                        <a href="{{ baseUrl }}tlib_gestion_stock.itx" class="dropdown-item">Gestiona el stock</a>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </ul>
                </li>
            </ul>

            <div class="my-2 my-lg-0">
                <ul class="navbar-nav">
                    <!--<li class="nav-item me-2">
                        <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" href="#">ES</a>
                        <ul class="dropdown-menu fade-up">
                            <div class="row">
                                <div class="col-xl-12 themed-grid-col bg-white">
                                    <h6 class="dropdown-header mt-1">Selección de idioma</h6>
                                    <li>
                                        <a class="dropdown-item" href="#"><span class="fw-bold">EN</span> - Inglés</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#"><span class="fw-bold">FR</span> - Francés</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#"><span class="fw-bold">IT</span> - Italiano</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item mb-2" href="#"><span class="fw-bold">DE</span> - Alemán</a>
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </li>-->
                    <!--li class="nav-item"> <a href="" class="icons--nav"><img src="./assets/img/search.svg" class="img-fluid icon-nav pt-3" width="18"> <span class="mobile--ver">Búsqueda de clientes</span></a> </li-->
                    <li class="nav-item tooltips bottom" *ngIf="pdv?.info?.permitir_cambio_pass">
                        <a href="{{ baseUrl }}seguridad.itx" class="icons--nav">
                            <img src="./assets/img/{{router.url === '/' ? 'user1' : 'user' }}.svg" class="img-fluid icon-nav pt-3" width="18"> <span class="mobile--ver">Usuario</span>
                        </a>
                        <span class="tooltiptext">Cambiar contraseña</span>
                    </li>
                    <li class="nav-item tooltips bottom cursor-pointer" data-bs-placement="bottom">
                        <a (click)="signOut()" class="icons--nav">
                            <img src="./assets/img/{{router.url === '/' ? 'lock1' : 'lock' }}.svg" class="img-fluid icon-nav pt-3" width="18"> <span class="mobile--ver">Login</span>
                        </a>
                        <span class="tooltiptext">Cerrar sesión</span>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</nav>
