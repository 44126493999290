<form [formGroup]="formGroup">
    <div class="row normalizador">
        <div class="col-lg-10 mb-0 position-relative">
            <digo-input
                id="nombreViaNormaliza"
                type="maps"
                label="Nombre de la vía"
                [formGroup]="formGroup"
                controlName="nombreViaNormaliza"
                autocomplete="nope"
                (OnInput)="onAddressChange($event);sendEvent($event);formGroup.get('tipoViaNormaliza').patchValue('');"
                (OnFocusIn)="onAddressChange($event)"
                (OnFocusOut)="onAddressFocusOut()"
                (OnAutocompleteSelected)="onAutocompleteSelected($event)"
                [isRequired]="true"/>
            <div #manualAddress class="manual-address" (click)="formGroup.get('tipoViaNormaliza').patchValue('');formNumber.focusInput()">
                <!--<strong #manualAddressText class="manual-address-text"></strong>-->
                <span class="manual-address-link">Continuar con selección manual</span>
            </div>
        </div>
        <div class="col-lg-2 mb-0">
            <digo-input
            #formNumber
            id="numeroNormaliza"
            type="number"
            label="Número"
            [formGroup]="formGroup"
            controlName="numeroNormaliza"
            pattern="\d+"
            min="0"
            autocomplete="nope"
            [isRequired]="true"
            ></digo-input>
        </div>
    </div>
    <!--SEGUNDA FILA-->
    <div class="row normalizador">
        <div class="col-lg-3 mb-1">
            <digo-input
                id="codigoPostalNormaliza"
                type="number"
                label="Código Postal"
                [formGroup]="formGroup"
                controlName="codigoPostalNormaliza"
                pattern="\d+"
                min="0"
                max="99999"
                autocomplete="nope"
                [isRequired]="true"
                (OnInput)="onChangeZip($event.target.value);"
            />
        </div>
        <div class="col-lg-5 mb-1">
            <digo-input
                id="localidadNormaliza"
                type="text"
                label="Localidad"
                [formGroup]="formGroup"
                controlName="localidadNormaliza"
                [isRequired]="true"
            />
        </div>
        <div class="col-lg-4 mb-1">
            <digo-select
            id="provinciaNormaliza"
            [label]="'Provincia'"
            [options]="provinces"
            [formGroup]="formGroup"
            controlName="provinciaNormaliza"
            optionValue="code"
            optionLabel="code"
            [isDisabled]="true"></digo-select>
        </div>
    </div>
    <button id="submit" type="submit" [disabled]="isLoading" *ngIf="muestraBoton == true" class="btn btn-simyo mb-1" (click)="onNormalizarDireccion()">
        <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        {{textDefault}}
    </button>
    <mat-error *ngIf="muestraErrorPeticion">
        <p class="alert alert-warning">
            {{msgErrorPeticion}}
        </p>
    </mat-error>
</form>

