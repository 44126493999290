import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MyaddressService {

    private loadingMyAddress: Subject<boolean> = new Subject<boolean>();

    constructor(private authService: AuthService) { }

    public interceptFetchRequests() {
        const originalFetch = window.fetch;
        const that = this;
        window.fetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
            const url = typeof input === 'string' ? input : input.url;
            const targetUrl: string = '/myaddressBack/';

            if (url.includes(targetUrl)) {
                that.setLoadingMyAddress(true);

                const timeoutPromise = new Promise<never>((_, reject) =>
                    setTimeout(() => reject(new Error('Timeout')), 17000)
                );

                try {
                    const response = await Promise.race([originalFetch(input, init), timeoutPromise]);
                    const clonedResponse = response.clone();

                    clonedResponse.json().then((data) => {
                        url.includes('/myaddressBack/parametros_canal') && that.sendDataToLogsMyAddress(JSON.stringify(data));
                    }).catch(error => {
                        url.includes('/myaddressBack/parametros_canal') && that.sendDataToLogsMyAddress(JSON.stringify(error));
                    }).finally(() => {
                        that.setLoadingMyAddress(false);
                    });

                    return response;
                } catch (error) {
                    let errorMessage = 'Unknown error';
                    if (error instanceof Error) {
                        errorMessage = error.message; 
                    } else if (typeof error === 'object' && error !== null) {
                        errorMessage = JSON.stringify(error); 
                    }

                    if (errorMessage.includes('Timeout')) {
                        that.sendDataToLogsMyAddress('Error: Timeout en la petición a ' + url);
                    } else {
                        url.includes('/myaddressBack/parametros_canal') && that.sendDataToLogsMyAddress(errorMessage);
                    }
                    that.setLoadingMyAddress(false);
                    throw error;
                }
            }

            return originalFetch(input, init);
        }
    }

    public setLoadingMyAddress(data) {
        this.loadingMyAddress.next(data);
    }

    public getLoadingMyAddress() {
        return this.loadingMyAddress.asObservable();
    }

    //Endpoint de mscomun para mandar logs de la llamada interceptada
    public async sendDataToLogsMyAddress(data: any) {
        try {
            await fetch(environment.comunEndpoint + 'logs/myaddress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.authService.getToken()
                },
                body: JSON.stringify({ log: data }) // Enviar como JSON válido
            });
        } catch (error) {
            console.error('Error al enviar datos al endpoint:', error);
        }
    }
}
