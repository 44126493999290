<form *ngIf="muestraCobertura == true" [formGroup]="formGroup">
    <ng-container *ngIf="!environment.enableMyAddress">
        <h6 class="resultados-norm">Resultados:</h6>

<!-- LOCALIDAD -->
<h6 class="localidad-norm">Localidad:</h6>
<div class="radio-btn-group" [formGroup]="formGroup">
    <div class="radio" *ngFor='let localidad of localidades'>
        <input id="{{localidad}}" formControlName="localidadCobertura" type="radio" name="localidadCobertura" value="{{localidad}}">
        <label for="{{localidad}}" (click)="onChangeLocalidad(localidad)">{{localidad}}</label>
        <mat-error *ngIf="errorHandling('localidadCobertura', 'required')">
            Campo obligatorio.
        </mat-error>
    </div>
    <button [disabled]="isLoading" class="reset_radio ms-2" (click)="onReset(formGroup)">Otra localidad</button>
</div>

<!-- DIRECCIONES SUGERIDAS -->
<div class="radio" id="direcciones_sugeridas" *ngIf="direccionesSugeridas !== null ">
    <h6 class="direccion-norm">Dirección seleccionada:</h6>
    <div class="radio-btn-group" [formGroup]="formGroup">
        <div class="radio" *ngFor='let dir of direccionesSugeridas'>
            <input id="{{dir.texto}}" formControlName="direccionesCobertura" type="radio" name="direccionesCobertura" value="{{dir.texto}}" (click)="onChangeDireccionesSugeridas(dir)">
            <label for="{{dir.texto}}"> {{dir.streetType }}
                {{(dir.streetName ? ' , ' + dir.streetName : '')}}
                {{(dir.streetNr ? ' , ' + dir.streetNr : '')}}
                {{(dir.postCode ? ' , ' + dir.postCode : '')}}
            </label>
            <mat-error *ngIf="errorHandling('direccionesCobertura', 'required')">
                Campo obligatorio.
            </mat-error>
        </div>
        <button [disabled]="isLoading" class="reset_radio ms-2" (click)="onReset(formGroup)">Otra dirección</button>
    </div>
</div>

<div id="consulta_cobertura" class="row">
    <!-- PLANTA -->
    <div id="planta" class="col-lg-4 planta mt-3" *ngIf="plantas !== null && plantas.length != 0 ">
        <digo-select
            id="plantaCobertura"
            label="Selecciona planta"
            [formGroup]="formGroup"
            controlName="plantaCobertura"
            (OnChange)="onChangePlanta()"
            [options]="plantas"
            optionValue="id"
            [allowDefaultEmpty]="true"
            [isRequired]="true"
        />
    </div>

    <div id="contenedor-normalizacion-vertical" class="col-lg-8">
        <!-- BIS -->
        <div *ngIf="bisOption !== null && bisOption.length != 0 " class="col-lg-12 mt-3">
            <ng-template [ngIf]="bisOption.length < 5" [ngIfElse]="selectBis">
                <label class="label-select-piso">Selecciona bis</label>
                <div class="radio-btn-group piso" [formGroup]="formGroup">
                    <div class="radio" *ngFor="let bis of bisOption">
                        <input id="streetNrSuffix-{{bis.value}}" formControlName="bisCobertura" type="radio" name="bisCobertura" value="{{bis.value}}">
                        <label for="streetNrSuffix-{{bis.value}}" (click)="onSelectOpcion('streetNrSuffix', bis.value)">{{bis}}</label>
                    </div>
                </div>
                <mat-error *ngIf="errorHandling('bisCobertura', 'required')">
                    Campo obligatorio.
                </mat-error>
            </ng-template>
            <ng-template #selectBis>
                <digo-select
                    id="bisCobertura"
                    label="Bis"
                    [formGroup]="formGroup"
                    controlName="bisCobertura"
                    (OnChange)="onSelectOpcion('streetNrSuffix', $event.target.value)"
                    [options]="bisOption"
                    optionValue="id"
                    [allowDefaultEmpty]="true"
                    [isRequired]="true"
                />
            </ng-template>
        </div>

        <!-- Bloque -->
        <div *ngIf="bloqueOption !== null && bloqueOption.length != 0 " class="col-lg-12 mt-3">
            <ng-template [ngIf]="bloqueOption.length < 5" [ngIfElse]="selectBloque">
                <label class="label-select-piso">Selecciona {{ bloqueLabel ? bloqueLabel : 'bloque' }}</label>
                <div class="radio-btn-group piso" [formGroup]="formGroup">
                    <div class="radio" *ngFor="let bloque of bloqueOption">
                        <input id="subUnitType-{{bloque.value}}" formControlName="bloqueCobertura" type="radio" name="bloqueCobertura" value="{{bloque.value}}">
                        <label for="subUnitType-{{bloque.value}}" (click)="onSelectOpcion('subUnitType', bloque.value)">{{bloque.value}}</label>
                    </div>
                </div>
                <mat-error *ngIf="errorHandling('bloqueCobertura', 'required')">
                    Campo obligatorio.
                </mat-error>
            </ng-template>
            <ng-template #selectBloque>
                <digo-select
                    id="bloqueCobertura"
                    label="{{ bloqueLabel ? bloqueLabel : 'bloque' }}"
                    [formGroup]="formGroup"
                    controlName="bloqueCobertura"
                    (OnChange)="onSelectOpcion('subUnitType', $event.target.value)"
                    [options]="bloqueOption"
                    optionValue="id"
                    [allowDefaultEmpty]="true"
                    [isRequired]="true"
                />
            </ng-template>
        </div>

        <!-- Se repite el mismo patrón para portal, escalera, tipo puerta y puerta -->
        
    </div>
</div>

<div id="Errores_ninguna" *ngIf="muestraErrorPeticion == true">
    <mat-error>
        <p class="alert alert-warning">
            {{msgErrorPeticion}}
        </p>
    </mat-error>
</div>
</ng-container>

    <div class="row mt-3">
        <div id="botones_cobertura" *ngIf="muestraBotonesCobertura === true" class="col-12 col-md">
            <button [disabled]="isLoading" type="submit" class="btn btn-simyo mb-1" (click)="onConsultarCobertura()">
                <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                {{textDefault}}
            </button>
        </div>
        <div class="col-12 col-md-auto mt-1 cursor-pointer" *ngIf="muestraBotonNinguna && !environment.production && !environment.enableMyAddress">
            <a (click)="openModalAddress()" class="mb-1 text-direccion">No encuentro mi dirección exacta</a>
        </div>
    </div>
</form>
