import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SimyoService} from '../../services/simyo.service';
import {SimyoOrderService} from '../../services/simyo-order.service';
import {FormGroup} from '@angular/forms';
import {Order} from '../../classes/order';
import {SimyoGlobals} from '../../classes/simyo-globals';
import {mobileContractType, orderType, superStatus, documentationTypes, translateSignType} from 'src/app/shared/constantes';
import { interval, Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { signatureDocuments } from '../../models/signatureDocuments';
import { getUnique } from 'src/app/utils/getUniqueFromArray';
import { allPermissions } from 'src/app/shared/permissions';
import moment from 'moment';

@Component({
    selector: 'app-simyo-finished',
    templateUrl: './simyo-finished.component.html',
    styleUrls: ['./simyo-finished.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoFinishedComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('modalPrepago') modalPrepago: NgbModalRef;
    @Input() set orderLoader(val: any) {
        this.orderInfo = val;
    };
    @Input() signatureFormGroup: FormGroup;
    public order: Order;
    public typeOrder: string;
    public rateFtth: any = null;
    public ratesMobiles = [];
    public orderInfo: any;
    public date = null;
    public superStatus: any = superStatus;
    private initialTime = 0;
    private finishTimeLimit = 60000;
    public checkStatusSubscription: Subscription;
    public orderType = orderType;
    public numLinePrepago: number;
    public dataOrderToSignature: signatureDocuments;
    public documentationMode: { [key: string]: string } = documentationTypes;
    public translateSignType = translateSignType;
    private allSubscription: Subscription[] = [];
    public customerData: any;
    public allPermissions = allPermissions;

    constructor(
        private simyoService: SimyoService,
        private orderService: SimyoOrderService,
        public simyoGlobals: SimyoGlobals,
        public permSv: PermissionService,
        private modalService: NgbModal
    ) {
        localStorage.removeItem('appointment');
     }

    ngOnInit(): void {
        this.getDate();

        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                this.typeOrder = data;
            })
        );

        this.allSubscription.push(this.simyoService.getRateFtth()
            .subscribe((data: any) => {
                this.rateFtth = data;
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data != null) {
                    this.ratesMobiles = data;
                }
            })
        );

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((data: any) => {
                this.order = data;
                if(this.orderInfo && this.orderInfo.mobiles) {
                    this.order.mobiles = this.order.mobiles.map((mobile, index) => {
                        return {...mobile, ...this.orderInfo.mobiles[index]}
                    })
                }
                if(this.orderInfo && this.orderInfo.broadband) {
                    this.order.broadband = {...this.order.broadband, ...this.orderInfo.broadband}
                }
            })
        );

        this.allSubscription.push(this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.customerData = data;
            })
        );

        this.initialTime = new Date().getTime();
        this.checkStatusSubscription = interval(5000)
            .subscribe(val => {
                if (this.order && this.order.order_id) {
                    this.getSummaryData(this.order.order_id);
                }
            });
    }

    ngAfterViewInit(): void {
        if (this.orderInfo && (this.orderInfo.super_status === superStatus.in_process || this.orderInfo.super_status === superStatus.active) && this.orderInfo.mobiles.find(rate => rate.contract_type === mobileContractType.prepaid)) {
            this.numLinePrepago = this.orderInfo.mobiles.filter(rate => rate.contract_type === mobileContractType.prepaid).length;
            this.modalService.open(this.modalPrepago, {size: 'lg', centered: true, backdrop: 'static', keyboard : false});
        }
    }

    private getSummaryData(orderId) {
        this.orderService.getSummaryData(orderId)
            .subscribe((response: any) => {
                if (response && response.msg && response.msg.length) {
                    this.mergeSummaryData(response.msg[0]);
                    let allPhonesToContact: any[] = [{phone: this.orderInfo.customer_phone_number, type: 'Contacto'}];
                    this.orderInfo.mobiles.map((mobile, index) => mobile.msisdn !== '' ? allPhonesToContact.push({phone: mobile.msisdn, type: index+1 + 'ª línea'}) : null);
                    this.dataOrderToSignature = {
                        orderId: this.orderInfo.order_id,
                        email: this.orderInfo.customer_email,
                        phones: getUnique(allPhonesToContact, 'phone')
                    };
                    const now = new Date().getTime();
                    if ((this.initialTime + this.finishTimeLimit) <= now) {
                        this.unsubscribeCheckStatus();
                    }
                    if (this.orderInfo.type === orderType.BROADBAND || (this.orderInfo.super_status !== superStatus.in_process && this.orderInfo.super_status !== superStatus.active)) {
                        this.unsubscribeCheckStatus();
                    } else {
                        if (this.orderInfo.mobiles && this.orderInfo.mobiles.length && !this.orderInfo.mobiles.find(movil => !movil.portability && movil.new_msisdn === '')) {
                            this.unsubscribeCheckStatus();
                        }
                    }
                }
            });
    }

    private mergeSummaryData(summaryData) {
        if (this.orderInfo) {
            // Generic
            this.orderInfo.allow_check_broadband = summaryData.allow_check_broadband;
            this.orderInfo.allow_check_mobile = summaryData.allow_check_mobile;
            this.orderInfo.annotation = summaryData.annotation;
            this.orderInfo.can_retry = summaryData.can_retry;
            this.orderInfo.error_description = summaryData.error_description;
            this.orderInfo.exit_progress_bar = summaryData.exit_progress_bar;
            this.orderInfo.response_status_description = summaryData.response_status_description;
            this.orderInfo.sign_type = summaryData.sign_type;
            this.orderInfo.status_description = summaryData.status_description;
            this.orderInfo.super_status = summaryData.super_status;
            this.orderInfo.type = summaryData.type;
            if (this.orderInfo.status_msg) {
                this.orderInfo.status_msg.description = summaryData.status_msg.description;
                this.orderInfo.status_msg.title = summaryData.status_msg.title;
            }
            if (this.orderInfo.status) {
                this.orderInfo.status.code = summaryData.status.code;
            }
            // Broadband
            if (this.orderInfo.broadband) {
                this.orderInfo.broadband.activation_date = summaryData.broadband.activation_date;
                this.orderInfo.broadband.can_retry = summaryData.broadband.can_retry;
                this.orderInfo.broadband.status_description = summaryData.broadband.status_description;
                this.orderInfo.broadband.super_status = summaryData.broadband.super_status;
            }
            // Mobiles
            if (this.orderInfo.mobiles && this.orderInfo.mobiles.length) {
                this.orderInfo.mobiles.forEach((mobile, index) => {
                    mobile.activation_date = summaryData.mobiles[index].activation_date;
                    mobile.can_retry = summaryData.mobiles[index].can_retry;
                    mobile.new_msisdn = summaryData.mobiles[index].new_msisdn;
                    mobile.status_description = summaryData.mobiles[index].status_description;
                    mobile.super_status = summaryData.mobiles[index].super_status;
                });
            }
        }
    }

    getDate() {
        this.date = new Date();
    }

    unsubscribeCheckStatus() {
        if (this.checkStatusSubscription) {
            this.checkStatusSubscription.unsubscribe();
        }
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
        this.unsubscribeCheckStatus();
    }

    formatAppointmentDate(appointmentDate) {
        return moment(appointmentDate).format('DD/MM/YYYY HH:mm');
    }

}
