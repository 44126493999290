import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MyaddressService } from 'src/app/services/myaddress.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-simyo-my-address',
    templateUrl: './simyo-my-address.component.html',
    styleUrls: ['./simyo-my-address.component.scss'],
})
export class SimyoMyAddressComponent implements OnInit, AfterViewChecked, OnDestroy {
    @Output() addressSelected = new EventEmitter();
    @Output() backToFastSearch = new EventEmitter<boolean>();

    public id: string = '';
    public loading: boolean = false;
    public initialized: boolean = false;
    public showBackToFastSearch: boolean = false;
    public showComponente: boolean = true;
    private subcription: any;
    public env = environment;

    constructor(
        @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
        private myaddressService: MyaddressService,
        private eRef: ElementRef
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.subcription = this.myaddressService.getLoadingMyAddress()
            .subscribe((data: boolean) => {
                this.loading = data;
                this.cdr.detectChanges();
            })
    }

    ngAfterViewChecked(): void {
        if (!this.showBackToFastSearch) {
            this.eRef.nativeElement.querySelectorAll('p').forEach(p => {
                if (p.textContent?.includes('No encontramos esta dirección. Añade tu dirección manualmente')) {
                    p.addEventListener('click', () => {
                        this.showBackToFastSearch = true;
                    });
                }
            });
        }
    }

    addressCreated(event) {
        console.log('addressCreated', event);
    }

    sendInitialized(event) {
        this.loading = false;
        this.initialized = true;
        console.log('sendInitialized', event);
    }

    public resetComponent(): void {
        this.showComponente = false;
        this.loading = true;
        this.initialized = false;
        this.showBackToFastSearch = false;
        setTimeout(() => {
            this.showComponente = true;
        }, 0);
    }

    public addrInput(event) {}

    public changeAddress(event) {}

    ngOnDestroy(): void {
        if (this.subcription) {
            this.subcription.unsubscribe();
        }
    }
}
