import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import * as moment from 'moment/moment';
import { delay, switchMap, tap, catchError, shareReplay, map, debounceTime, startWith, pairwise } from 'rxjs/operators';
import { snakeToCamel } from 'src/app/utils/convertKeysObject';
import { ActivatedRoute, Router } from '@angular/router';
import { PointsService } from 'src/app/home/services/points.service';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';

@Component({
    selector: 'app-points-movements',
    templateUrl: './movements.component.html',
    styleUrls: ['./movements.component.scss'],
})
export class MovementsComponent implements OnInit {
    @Input() pdv;
    @Output() onGoBack = new EventEmitter();

    public filtersFormGroup: FormGroup;
    public maxDate;
    public minDate = '01/01/1900 00:00:00';
    public filterBrandFormGroup: FormGroup;
    public allPermissions = allPermissions;

    public pointList$: Observable<any>;
    public reloadPointsList$: Subject<any> = new Subject();
    public statusPointsList$: BehaviorSubject<string> = new BehaviorSubject( 'initial' );
    public statusFilterList$: BehaviorSubject<string> = new BehaviorSubject( 'initial' );
    public initialFilters = {
      limit: 20,
      page: 1,
      filters: {
          created_at: '',
          type: '',
          product: ''
      },
      brands: []
    }
    public filterValues$: BehaviorSubject<any> = new BehaviorSubject(this.initialFilters);
    public filters$$: Subject<any> = new Subject();
    public brands = {
        simyo: 'simyo',
        orange: 'orange',
        jazztel: 'jazztel',
    };

    public dataSource = [];
    public displayedColumns: string[] = ['points', 'type', 'product', 'msisdn', 'activationDate', 'date', 'brand', 'balance'];
    public automaticLoad: boolean = true;
    public lastPage: number = 0;
    public showLoadMore: boolean = true;
    /*Mock data*/
    public listType = [];

    public productType = [];

    constructor(
        private formBuilder: FormBuilder,
        public permSv: PermissionService,
        private route: ActivatedRoute,
        private pointsService: PointsService
    ) {
        this.initForms();
        const today = new Date();
        this.maxDate = today.getDate() + 1;

        const route$ = this.route.queryParams.pipe(delay(0));
        const reload$ = route$.pipe(switchMap(() => this.reloadPointsList$));

        route$.pipe(
          tap(() => this.statusFilterList$.next('loading')),
          switchMap(() =>
              this.pointsService.getFilters().pipe(
                  tap(() => this.statusFilterList$.next('initial')),
                  catchError((err) => {
                      this.statusFilterList$.next('error');
                      return of({});
                  })
              )
          ),
          map((data: any) => snakeToCamel(data)),
          tap((data: any) => {
            let valueEmpty = {};
            valueEmpty[""] = "Todas";
            this.listType = { ...valueEmpty, ...data.movementTypes};
            this.productType = { ...valueEmpty, ...data.products};
          }),
          shareReplay()
      ).subscribe();

        this.pointList$ = merge(route$, reload$).pipe(
            tap((filters) => this.statusPointsList$.next(filters.stauts ?? 'loading')),
            map(filters => ({...this.filterValues$.getValue(), ...filters})),
            tap((filters) => this.filterValues$.next(filters)),
            switchMap((filters) =>
                this.pointsService.postPointsList(filters).pipe(
                    tap(() => this.statusPointsList$.next('initial')),
                    catchError((err) => {
                        this.statusPointsList$.next('error');
                        return of({});
                    })
                )
            ),
            map((data: any) => snakeToCamel(data)),
            tap((data: any) => {
              if(data.length > 0) {
                if(data[0].currentPage === 1) {
                  this.dataSource = data[0].data;
                } else {
                  this.dataSource = [...this.dataSource, ...data[0].data];
                }
                this.lastPage = data[0].lastPage;
                this.showLoadMore = data[0].currentPage < data[0].lastPage;
                return;
              }
              this.statusPointsList$.next('error');
            }),
            shareReplay()
        );
        this.filters$$.pipe(debounceTime(300)).subscribe(filter => {
            this.reloadPointsList$.next(filter);
        })


    }

    ngOnInit(): void { }

    initForms() {
        this.filterBrandFormGroup = this.formBuilder.group({
            simyo: [true],
            orange: [true],
            jazztel: [true],
        });

        this.filtersFormGroup = this.formBuilder.group({
            type: [''],
            product: [''],
            created_at: new FormControl({value: null, disabled: false})
        });

        this.filtersFormGroup.valueChanges.subscribe(data => {
            data = {
                ...data,
                created_at: data.created_at != null ? data.created_at?.format('YYYY-MM-DD') : ''
            }

            this.filters$$.next({page: 1, filters: data, status: 'loading'})
        });

        this.filterBrandFormGroup.valueChanges
        .pipe(startWith(null as string), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
            let filtersBrand = next;
            let maxLength = Object.keys(next).length;
            if(prev === null || Object.keys(prev).filter(key => prev[key] === true).length === maxLength) {
                filtersBrand = Object.fromEntries(Object.entries(next).map(([clave, valor]) => [clave, !valor]));
                this.filterBrandFormGroup.patchValue(filtersBrand, {emitEvent: false});
            }

            let brandsActive: string[] = Object.keys(filtersBrand).filter(key => filtersBrand[key] === true);
            this.filters$$.next({page: 1, brands: brandsActive.length === maxLength ? [] : brandsActive, status: 'loading'});
        });

    }

    convertDate(data): string {
        return data && data !== '' ? moment(data).format('DD/MM/YYYY') : '-';
    }

    getImgBrand(brand) : string {
      if(!brand) return '';
      brand = brand.toLowerCase();
        switch (brand) {
            case this.brands.orange:
                return 'or-login-color.svg';
            case this.brands.jazztel:
                return 'jz-login-color.svg';
            case this.brands.simyo:
                return 'sm-login-color.svg';
            default:
                return '';
        }
    }

    nextPage() {
        let currentFilter = this.filterValues$.getValue();
        let nextPage = currentFilter.page + 1;
        if(nextPage <= this.lastPage) {
            this.filters$$.next({page: nextPage, status: 'loadingMore'});
        }
    }

    goBack() {
      this.onGoBack.emit();
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        if (this.automaticLoad && this.statusPointsList$.getValue() !== 'loading') {
            const element: any = event.srcElement;
            const scrollOffset = element.children[0].scrollTop;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight - 100;

            if (scrollOffset > windowHeight) {
                let currentFilter = this.filterValues$.getValue();
                this.automaticLoad = currentFilter.page >= 2 ? false : true;
                this.nextPage();
            }
        }
    }
}
