import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { HomeBrand } from '../../../../models/home-brand';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';

@Component({
    selector: 'app-hm-brand',
    templateUrl: './hm-brand.component.html',
    styleUrls: ['./hm-brand.component.css']
})
export class HmBrandComponent implements OnInit, AfterViewInit {
    @Input() brand: HomeBrand;
    @ViewChild('contentRef') contentElement: ElementRef;
    public allPermissions = allPermissions;
    public contentHeight: number = 0; //Se usa para animar la expansión

    constructor(public permSv: PermissionService, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.contentElement) {
            this.contentHeight = this.contentElement.nativeElement.scrollHeight;
            this.cdRef.detectChanges();
        }
    }

    getCardClass() {
        return {
            [`card--brand`]: true,
            [`card--brand--${this.brand.prefix}`]: true,
            [`card--brand--2`]: this.brand.isOpen,
        };
    }

    toggleOpen() {
        this.brand.onToggle(!this.brand.isOpen ? this.brand.prefix : null);

        if (this.brand.canOpen) {
            this.brand.isOpen = !this.brand.isOpen;
        }

        setTimeout(() => {
            if (this.contentElement) {
                this.contentHeight = this.brand.isOpen ? this.contentElement.nativeElement.scrollHeight : 0;
                this.cdRef.detectChanges();
            }
        }, 10);
    }
}
