import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { CreatePrepaidResponse } from 'src/app/orange/models/createPrepaid';
import {MasterAllOrange, OrangeDocumentType} from 'src/app/orange/models/masterAllResponse';
import { AppState } from 'src/app/orange/redux';
import { setOrderDraftId, setProvinceByPostalCode} from 'src/app/orange/redux/orange.actions';
import { IOrangePrepaidState } from 'src/app/orange/redux/orange.state';
import { OrangeOrdersService } from 'src/app/orange/services/orange-orders.service';
import { OrangePrepaidHiringService } from 'src/app/orange/services/orange-prepaid-hiring.service';
import { brands, maxDate, minDate, orangePrepaidTypes, orangeWelcomePackCodes } from 'src/app/shared/constantes';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { cifValidator, dniValidator, getMaxLengthByDoc, nieValidator } from 'src/app/utils/validators/spanish-id-validator';

@Component({
    selector: 'app-orange-prepaid-user-data',
    templateUrl: './orange-prepaid-user-data.component.html',
    styleUrls: ['./orange-prepaid-user-data.component.scss', '../../../../../assets/css/orange-theme.css']
})
export class OrangePrepaidUserDataComponent implements OnInit {

    @Input() userDataFormGroup: FormGroup;
    @Input() agreementsFormGroup: FormGroup;
    @Input() stepper: MatStepper;
    @Input() pdv: Ipdv;
    @Input() configurationFormGroup: FormGroup;
    @Input() type: string;

    private validationEmailRequest: Subscription;
    public maxLengthByDoc: number = 9;
    public maxDate: Date = maxDate;
    public minDate: Date = minDate;
    public orange$: Observable<IOrangePrepaidState>;
    public orangeWelcomePackCodes: { [key: string]: string } = orangeWelcomePackCodes;
    public master: MasterAllOrange;
    public documentTypeName: string = '';
    public onlySpain = false;
    public mailInfo = "El mail del cliente es obligatorio para realizar el envío del resumen de contrato";
    public errorEmailValidation: string = '';
    public validEmailLoading: boolean = false;
    public isCreateLoading: boolean = false;
    public showErrorOrderMsg: string = 'Error, no pudimos crear el pedido';
    public showErrorOrder: boolean = false;

    public allowedAllCountries = false;
    public countries = [];
    public document_types = [];
    constructor(
        private orangeStore: Store<AppState>,
        private orangePrepaidHiringService: OrangePrepaidHiringService,
        private orangeOrderService: OrangeOrdersService
    ) {
        this.orange$ = orangeStore.pipe(select('orangePrepaid'));
    }

    ngOnInit(): void {
        this.orange$.pipe(  
          distinctUntilChanged((prev, curr) =>  JSON.stringify(prev) === JSON.stringify(curr))  
        ).subscribe(d => {
            this.master = d.master;

            let countrieFiltered = d.master?.countries?.filter(country => {
              return (this.onlySpain && country.country_id === 64)
                || (!this.onlySpain && country.country_id !== 64)
                || this.allowedAllCountries;
            });

            if (JSON.stringify(countrieFiltered) !== JSON.stringify(this.countries)) {
                this.countries = countrieFiltered;
            }

            let documentsFiltered = d.master?.document_types?.filter(document_type =>  
                document_type.name !== 'CIF' && document_type.name !== 'Otro'
            );

            if (JSON.stringify(documentsFiltered) !== JSON.stringify(this.document_types)) {
                this.document_types = documentsFiltered;
            };

            if(d.user_data_form_values.province !== this.userDataFormGroup.value.province){
                this.userDataFormGroup.get('province').patchValue(d.user_data_form_values.province);
            }            
        });
      }

    public changeTypeDocument(documentType: OrangeDocumentType): void {
        const docValidators: ValidatorFn[] = [Validators.required,  Validators.pattern('^[a-zA-Z0-9]+$')];

        if (documentType !== null) {
            this.documentTypeName = this.document_types.find(doc => doc.document_type_id == documentType).name;
        }

        this.onlySpain = false;
        this.allowedAllCountries = false;
        this.userDataFormGroup.get('second_surname').clearValidators();
        this.userDataFormGroup.get('second_surname').updateValueAndValidity();

        switch (this.documentTypeName) {
            case 'NIF':
            case 'DNI':
            case 'DNI/NIF':
                this.setNationalityToSpain()
                docValidators.push(dniValidator());
                break;
            case 'NIE':
                this.userDataFormGroup.get('nacionality').patchValue(null);
                docValidators.push(nieValidator());
                break;
            case 'CIF':
                this.setNationalityToSpain()
                docValidators.push(cifValidator());
                break;
            case 'Pasaporte':
            case 'PASAPORTE':
                this.allowedAllCountries = true;
                this.userDataFormGroup.get('nacionality').patchValue(null);
                break;
            default:
                this.userDataFormGroup.get('nacionality').patchValue(null);
        }

        this.userDataFormGroup.get('document_number').setValidators(docValidators);
        this.userDataFormGroup.get('document_number').updateValueAndValidity();
        //this.onChanges();
    }

    private setNationalityToSpain() {
        this.onlySpain = true;
        this.userDataFormGroup.get('nacionality').patchValue(this.master.countries.find(country => country.country_id === 64).country_id);
    }

    public setMaxLengthByDoc(documentType: OrangeDocumentType) {
        if (documentType !== null) {
            this.maxLengthByDoc = getMaxLengthByDoc(brands.orange, documentType);
        }
    }

    public next(orderId) {
        if (this.validate()) {
            if(orderId === null && this.type === orangePrepaidTypes.esim) {
                this.createDraftOrder();
                return;
            }

            this.stepper.next();
            return;
        }

        this.focusAndScrollFirstError()
        
    }

    public onChangeZip(value) {
        if (value && +value > 99999) {
            value = value.substring(0,5);
            this.userDataFormGroup.get('postal_code').patchValue(value);
            //this.onChanges();
        }
        this.orangeStore.dispatch(setProvinceByPostalCode({postalCode: +value}));
    }

    onlyPositiveDigits(event){
        if (event.key.length > 1) {
            return true;
        }
        if (+event.key >= 0 && +event.key <= 9 ) {
            return true;
        }
        return false;
    }

    public onChanges(){
        //Si esta todo correcto, scroll para que se vea el nuevo botón de abajo
        if (this.userDataFormGroup.valid && this.agreementsFormGroup.valid) {
            setTimeout(() => {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0 ,
                    behavior: 'smooth'
                });
            }, 1000);
        }
    }
    public isValidEmail(): void {
        if (this.userDataFormGroup.get('email').valid) {
            this.errorEmailValidation = '';
            this.validEmailLoading = true;
            if (this.validationEmailRequest) {
                this.validationEmailRequest.unsubscribe();
            }
            this.validationEmailRequest = this.orangePrepaidHiringService.emailPrepaidValid(this.userDataFormGroup.get('email').value)
                .subscribe((data: any) => {
                    if (data) {
                        if (data.msg === 'ok') {
                            this.userDataFormGroup.get('email').setErrors(null);
                        }
                        if (data.msg === 'ko') {
                            this.userDataFormGroup.get('email').markAsTouched();
                            this.userDataFormGroup.get('email').setErrors({ invalid: true });
                            this.errorEmailValidation = data.error;
                        }
                    } else {
                        this.errorEmailValidation = 'Ha ocurrido un error al validar el email. Inténtelo de nuevo más tarde';
                    }
                    this.validEmailLoading = false;
                }, (error: HttpErrorResponse) => {
                    this.errorEmailValidation = 'Ha ocurrido un error al validar el email. Inténtelo de nuevo más tarde';
                    this.validEmailLoading = false;
                });
        }
    }

    private validate() {
        this.userDataFormGroup.markAllAsTouched();
        this.userDataFormGroup.updateValueAndValidity();
        if (!this.userDataFormGroup.valid) {
            const elements: any = document.getElementsByClassName('ng-invalid');
            if (elements.length > 0) {
                elements[0].focus();
            }
            return false;
        }
        return true;
    }

    private createDraftOrder() {
        if (this.configurationFormGroup.valid) {
            this.isCreateLoading = true;
            let userData = {
                ...this.userDataFormGroup.value,
                province: this.userDataFormGroup.get('province').value
            };
            return this.orangeOrderService.createDraftOrderEsim(
                userData,
                this.configurationFormGroup.value,
                this.agreementsFormGroup.value,
                this.type,
                '',
                false,
                this.pdv?.sfids?.orange)
                .subscribe(r => this.onCreateSuccess(r), e => this.onCreateError(e), () => this.onCreateComplete());
                
        }
    }

    private onCreateSuccess(response: CreatePrepaidResponse) {
        console.log('onsuccess', response);
        if (response?.msg) {
            if (response?.msg?.order_id && response?.msg?.order_id !== '') {
                this.orangeStore.dispatch(setOrderDraftId({orderDraftId: response.msg.order_id}));
                this.stepper.next();
                return;
            }
        }

        if (response?.error?.error_code.toString().includes('E')) {
            this.showErrorOrderMsg = response.error.msg + ' (' + response?.error?.error_code.toString() + ')';
        }

        this.showErrorOrder = true;
    }

    private onCreateError(error: HttpErrorResponse) {
        this.showErrorOrder = true;
    }

    private onCreateComplete() {
        this.isCreateLoading = false;
    }

    public checkErrorFieldForm(fieldName, errors:string[]) {
        let hasError = this.userDataFormGroup.get(fieldName)?.errors ? Object.keys(this.userDataFormGroup.get(fieldName)?.errors).findIndex((key) => errors.includes(key)) > -1 : false;
        return hasError;
        //return hasError && this.userDataFormGroup.get(fieldName).touched;
    }
    private focusAndScrollFirstError() {
        const formulario = document.getElementById('userDataForm') as HTMLFormElement;
        if (formulario) {
            const camposConErrores = formulario.querySelectorAll(':invalid');
            
            if (camposConErrores.length > 0) {
                const primerCampoConError = camposConErrores[0] as HTMLElement;
            
                primerCampoConError.focus();
                primerCampoConError.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    alphanumericOnly(event) {
        let pattern: RegExp = /^[a-zA-Z0-9]+$/;
        const inputChar = event.key;
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
}
