import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SimyoService } from '../../services/simyo.service';
import * as moment from 'moment/moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UserNotFoundComponent } from 'src/app/shared/components/user-not-found/user-not-found.component';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DigoService } from 'src/app/services/digo.service';
import { SimyoModalReprocessComponent } from '../simyo-modal-reprocess/simyo-modal-reprocess.component';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { fiberIndirectTechnologies, GenericList, listSortByDate, listType, orderType, simyoDocStatus, superStatus, superStatusTranslate, translateSignType, typeCard } from 'src/app/shared/constantes';
import { Subscription } from 'rxjs';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import { masterAllResponseSimyo, masterAllSimyo } from '../../models/masterAllSimyo';
import { orderListDetail, orderListFilters, orderListRequest, orderListResponseSimyo, orderListStats, orderListStatsResponseSimyo } from '../../models/orderListSimyo';
import { HttpErrorResponse } from '@angular/common/http';
import { SimyoGlobals } from '../../classes/simyo-globals';

const ELEMENTS_BY_PAGE: number = 50;

@Component({
    selector: 'app-simyo-listado-activaciones',
    templateUrl: './simyo-listado-activaciones.component.html',
    styleUrls: ['./simyo-listado-activaciones.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoListadoActivacionesComponent implements OnInit, OnDestroy {

    public loaded: boolean = false;
    public isCollapsed: boolean = true;
    public savingAnnotation = -1;
    public orders: orderListDetail[] = [];
    public ordersFiltered: orderListDetail[] = [];
    public minDate: moment.Moment;
    public maxDate: moment.Moment;
    public faCalendarDay = faCalendarDay;
    public isAlertView: boolean = false;
    public body: orderListRequest = {
        limit: ELEMENTS_BY_PAGE,
        page: 1,
        order: {
            created_at: 'desc'
        }
    };
    public pages: number[] = [];
    public lastPage: number = 0;
    public listType = [{ label: "Todas", id: "" }, ...listType];
    public listSortByDate: GenericList[] = listSortByDate;
    private automaticLoad: boolean = true;
    public formGroup: FormGroup;
    public currentMonth: string;
    public rateMaster: any;
    public master: masterAllSimyo;
    public superStatus: { [key: string]: string } = superStatus;
    public superStatusTranslate: { [key: string]: string } = superStatusTranslate;
    public errorSearch: boolean = false;
    public allPermissions = allPermissions;
    public typeUpdate: { [key: string]: string } = {
        broadband: 'broadband',
        mobile: 'mobile',
        full: 'full'
    };
    public fiberIndirectTechnologies: string[] = fiberIndirectTechnologies;
    public translateSignType: { [key: string]: string } = translateSignType;
    public orderListStats: orderListStats;
    private allSubscription: Subscription[] = [];
    public typeCard = typeCard;
    public superStatusOptions: any[] = [{ super_status_id: "Todas", value: "" }];

    constructor(
        private formBuilder: FormBuilder,
        public simyoService: SimyoService,
        public dialog: MatDialog,
        private modalService: NgbModal,
        private digoService: DigoService,
        private simyoRateService: SimyoRateService,
        private simyoOrderService: SimyoOrderService,
        public permSv: PermissionService,
        public simyoGlobals: SimyoGlobals
    ) { }

    ngOnInit(): void {
        this.doKeepAlive();
        this.formGroup = this.formBuilder.group({
            full_name: [''],
            document: [''],
            msisdn_movil: [''],
            min_date: [''],
            max_date: [''],
            status: [''],
            contract_type: [''],
            sort_by_date: ['desc'],
        });
        this.getOrders();
        this.currentMonth = moment().locale('es').format('MMMM');

        this.simyoRateService.getRatesFromService()
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.rateMaster = data.msg;
                }
            });

        this.simyoService.getMasterAllFromService()
            .subscribe((data: masterAllResponseSimyo) => {
                if (data && data.msg) {
                    this.master = data.msg;
                    this.simyoService.setMasterAll(data.msg);
                    this.superStatusOptions = [
                        { super_status_id: "Todas", value: "" },
                        ...this.master.super_statuses.map(status => ({
                        super_status_id: superStatusTranslate[status.super_status_id] || status.super_status_id,
                        value: status.value 
                        }))
                    ];
                }
            });

        this.simyoService.getOrderListStats()
            .subscribe((data: orderListStatsResponseSimyo) => {
                if (data && data.msg) {
                    this.orderListStats = data.msg;
                }
            });
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        if (this.automaticLoad) {
            const element: any = event.srcElement;
            const scrollOffset = element.children[0].scrollTop;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight - 200;

            if (scrollOffset > windowHeight && windowHeight > 0) {
                console.log('Cargando siguiente página. A partir de ahora se carga en manual.');
                this.automaticLoad = false;
                this.nextPage();
            }
        }
    }

    private getOrders(): void {
        this.loaded = false;
        this.errorSearch = false;
        this.getOrderFilteredList(this.body);
    }

    private getOrderFilteredList(body: orderListRequest): void {
        this.simyoService.getFilteredOrderList(body)
            .subscribe((data: orderListResponseSimyo) => {
                if (data) this.processData(data);
            }, (error: HttpErrorResponse) => {
                this.errorSearch = true;
                this.loaded = true;
                if (error && error.error && error.error.error && error.error.error.errorCode !== null && error.error.error.errorCode === 462) {
                    this.dialog.open(UserNotFoundComponent, {
                        width: '350px',
                        disableClose: true,
                        data: {
                            marca: 'Simyo'
                          }
                    });
                }
            });
    }

    public getActivationThisMonth(): void {
        this.isAlertView = false;
        this.resetRequest();
        this.body.filters = {
            statuses: this.getSuperStatusFromMaster(superStatus.active),
            created_at: 'gte|' + moment().startOf('month').format('YYYY-MM-DD 00:00:00')
        };

        this.getOrderFilteredList(this.body);
    }

    public getRequestThisMonth(): void {
        this.isAlertView = false;
        this.resetRequest();
        this.body.filters = {
            created_at: 'gte|' + moment().startOf('month').format('YYYY-MM-DD 00:00:00')
        };

        this.getOrderFilteredList(this.body);
    }

    public getAlertThisMonth(page: number): void {
        const request: string = '?limit=' + ELEMENTS_BY_PAGE + '&page=' + page + '&startDate=' + moment().startOf('month').format('YYYY-MM-DD') +
            '&endDate=' + moment().format('YYYY-MM-DD') + '&daysPending=7';

        if (page === 1) {
            this.orders = [];
            this.ordersFiltered = [];
        }
        this.loaded = false;

        this.body.limit = ELEMENTS_BY_PAGE;
        this.body.page = page;
        this.simyoService.getFilterAlerts(request)
            .subscribe((data: orderListResponseSimyo) => {
                if (data) this.processData(data);
            }, (error: HttpErrorResponse) => {
                console.log(error);
            });
    }

    public getSuspendedThisMonth(): void {
        this.resetRequest();
        this.body.filters = {
            statuses: this.getSuperStatusFromMaster(superStatus.cancelled),
            created_at: 'gte|' + moment().startOf('month').format('YYYY-MM-DD 00:00:00')
        };

        this.getOrderFilteredList(this.body);
    }

    private processData(data: orderListResponseSimyo): void {
        this.loaded = true;
        this.orders = this.orders.concat(data.msg.data);
        this.ordersFiltered = this.orders;
        this.lastPage = data.msg.last_page;
        this.pages = Array(this.lastPage).fill(1).map((x, i) => i);
    }

    setMinDate(event: moment.Moment) {
        this.minDate = event;
    }

    setMaxDate(event: moment.Moment) {
        this.maxDate = event;
    }

    public searchClient(text: string) {
        this.ordersFiltered = this.orders.filter(item => {
            const fullName: string = item.customer_full_name.toLowerCase();
            const fullNameNormalized = fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            let document: string;

            if (item.customer_document) {
                document = item.customer_document.toLowerCase();
            }

            return document ? fullName.includes(text.toLowerCase()) || fullNameNormalized.includes(text.toLowerCase()) ||
                document.includes(text.toLowerCase()) : fullName.includes(text.toLowerCase()) ||
                fullNameNormalized.includes(text.toLowerCase());
        });
    }

    public advanceSearch(): void {
        this.isAlertView = false;
        const formData = this.formGroup.getRawValue();
        const filters: orderListFilters = {};

        if (formData.full_name !== '') {
            filters.customer_full_name = formData.full_name;
        }
        if (formData.document !== '') {
            filters.customer_document = formData.document;
        }
        if (formData.msisdn_movil !== '') {
            filters.mobile_msisdn = formData.msisdn_movil;
        }
        if (formData.status) {
            filters.statuses = formData.status;
        }
        if (formData.contract_type) {
            filters.type = formData.contract_type;
        }
        if (formData.sort_by_date) {
            this.body.order.created_at = formData.sort_by_date;
        }
        if (formData.min_date != null && formData.max_date != null && formData.min_date !== '' && formData.max_date !== '') {
            filters.created_at = 'bt|' + moment(formData.min_date).format('YYYY-MM-DD 00:00:00') + '|' + moment(formData.max_date).format('YYYY-MM-DD 23:59:59');
        } else if (formData.min_date != null && formData.min_date !== '') {
            filters.created_at = 'gte|' + moment(formData.min_date).format('YYYY-MM-DD 00:00:00');
        } else if (formData.max_date != null && formData.max_date !== '') {
            filters.created_at = 'lte|' + moment(formData.max_date).format('YYYY-MM-DD 23:59:59');
        }

        this.body.page = 1;
        this.body.filters = filters;
        this.orders = [];
        this.ordersFiltered = [];
        this.automaticLoad = true;

        this.getOrders();
    }

    public updateAnnotation(order: orderListDetail, annotation: string) {
        this.savingAnnotation = order.order_id;

        this.simyoService.updateAnnotation(order.order_id, annotation)
            .subscribe((data: any) => {
                order.annotation = annotation;
                this.savingAnnotation = -1;
            }, (error: HttpErrorResponse) => {
                console.error('ERROR updateAnnotation', error);
                this.savingAnnotation = -1;
            });
    }

    public getPriceMobile(rateMobile: any): number {
        if (rateMobile) {
            let priceBonos = this.getPriceBonos(rateMobile);
            return +rateMobile.rate.rate_info.contracted_price + priceBonos;
        }
    }

    public getPriceBonos(rateMobile: any): number {
        // if (rateMobile) {
        //     let priceBonos = 0;
        //     if (rateMobile.bonos && rateMobile.bonos.length) {
        //         rateMobile.bonos.forEach(x => {
        //             priceBonos += +x.rate.rate_info.price;
        //         });
        //     }
        //     return priceBonos;
        // }
        return 0;
    }

    public convertStatusClass(status: string): string {
        switch (status) {
            case superStatus.active:
                return 'dot-complete';
            case superStatus.cancelled:
            case superStatus.error:
            case superStatus.rejected:
                return 'dot-cancel';
            default:
                return 'dot-pending';
        }
    }

    public convertStatusDocClass(status: string): string {
        switch (status) {
            case simyoDocStatus.ok:
                return 'dot-complete';
            case simyoDocStatus.pte:
                return 'dot-pending';
            case simyoDocStatus.notSend:
            case simyoDocStatus.ko:
                return 'dot-cancel-red';
            default:
                return 'dot-pending';
        }
    }

    public convertDate(date: string): string {
        return moment(date).format('DD/MM/YYYY, HH:mm');
    }

    private nextPage(): void {
        if (this.body.page < this.lastPage) {
            this.body.page += 1;
            this.isAlertView ? this.getAlertThisMonth(this.body.page) : this.getOrders();
        }
    }

    public loadMore(): void {
        this.nextPage();
    }

    public openModalNewProcess(order: orderListDetail, onlyClient: boolean): void {
        const modalRef = this.modalService.open(SimyoModalReprocessComponent, {size: 'lg', centered: true})
        modalRef.componentInstance.order = order.order_id;
        modalRef.componentInstance.rates = this.rateMaster;
        modalRef.componentInstance.showOptionClient = onlyClient;
    }

    private getSuperStatusFromMaster(superStatus: string): any {
        if (this.master && this.master.super_statuses) {
            const found = this.master.super_statuses.find(super_status => super_status.super_status_id === superStatus);
            if (found) {
                return found.value;
            }
        }
        return null;
    }

    public updateStatus(order: orderListDetail, type: string): void {
        if (type === this.typeUpdate.broadband) {
            order.class_broadband = true;
            order.error_update_broadband = false;
        }
        if (type === this.typeUpdate.mobile) {
            order.class_mobile = true;
            order.error_update_mobile = false;
        }
        if (type === this.typeUpdate.full) {
            order.update_full = true;
            order.error_update_full = false;
        }
        this.simyoOrderService.getOrderFromService(order.order_id)
            .subscribe((data: any) => {
                if (data && data.msg && data.msg.length) {
                    if (type === this.typeUpdate.broadband && data.msg[0].broadband) {
                        order.broadband.status_description = data.msg[0].broadband.status_description;
                        order.class_broadband = false;
                        order.allow_check_broadband = data.msg[0].allow_check_broadband;
                    }
                    if (type === this.typeUpdate.mobile && data.msg[0].mobiles && data.msg[0].mobiles.length) {
                        order.mobiles[0].status_description = data.msg[0].mobiles[0].status_description;
                        order.class_mobile = false;
                        order.allow_check_mobile = data.msg[0].allow_check_mobile;
                    }
                    if (type === this.typeUpdate.full) {
                        this.mergeDataOrder(order, data.msg[0]);
                        order.update_full = false;
                    }
                };
            }, (error: HttpErrorResponse) => {
                if (type === this.typeUpdate.broadband) {
                    order.class_broadband = false;
                    order.error_update_broadband = true;
                }
                if (type === this.typeUpdate.mobile) {
                    order.class_mobile = false;
                    order.error_update_mobile = true;
                }
                if (type === this.typeUpdate.full) {
                    order.update_full = false;
                    order.error_update_full = true;
                }
            });
    }

    private mergeDataOrder(oldOrder: orderListDetail, newStateOrder: any) {
        if (oldOrder && newStateOrder) {
            // Broadband
            if (oldOrder.broadband) {
                oldOrder.broadband.status_description = newStateOrder.broadband?.status_description;
                oldOrder.broadband.activation_date = newStateOrder.broadband?.activation_date;
                oldOrder.broadband.super_status = newStateOrder.broadband?.super_status;
                if (oldOrder.broadband.substatus) {
                    oldOrder.broadband.substatus.name = newStateOrder.broadband?.substatus?.name;
                    oldOrder.broadband.substatus.description = newStateOrder.broadband?.substatus?.description;
                }
            }
            // Mobile
            if (oldOrder.mobiles && oldOrder.mobiles.length) {
                oldOrder.mobiles.forEach((mobile, index) => {
                    mobile.new_msisdn = newStateOrder?.mobiles[index]?.new_msisdn;
                    mobile.status_description = newStateOrder?.mobiles[index]?.status_description;
                    mobile.activation_date = newStateOrder?.mobiles[index]?.activation_date;
                    mobile.super_status = newStateOrder?.mobiles[index]?.super_status;
                });
            }
            // Generic
            oldOrder.super_status = newStateOrder.super_status;
            oldOrder.status_description = newStateOrder.status_description;
            oldOrder.doc_status = newStateOrder.doc_status;
            oldOrder.doc_annotation = newStateOrder.doc_annotation;
            oldOrder.doc_warning = newStateOrder.doc_warning;
            oldOrder.can_retry = newStateOrder.can_retry;
        }
    }

    public showUpdateOrder(order: orderListDetail): boolean {
        if (order) {
            if (order.type === orderType.BROADBAND) {
                return order.super_status !== superStatus.draft && order.broadband && (order.broadband.super_status === superStatus.in_process || (order.broadband.super_status === superStatus.active && !order.broadband.activation_date));
            }
            if (order.type === orderType.CONVERGENT) {
                return order.super_status !== superStatus.draft && (order.broadband && (order.broadband.super_status === superStatus.in_process || (order.broadband.super_status === superStatus.active && !order.broadband.activation_date))) || (order.mobiles && order.mobiles.length && order.mobiles.some(line => (line.super_status === superStatus.in_process) || (line.super_status === superStatus.active && !line.activation_date)));
            }
            if (order.type === orderType.MOBILE) {
                return order.super_status !== superStatus.draft && order.mobiles && order.mobiles.length && order.mobiles.some(line => (line.super_status === superStatus.in_process) || (line.super_status === superStatus.active && !line.activation_date));
            }
            return
        }
        return false;
    }

    private resetRequest(): void {
        this.orders = [];
        this.ordersFiltered = [];
        this.loaded = false;
        this.body.limit = ELEMENTS_BY_PAGE;
        this.body.page = 1;
        this.body.order.created_at = 'desc';
    }

    doKeepAlive(){
        this.digoService.keepAlive()
            .subscribe({
                next: (resp: any) => {
                    if (!resp || resp !== 200) {
                        this.digoService.goLogin();
                    }
                }
                , error: (error: HttpErrorResponse) => {
                    this.digoService.goLogin();
                }
            });
    }

    ngOnDestroy(): void {
        this.ordersFiltered = [];
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    getTitleLine(mobile) {
        if(mobile === undefined) return '';
        const portability = mobile?.portability;
        if(mobile?.is_esim === typeCard.esim) {
            return portability ? `Portabilidad eSIM móvil` : 'Alta eSIM';
        }

        return portability ? `Portabilidad móvil` : 'Alta móvil';
    }
}
