<section class="procesos mt-4">
    <div class="row">
        <div class="col-12 text-center my-3" *ngIf="loadBrands < brandsWithPermissions">
            <span>Cargando tarifas...</span>
            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
        </div>

        <div class="col-12 text-center my-3" *ngIf="ratesFiltered.length === 0 && loadBrands == brandsWithPermissions">
            No se han encontrado tarifas
        </div>
        <!-- filtros -->
        <div class="col-xl-3" [formGroup]="filterFormGroup" *ngIf="(brandsWithPermissions > 1 || permSv.hasPermFromV2(allPermissions.simyo.permite_vender)) && loadBrands == brandsWithPermissions">
            <div class="card--filtros--digo mb-2">
                <div class="card-body--digo">
                    <!-- Marcas -->
                    <div *ngIf="permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas) || permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
                        <h6 class="ms-2 marca--sub">Marcas</h6>

                        <div class="btn-group btn-group-brands mt-2">

                            <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                                <input type="checkbox" formControlName="brand_sm" [value]="brands.simyo"
                                       (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                <span class="btn-marca"><img src="./assets/img/sm-login-color.svg" class="img-fluid ps-1" width="120"></span>
                            </label>

                            <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
                                <input type="checkbox" formControlName="brand_or" [value]="brands.orange"
                                       (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                <span class="btn-marca"><img src="./assets/img/or-login-color.svg" class="img-fluid ps-1" width="120"></span>
                            </label>
                        </div>
                    </div>

                    <div>
                        <hr class="mb-4">

                        <!-- impuesto -->
                        <h6 class="ms-2 marca--sub">Tipo de impuesto</h6>
                        <div class="mt-2 ms-2">
                            <form [formGroup]="taxesFormGroup">
                                <div>
                                    <digo-select
                                    id="tax"
                                    label="Tipo impuesto"
                                    [formGroup]="taxesFormGroup"
                                    controlName="tax"
                                    (OnChange)="changeTaxType($event.target.value)"
                                    [options]="taxes"
                                    optionValue="value"
                                    optionLabel="name"/>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div *ngIf="countTypeRates > 1">
                        <hr class="mb-4">

                        <!-- Trámites -->
                        <h6 class="ms-2 marca--sub">Tipo de tarifa</h6>

                        <div class="area-check--login mb-3 ms-2 mt-4">
                            <div class="checkbox icheck-login" *ngIf="showConvergentCheck">
                                <input type="checkbox" id="primary-1" formControlName="type_convergent" [value]="types.convergent"
                                       [checked]="typeFilters.includes(types.convergent)"
                                       (change)="changeFilterType($event.target.value, $event.target.checked)"/>
                                <label for="primary-1" class="font--filter">Fibra y Móvil</label>
                            </div>

                            <div class="checkbox icheck-login" *ngIf="showMobileCheck">
                                <input type="checkbox" id="primary-2" formControlName="type_mobile" [value]="types.mobile"
                                       [checked]="typeFilters.includes(types.mobile)"
                                       (change)="changeFilterType($event.target.value, $event.target.checked)"/>
                                <label for="primary-2" class="font--filter">Móvil</label>
                            </div>

                            <div class="checkbox icheck-login" *ngIf="showBroadbandCheck">
                                <input type="checkbox" id="primary-3" formControlName="type_broadband" [value]="types.broadband"
                                       [checked]="typeFilters.includes(types.broadband)"
                                       (change)="changeFilterType($event.target.value, $event.target.checked)"/>
                                <label for="primary-3" class="font--filter">Fibra</label>
                            </div>

                            <div class="checkbox icheck-login pt-2" *ngIf="showAdditionalCheck">
                                <input type="checkbox" id="primary-4" formControlName="type_additional" [value]="types.adicional"
                                       [checked]="typeFilters.includes(types.adicional)"
                                       (change)="changeFilterType($event.target.value, $event.target.checked)"/>
                                <label for="primary-4" class="font--filter">Líneas adicionales</label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showMobileFilters && showMobilePostpaid && showMobilePrepaid">
                        <hr class="mb-4">

                        <h6 class="ms-2 marca--sub">Modalidad de Móvil</h6>

                        <div class="area-check--login mb-3 ms-2 mt-4">
                            <div class="checkbox icheck-login">
                                <input type="checkbox" id="primary-5" formControlName="payment_type_pre" value="prepaid"
                                       (change)="changeFilterTypePayment($event.target.value, $event.target.checked)"/>
                                <label for="primary-5" class="font--filter">Prepago</label>
                            </div>

                            <div class="checkbox icheck-login">
                                <input type="checkbox" id="primary-6" formControlName="payment_type_post" value="postpaid"
                                       (change)="changeFilterTypePayment($event.target.value, $event.target.checked)"/>
                                <label for="primary-6" class="font--filter">Pospago</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- tarifas -->
        <div [class.col-xl-9]="brandsWithPermissions > 1 || permSv.hasPermFromV2(allPermissions.simyo.permite_vender)" [class.col-xl-12]="brandsWithPermissions <= 1 && !permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
            <div class="row" *ngIf="ratesFiltered.length > 0 && loadBrands == brandsWithPermissions">
                <div class="col-12 ms-3 alert-home text-bold info-notify" style="margin-bottom: 1rem !important;" *ngIf="filterFormGroup.get('type_additional').value">
                    Tarifas disponibles para clientes que tengan contratada o contraten una combinación de una línea móvil y una fibra con cuota reducida
                </div>
                <div [class.col-xl-4]="brandsWithPermissions > 1 || permSv.hasPermFromV2(allPermissions.simyo.permite_vender)" [class.col-xl-3]="brandsWithPermissions <= 1 && !permSv.hasPermFromV2(allPermissions.simyo.permite_vender)" *ngFor="let rate of ratesFiltered">
                    <div class="card--tarifas--digo mb-3" [ngStyle]="{'background-color' : rate.type === types.all ? '#f7f7f7' : ''}" style="
                    height: calc(100% - 16px);
                ">
                        <div class="card-body--digo" style="
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                        ">
                            <div class="w-100">
                                <div *ngIf="rate.type === types.mobile && rate.payment_type === paymentType.prepago" class="rate-prepaid">MÓVIL PREPAGO</div>
                                <div *ngIf="rate.type === types.mobile && rate.payment_type === paymentType.postpago && (rate.wifi_4g == null || !rate.wifi_4g)" class="rate-prepaid">MÓVIL POSPAGO</div>
                                <div *ngIf="rate.type === types.adicional && rate.payment_type === paymentType.postpago" class="rate-prepaid">POSPAGO ADICIONAL</div>
                                <div *ngIf="rate.type === types.mobile && rate.wifi_4g" class="rate-prepaid">WIFI 4G</div>
                                <div *ngIf="rate.type === types.broadband" class="rate-prepaid">FIBRA</div>
                                <div *ngIf="rate.type === types.convergent" class="rate-prepaid">FIBRA Y MÓVIL</div>

                                <img src="./assets/img/{{ rate.brand }}-login-color.svg" class="img-fluid mb-3 logo--tarifa" width="140">
                                <!-- Datos de la tarifa -->

                                <h6 class="fw-bold tarifa--name">{{ rate.name }}</h6>
                                <div class="fw-bold mt-2 llamadas" *ngIf="rate.voice" [innerHTML]="'+ ' + rate.voice"></div>
                                <div class="fw-bold mt-2 llamadas" *ngIf="rate.data" [innerHTML]="'+ ' + rate.data"></div>
                            </div>
                            <div>
                                <hr class="mb-3" style="width: 100%;">
                                <!-- Precio -->
                                <div class="row" *ngIf="rate.type !== types.all">
                                    <div class="col-12 col-xl">
                                        <div class="text-muted fw-bold mb-0" style="text-decoration: line-through; min-height: 24px;" *ngIf="rate.old_price && rate?._original?.is_additional">
                                            {{ rate.old_price !== '' ? (rate.old_price|toFixedDecimals) + '€' : ' ' }}
                                        </div>
                                        <h3 class="fw-bold orange">{{ rate.price|toFixedDecimals }}
                                            <span class="euro">€</span>
                                            <span class="mes">/mes*</span>
                                        </h3>
                                    </div>
                                    <div class="col-12 col-xl-auto" *ngIf="rate.type === types.convergent">
                                        <p class="cuota--l me-2">*cuota de línea<br> incluida</p>
                                    </div>
                                </div>

                            <!-- Button group -->
                            <div align="center" class="btn-group mt-2 mb-3" *ngIf="rate.type !== types.adicional">
                                <button (click)="hire(rate.brand, rate.type, rate)" class="btn btn-black--outline btn-sm ml-1" *ngIf="pdv?.info?.username === 'demo_pdv' && showButtonHire(rate)">Contratar</button>
                                <button (click)="hire(rate.brand, rate.type, rate)" class="btn btn-black--outline btn-sm ml-1" *ngIf="rate.type === types.all">Acceder</button>
                                <button (click)="details(rate)" class="btn btn-black btn-sm ml-3" *ngIf="rate.brand != brands.simyo && rate.brand != brands.orange">Info</button>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
