import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {RepublicaMovilService} from '../../services/republica-movil.service';
import * as moment from 'moment/moment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {faCalendarDay} from '@fortawesome/free-solid-svg-icons';
import { UserNotFoundComponent } from 'src/app/shared/components/user-not-found/user-not-found.component';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DigoService } from 'src/app/services/digo.service';
import { listSortByDate, listType, superStatus, superStatusTranslate } from 'src/app/shared/constantes';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-rm-listado-activaciones',
    templateUrl: './rm-listado-activaciones.component.html',
    styleUrls: ['./rm-listado-activaciones.component.css', '../../../../assets/css/rm-theme.css']
})
export class RmListadoActivacionesComponent implements OnInit, OnDestroy {
    public loaded = false;
    public isCollapsed = true;
    public savingAnnotation = -1;
    public orders = [];
    public ordersFiltered = [];
    public minDate: moment.Moment;
    public maxDate: moment.Moment;
    public faCalendarDay = faCalendarDay;
    public activationThisMonth: number = 0;
    public suspendedThisMonth: number = 0;
    public requestThisMonth: number = 0;
    public alertThisMonth: number = 0;
    public isAlertView = false;
    public body: any = {
        limit: 50,
        page: 1,
        order: {
            created_at: 'desc'
        }
    };
    public pages: number[] = [];
    public lastPage = 0;
    public listType = [{ label: "Todas", id: "" }, ...listType];
    public listSortByDate = listSortByDate;
    private automaticLoad = true;
    public formGroup: FormGroup;
    public currentMonth: string;
    public pdv: any;
    public master: any;
    public superStatus: any = superStatus;
    public superStatusTranslate: any = superStatusTranslate;
    public errorSearch = false;
    private allSubscription: Subscription[] = [];
    public superStatusOptions: any[] = [{ label: "Todas", value: "" }];

    constructor(
        private formBuilder: FormBuilder,
        public rmService: RepublicaMovilService,
        public dialog: MatDialog,
        private modalService: NgbModal,
        private digoService: DigoService
    ) { }

    ngOnInit(): void {
        this.doKeepAlive();
        this.formGroup = this.formBuilder.group({
            full_name: [''],
            document: [''],
            msisdn_movil: [''],
            min_date: [''],
            max_date: [''],
            status: [''],
            contract_type: [''],
            sort_by_date: ['desc']
        });
        this.getOrders();
        this.currentMonth = moment().locale('es').format('MMM');

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: any) => {
                if (data != null) {
                    this.pdv = data;
                }
            })
        );

        this.rmService.getMasterAllFromService()
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.master = data.msg;
                    this.superStatusOptions = [{ label: "Todas", value: "" }, ...this.master.super_statuses];
                    this.getMothlyData();
                }
            });

    }

    ngOnDestroy(): void {
        this.ordersFiltered = [];
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        if (this.automaticLoad) {
            const element: any = event.srcElement;
            const scrollOffset = element.children[0].scrollTop;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight - 200;

            if (scrollOffset > windowHeight) {
                console.log('Cargando siguiente página. A partir de ahora se carga en manual.');
                this.automaticLoad = false;
                this.nextPage();
            }
        }
    }

    getOrders() {
        this.loaded = false;
        this.errorSearch = false;
        this.rmService.getFilteredOrderList(this.body)
            .subscribe((data: any) => {
                this.processData(data);
            }, (error) => {
                this.errorSearch = true;
                this.loaded = true;
                if (error && error.error && error.error.error && error.error.error.errorCode !== null && (error.error.error.errorCode === 462 || error.error.error.errorCode === 1007)) {
                    this.dialog.open(UserNotFoundComponent, {
                        width: '350px',
                        disableClose: true,
                        data: {
                            marca: 'República móvil'
                        }
                    });
                }
            });
    }

    getMothlyData() {
        this.getActivationThisMonth(null);
        this.getRequestThisMonth(null);
        this.getAlertThisMonth(null, 1);
        this.getSuspendedThisMonth(null);
    }

    getActivationThisMonth(body) {
        this.isAlertView = false;
        const filters: any = {};
        const aux: any = {
            limit: 50,
            page: 1,
            order: {
                created_at: 'desc'
            }
        };

        filters.statuses = this.getSuperStatusFromMaster(superStatus.active);
        filters.created_at = 'gte|' + moment().startOf('month').format('YYYY-MM-DD 00:00:00');
        aux.filters = filters;

        if (body) {
            this.orders = [];
            this.ordersFiltered = [];
            this.loaded = false;
            this.body = aux;
        }

        this.rmService.getFilteredOrderList(aux)
            .subscribe((data: any) => {
                if (data) {
                    this.activationThisMonth = data.msg.total;
                    if (body) {
                        this.processData(data);
                    }
                }
            }, fail => {
                console.log(fail);
            });
    }

    getSuspendedThisMonth(body) {
        this.isAlertView = false;
        const filters: any = {};
        const aux: any = {
            limit: 50,
            page: 1,
            order: {
                created_at: 'desc'
            }
        };

        filters.statuses = this.getSuperStatusFromMaster(superStatus.cancelled);
        filters.created_at = 'gte|' + moment().startOf('month').format('YYYY-MM-DD 00:00:00');
        aux.filters = filters;

        if (body) {
            this.orders = [];
            this.ordersFiltered = [];
            this.loaded = false;
            this.body = aux;
        }

        this.rmService.getFilteredOrderList(aux)
            .subscribe((data: any) => {
                if (data) {
                    this.suspendedThisMonth = data.msg.total;
                    if (body) {
                        this.processData(data);
                    }
                }
            }, fail => {
                console.log(fail);
            });
    }

    getRequestThisMonth(body) {
        this.isAlertView = false;
        const filters: any = {};
        const aux: any = {
            limit: 50,
            page: 1,
            order: {
                created_at: 'desc'
            }
        };

        filters.created_at = 'gte|' + moment().startOf('month').format('YYYY-MM-DD 00:00:00');
        aux.filters = filters;

        if (body) {
            this.orders = [];
            this.ordersFiltered = [];
            this.loaded = false;
            this.body = aux;
        }

        this.rmService.getFilteredOrderList(aux)
            .subscribe((data: any) => {
                if (data) {
                    this.requestThisMonth = data.msg.total;
                    if (body) {
                        this.processData(data);
                    }
                }
            }, fail => {
                console.log(fail);
            });
    }

    getAlertThisMonth(body, page) {
        const aux = '?limit=50&page=' + page + '&startDate=' + moment().startOf('month').format('YYYY-MM-DD') +
            '&endDate=' + moment().format('YYYY-MM-DD') + '&daysPending=7';

        if (body) {
            if (page === 1) {
                this.orders = [];
                this.ordersFiltered = [];
            }
            this.loaded = false;
        }

        this.body.limit = 50;
        this.body.page = page;
        this.rmService.getFilterAlerts(aux)
            .subscribe((data: any) => {
                if (data) {
                    this.alertThisMonth = data.msg.total;
                    if (body) {
                        this.processData(data);
                    }
                }
            }, fail => {
                console.log(fail);
            });
    }

    processData(data: any) {
        this.loaded = true;
        this.orders = this.orders.concat(data.msg.data);
        this.ordersFiltered = this.orders;
        this.lastPage = data.msg.last_page;
        this.pages = Array(this.lastPage).fill(1).map((x, i) => i);
    }

    setMinDate(event: moment.Moment) {
        this.minDate = event;
    }

    setMaxDate(event: moment.Moment) {
        this.maxDate = event;
    }

    searchClient(text) {
        this.ordersFiltered = this.orders.filter(item => {
            const fullName: string = item.customer_full_name.toLowerCase();
            const fullNameNormalized = fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            let document: string;

            if (item.customer_document) {
                document = item.customer_document.toLowerCase();
            }

            return document ? fullName.includes(text.toLowerCase()) || fullNameNormalized.includes(text.toLowerCase()) ||
                document.includes(text.toLowerCase()) : fullName.includes(text.toLowerCase()) ||
                fullNameNormalized.includes(text.toLowerCase());
        });
    }

    advanceSearch() {
        this.isAlertView = false;
        const formData = this.formGroup.getRawValue();
        const filters: any = {};

        if (formData.full_name !== '') {
            filters.customer_full_name = formData.full_name;
        }
        if (formData.document !== '') {
            filters.customer_document = formData.document;
        }
        if (formData.msisdn_movil !== '') {
            filters.mobile_msisdn = formData.msisdn_movil;
        }
        if (formData.status) {
            filters.statuses = formData.status;
        }
        if (formData.contract_type) {
            filters.type = formData.contract_type;
        }
        if (formData.sort_by_date) {
            this.body.order.created_at = formData.sort_by_date;
        }
        if (formData.min_date != null && formData.max_date != null && formData.min_date !== '' && formData.max_date !== '') {
            filters.created_at = 'bt|' + moment(formData.min_date).format('YYYY-MM-DD 00:00:00') + '|' + moment(formData.max_date).format('YYYY-MM-DD 23:59:59');
        } else if (formData.min_date != null && formData.min_date !== '') {
            filters.created_at = 'gte|' + moment(formData.min_date).format('YYYY-MM-DD 00:00:00');
        } else if (formData.max_date != null && formData.max_date !== '') {
            filters.created_at = 'lte|' + moment(formData.max_date).format('YYYY-MM-DD 23:59:59');
        }

        this.body.page = 1;
        this.body.filters = filters;
        this.orders = [];
        this.ordersFiltered = [];
        this.automaticLoad = true;

        this.getOrders();
    }

    updateAnnotation(order, annotation) {
        this.savingAnnotation = order.order_id;

        this.rmService.updateAnnotation(order.order_id, annotation)
            .subscribe(data => {
                console.log(data);
                order.annotation = annotation;
                this.savingAnnotation = -1;
            }, error => {
                console.error('ERROR updateAnnotation', error);
                this.savingAnnotation = -1;
            });
    }

    getRatePrice(rate?: any): string {
        if (rate == null) {
            return '0.00';
        }
        let price = rate.rate_amount;
        if (rate.apply_ftth_discount != null && rate.apply_ftth_discount === true && rate.discount != null) {
            console.log(rate.rate_amount, rate.discount);
            price -= rate.discount;
        }
        return (price * 1.21).toFixed(0);
    }

    getConvergentPrice(order): string {
        if (order.broadband && order.broadband.rate && order.mobiles && order.mobiles.length && order.mobiles[0].rate) {
            let price = +order.broadband.rate.rate_amount - +order.broadband.rate.discount + +order.mobiles[0].rate.rate_amount;
            return (price * 1.21).toFixed(0);
        }
    }

    convertStatusClass(status): string {
        switch (status) {
            case superStatus.active:
                return 'dot-complete';
            case superStatus.cancelled:
            case superStatus.error:
            case superStatus.rejected:
                return 'dot-cancel';
            default:
                return 'dot-pending';
        }
    }

    convertDate(data): string {
        return moment(data).format('DD/MM/YYYY, hh:mm a');
    }

    nextPage() {
        if (this.body.page < this.lastPage) {
            this.body.page += 1;
            this.isAlertView ? this.getAlertThisMonth(true, this.body.page) : this.getOrders();
        }
    }

    loadMore() {
        this.nextPage();
    }

    doKeepAlive(){
        this.digoService.keepAlive()
            .subscribe({
                next: (resp: any) => {
                    if (!resp || resp !== 200) {
                        this.digoService.goLogin();
                    }
                }
                , error: (error: HttpErrorResponse) => {
                    this.digoService.goLogin();
                }
            });
    }

    getSuperStatusFromMaster(superStatus): any {
        if (this.master && this.master.super_statuses) {
            const found = this.master.super_statuses.find(super_status => super_status.super_status_id === superStatus);
            if (found) {
                return found.value;
            }
        }
        return null;
    }
}
