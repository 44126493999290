import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimyoService } from '../../services/simyo.service';
import { environment } from 'src/environments/environment';
import { onChangeZip, searchRoadType } from 'src/app/utils/normalizerFunctions';
import { SimyoUserDataService } from '../../services/simyo-user-data.service';
import { SimyoGlobals } from '../../classes/simyo-globals';
import { of, Subscription } from 'rxjs';
import { masterAllSimyo, provinceSimyo, trackTypeSimyo } from '../../models/masterAllSimyo';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { PriceDelivery } from '../../classes/priceDelivery';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MyaddressService } from 'src/app/services/myaddress.service';

@Component({
    selector: 'app-simyo-terminal-delivery',
    templateUrl: './simyo-terminal-delivery.component.html',
    styleUrls: ['./simyo-terminal-delivery.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoTerminalDeliveryComponent implements OnInit, OnDestroy {

    @Input() formGroup: FormGroup;
    public order
    public confirmedAddress: string;
    public type;
    public deliveryPrice;
    public anyRegistration: boolean;
    public country = environment.googleMapsCountry;
    public errorForm: boolean = false;
    public otherDeliveryAddressValid = false;
    public isMyAddressDisabledByUser: boolean = false;

    // Master
    public provinces: provinceSimyo[] = [];
    public roadTypes: trackTypeSimyo[] = [];

    private allSubscription: Subscription[] = [];
    public enviroment = environment;

    constructor(
        private modalService: NgbModal,
        private orderService: SimyoOrderService,
        private simyoService: SimyoService,
        private userDataService: SimyoUserDataService,
        public simyoGlobals: SimyoGlobals,
        private myAddressService: MyaddressService
    ) { }

    ngOnInit(): void {
        this.type = 3;
        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((data: any) => {
                if (data) {
                    this.order = data;
                    if (this.order.mobiles && this.order.mobiles.length) {
                        this.typeOperationMobile(this.order.mobiles);
                    }
                }
            })
        );  

        this.allSubscription.push(
            this.simyoService.getProvincesCommunFromService().pipe(
                map((data:any) => {
                    if(data && data.msg) {
                        this.simyoService.setProvincesCommun(data.msg);
                        return data.msg;
                    }
                    return null;
                }),
                tap((data: any) => {
                    if (data) {
                        this.provinces = data;
                        this.onChangeZip(this.formGroup.get('codigoPostalNormaliza').value);
                    }
                })
            ).subscribe()
        );

        this.allSubscription.push(
            this.simyoService.getRoadTypesCommun().pipe(
                switchMap((data:any) => {
                    if(data != null) {
                        return of(data);
                    }
                    return this.simyoService.getRoadTypeCommunFromService().pipe(
                        catchError(error => {
                            return of(null);
                        }),
                        map((data:any) => {
                            if(data && data.msg) {
                                this.simyoService.setRoadTypesCommun(data.msg);
                                return of(data.msg);
                            }
                            return of(null);
                        })
                    )
                }),
                tap((data: any) => {
                    if (data) {
                        this.roadTypes = data;
                    }
                })
            ).subscribe());
        this.allSubscription.push(this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data) {
                    if (this.formGroup.get('address').value && this.formGroup.get('address').value !== '') {
                        this.confirmedAddress = this.formGroup.get('address').value + ' ' +
                            this.formGroup.get('number').value + ', ' +
                            this.formGroup.get('city').value + ', ' +
                            this.simyoGlobals.getProvinceLabel(this.provinces, this.formGroup.get('province').value);
                    }
                }
            })
        );

        this.allSubscription.push(this.userDataService.getUserData()
            .subscribe((data: any) => {
                if (data != null && data.is_custom_delivery !== '') {
                    this.type = this.formGroup.get('address').value && this.formGroup.get('address').value !== '' ? 2 : 3;
                    this.confirmDelivery();
                }
            })
        );

        this.allSubscription.push(this.formGroup.valueChanges.subscribe(value => {
                if(value.address == "null"){
                    this.formGroup.patchValue({
                        address: this.sanitizeValue(this.formGroup.get('address')?.value),
                        floor: this.sanitizeValue(this.formGroup.get('floor')?.value),
                        city: this.sanitizeValue(this.formGroup.get('city')?.value),
                        province: this.sanitizeValue(this.formGroup.get('province')?.value),
                      });
                }
                this.otherDeliveryAddressValid = value.address && value.number && value.zip && value.city && value.province;
            })
          );
    }

    //Limpia valores que el componente myaddress setea valores a 'null' en los campos al no encontrar una direccion
    sanitizeValue(value: any): string {
        return value === null || value === 'null' ? '' : value;
    }

    changeDeliveryPrice(type) {
        this.type = parseInt(type, null);
        this.formGroup.get('office').clearValidators();
        this.formGroup.get('full_name').clearValidators();
        this.formGroup.get('delivery_phone').clearValidators();
        this.formGroup.get('road_type').clearValidators();
        this.formGroup.get('address').clearValidators();
        this.formGroup.get('number').clearValidators();
        this.formGroup.get('zip').clearValidators();
        this.formGroup.get('city').clearValidators();
        this.formGroup.get('province').clearValidators();
        this.formGroup.get('floor').clearValidators();
        this.formGroup.get('stairway').clearValidators();

        switch (this.type) {
            case 0:
                this.deliveryPrice = 0;
                break;
            case 1:
                break;
            case 2:
                this.deliveryPrice = this.anyRegistration ? PriceDelivery.PRICE_ALTA_DIR_CONTACTO : PriceDelivery.PRICE_PORTA_DIR_CONTACTO;
                // this.formGroup.get('full_name').setValidators(Validators.required);
                // this.formGroup.get('delivery_phone').setValidators(Validators.required);
                // this.formGroup.get('road_type').setValidators(Validators.required);
                this.formGroup.get('address').setValidators([Validators.required, blankSpaceValidator()]);
                this.formGroup.get('number').setValidators([Validators.required, blankSpaceValidator()]);
                this.formGroup.get('zip').setValidators([Validators.required, blankSpaceValidator()]);
                this.formGroup.get('city').setValidators([Validators.required, blankSpaceValidator()]);
                this.formGroup.get('province').setValidators([Validators.required]);
                // this.formGroup.get('stairway').setValidators(autocompleteValidator(this.staircaseTypes.map(i => i.code)));
                // this.formGroup.get('floor').setValidators(autocompleteValidator(this.floorTypes.map(i => i.code)));
                break;
            case 3:
              this.deliveryPrice = this.anyRegistration ? PriceDelivery.PRICE_ALTA_DIR_CONTACTO : PriceDelivery.PRICE_PORTA_DIR_CONTACTO;
              break;
            default:
                this.deliveryPrice = this.anyRegistration ? PriceDelivery.PRICE_ALTA_DIR_CONTACTO : PriceDelivery.PRICE_PORTA_DIR_CONTACTO;
                break;
        }

        this.formGroup.get('full_name').updateValueAndValidity();
        this.formGroup.get('delivery_phone').updateValueAndValidity();
        this.formGroup.get('office').updateValueAndValidity();
        this.formGroup.get('road_type').updateValueAndValidity();
        this.formGroup.get('address').updateValueAndValidity();
        this.formGroup.get('number').updateValueAndValidity();
        this.formGroup.get('zip').updateValueAndValidity();
        this.formGroup.get('city').updateValueAndValidity();
        this.formGroup.get('province').updateValueAndValidity();
        this.formGroup.get('stairway').updateValueAndValidity();
        this.formGroup.get('floor').updateValueAndValidity();
    }

    confirmDelivery() {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.evaluateDeliveryForm()) {
            if (this.type === 2) {
                this.confirmedAddress = this.formGroup.get('address').value + ' ' +
                    this.formGroup.get('number').value + ', ' +
                    this.formGroup.get('city').value + ', ' +
                    this.simyoGlobals.getProvinceLabel(this.provinces, this.formGroup.get('province').value);
            } else {
                this.confirmedAddress = 'Enviar a la dirección de contacto';
            }
            this.formGroup.get('is_custom_delivery').patchValue(true);
            this.errorForm = false;
            this.modalService.dismissAll();
        } else {
            const elements: any = document.getElementById('bodyModal').getElementsByClassName('mat-input-element ng-invalid');

            if (elements.length > 0) {
                elements[0].focus();
            }
        }
    }

    onAutocompleteSelected($event) {
        const addressComponents: any[] = $event.address_components;

        if (addressComponents && addressComponents.length > 0) {
            this.formGroup.get('road_type').patchValue('');
            this.formGroup.get('address').patchValue('');
            this.formGroup.get('number').patchValue('');
            this.formGroup.get('floor').patchValue('');
            this.formGroup.get('door').patchValue('');
            this.formGroup.get('stairway').patchValue('');
            this.formGroup.get('zip').patchValue('');
            this.formGroup.get('city').patchValue('');
            this.formGroup.get('province').patchValue('');

            for (const addressComponent of addressComponents) {
                let field = '';
                const types: string[] = addressComponent.types;
                let value: string = addressComponent.long_name;

                if (types.includes('route')) {
                    field = 'address';
                    value = searchRoadType(value, this.roadTypes, 'value', this.formGroup.get('road_type'));
                } else if (types.includes('street_number')) {
                    field = 'number';
                } else if (types.includes('postal_code')) {
                    field = 'zip';
                    this.onChangeZip(value);
                } else if (types.includes('locality')) {
                    field = 'city';
                } else if (types.includes('administrative_area_level_2')) {
                    field = 'province';
                    value = value.toUpperCase();
                }

                if (field !== '') {
                    this.formGroup.get(field).patchValue(value);
                }
            }
        }
    }

    onChangeZip(value) {
        if (value && +value > 99999) {
            value = value.substring(0,5);
            this.formGroup.get('zip').patchValue(value);
        }
        onChangeZip(value, this.provinces, 'postal_code', this.formGroup.get('province'));
    }

    open(content) {
        this.otherDeliveryAddressValid = false;
        this.modalService.open(content, {size: 'lg', centered: true});
    }

    evaluateDeliveryForm(): boolean {
        if (this.type === 2 && !this.formGroup.get('road_type').value || this.formGroup.get('road_type').value === '') {
            searchRoadType(this.formGroup.get('address').value, this.roadTypes, 'value', this.formGroup.get('road_type'));
        }
        return this.formGroup.get('address').valid &&
            this.formGroup.get('number').valid &&
            this.formGroup.get('zip').valid &&
            this.formGroup.get('city').valid &&
            this.formGroup.get('province').valid;
    }

    typeOperationMobile(order) {
        const found = order.find(mobil => mobil.msisdn === '');
        if (found) { // Alguna alta
            this.anyRegistration = true;
            this.deliveryPrice = PriceDelivery.PRICE_ALTA_DIR_CONTACTO;
        } else { // Todos portabilidad
            this.anyRegistration = false;
            this.deliveryPrice = PriceDelivery.PRICE_PORTA_DIR_CONTACTO;
        }
    }

    myAddressSelected(myaddress){
        console.log(myaddress)

        if (!myaddress?.detail?.ospGeographicAddress?.length) {
            return;
        }
        this.formGroup.patchValue({
            address: myaddress?.detail?.ospGeographicAddress[0]?.streetName,
            number: myaddress?.detail?.ospGeographicAddress[0]?.streetNr,
            floor: myaddress?.detail?.ospGeographicAddress[0]?.geographicSubAddress[0]?.levelNumber,
            door: myaddress?.detail?.ospGeographicAddress[0]?.geographicSubAddress[0]?.door,
            stairway: myaddress?.detail?.ospGeographicAddress[0]?.geographicSubAddress[0]?.stairCase,
            zip: myaddress?.detail?.ospGeographicAddress[0]?.postCode,
            city: myaddress?.detail?.ospGeographicAddress[0]?.locality ?? myaddress?.detail?.ospGeographicAddress[0]?.city,
            province: myaddress?.detail?.ospGeographicAddress[0]?.stateOrProvince,
        })

        const { address, number, zip, city, province } = this.formGroup.getRawValue();
        this.otherDeliveryAddressValid = address && number && zip && city && province;
    }

    public resetForm(): void {
        if (this.otherDeliveryAddressValid) {
            this.formGroup.patchValue({
                address: '',
                number: '',
                floor: '',
                door: '',
                stairway: '',
                zip: '',
                city: '',
                province: '',
            });
            this.otherDeliveryAddressValid = false;
        }
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    public disableMyAddressComponent(){
        this.myAddressService.sendDataToLogsMyAddress('log: El usuario cambia el componente - Formulario de entrega de terminal');
        this.isMyAddressDisabledByUser = !this.isMyAddressDisabledByUser;
    }
}
