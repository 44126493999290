<div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
        <div class="modal-body">
            <div class="mb-4">
                <p [innerHTML]="message" [class]="className"></p>
            </div>

            <div class="row">
                <div class="col-sm-6 offset-3">
                    <button class="btn d-block w-100 btn-orange text-white" (click)="close()">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
</div>
