import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ChangeDetectionStrategy, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../baseComponent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'digo-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush // Mejora de rendimiento
})
export class CustomInputComponent extends BaseComponent implements OnChanges, OnInit {

    @Input() type: TypeInput = TypeInput.text;
    @Input() maxlength: string;
    @Input() minlength: string;
    @Input() customeMessageError: string;
    @Input() placeholder: string = '';
    @Input() min: string;
    @Input() max: string;
    @Input() pattern: string;
    @Input() autocomplete: string = 'on';
    @Input() activeBlockPaste = false;
    @Input() isSearch: boolean = false;
    @Input() floatLabel: boolean = true;
    @Input() mask: string;


    @Output() OnKeyPress = new EventEmitter();
    @Output() OnInput = new EventEmitter();
    @Output() OnKeyDown = new EventEmitter();
    @Output() OnFocusIn = new EventEmitter();
    @Output() OnFocusOut = new EventEmitter();
    @Output() OnAutocompleteSelected = new EventEmitter();
    @Output() OnKeyUpEnter = new EventEmitter();
    @Output() OnClickIcon = new EventEmitter();
    @Output() clicked = new EventEmitter<void>();

    TypeInput = TypeInput;
    country: string = environment.googleMapsCountry;

    private valueChangesSubscription: Subscription;

    constructor(public formBuilder: FormBuilder) {
        super(formBuilder);
    }

    ngOnInit(): void {
        if (this.isDisabled && this.control) {
            this.control.disable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.isDisabled) {
            if (changes.isDisabled.currentValue) {
                this.control?.disable();
            } else {
                this.control?.enable();
            }
        }

        if (this.formGroup && this.controlName.toString()) {
            this.control = this.getControl(this.controlName);
            this.control = this.controlName == '0' ? (this.formGroup.controls[this.controlName] as FormControl) : this.control;
            if (this.valueChangesSubscription) {
                this.valueChangesSubscription.unsubscribe();
            }

            this.control?.valueChanges.subscribe((data) => {
                this.isEmpty = data === '';
            });
        }

        if (changes.value) {
            this.control.setValue(changes.value.currentValue);
        }
    }

    ngOnDestroy(): void {
        /*if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }*/
    }

    onClick() {
        this.clicked.emit();
    }
}

export enum TypeInput {
    text = 'text',
    number = 'number',
    maps = 'maps'
};
