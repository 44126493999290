import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SimyoRoutingModule } from './simyo-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SimyoComponent } from './components/simyo/simyo.component';
import { SimyoIndexComponent } from './components/simyo-index/simyo-index.component';
import { SimyoShoppingCartComponent } from './components/simyo-shopping-cart/simyo-shopping-cart.component';
import { SimyoSubheaderComponent } from './components/simyo-subheader/simyo-subheader.component';
import { SimyoModalSaveComponent } from './components/simyo-modal-save/simyo-modal-save.component';
import { SimyoListadoActivacionesComponent } from './components/simyo-listado-activaciones/simyo-listado-activaciones.component';
import { SimyoOrderDetailComponent } from './components/simyo-order-detail/simyo-order-detail.component';
import { SimyoNormalizaDireccionComponent } from './components/simyo-normaliza-direccion/simyo-normaliza-direccion.component';
import { SimyoCoberturaComponent } from './components/simyo-cobertura/simyo-cobertura.component';
import { SimyoRatesComponent } from './components/simyo-rates/simyo-rates.component';
import { SimyoMovilPackComponent } from './components/simyo-movil-pack/simyo-movil-pack.component';
import { SimyoUserDataComponent } from './components/simyo-user-data/simyo-user-data.component';
import { SimyoConfirmationComponent } from './components/simyo-confirmation/simyo-confirmation.component';
import { SimyoFinishedComponent } from './components/simyo-finished/simyo-finished.component';
import { SimyoCustomerComponent } from './components/simyo-customer/simyo-customer.component';
import { SimyoUserBankingDataComponent } from './components/simyo-user-banking-data/simyo-user-banking-data.component';
import { SimyoTerminalComponent } from './components/simyo-terminal/simyo-terminal.component';
import { SimyoTerminalDeliveryComponent } from './components/simyo-terminal-delivery/simyo-terminal-delivery.component';
import { SimyoLoaderComponent } from './components/simyo-loader/simyo-loader.component';
import { SimyoRatesListComponent } from './components/simyo-rates-list/simyo-rates-list.component';
import { SimyoModalReprocessComponent } from './components/simyo-modal-reprocess/simyo-modal-reprocess.component';
import { SimyoModalAddressComponent } from './components/simyo-modal-address/simyo-modal-address.component';
import { SimyoDuplicadosSimComponent } from './components/simyo-duplicados-sim/simyo-duplicados-sim.component';
import { SimyoDsConfigurationComponent } from './components/simyo-duplicados-sim/simyo-ds-configuration/simyo-ds-configuration.component';
import { SimyoDsConfirmationComponent } from './components/simyo-duplicados-sim/simyo-ds-confirmation/simyo-ds-confirmation.component';
import { SimyoDsListadoComponent } from './components/simyo-duplicados-sim/simyo-ds-listado/simyo-ds-listado.component';
import { SimyoDsDetailComponent } from './components/simyo-duplicados-sim/simyo-ds-detail/simyo-ds-detail.component';
import { SimyoDocumentationSignatureComponent } from './components/simyo-documentation-signature/simyo-documentation-signature.component';
import { SimyoAgreementsComponent } from './components/simyo-agreements/simyo-agreements.component';
import { SimyoTemporaryClosureComponent } from './components/simyo-temporary-closure/simyo-temporary-closure.component';
import { SimyoDsDocumentationSignatureComponent } from './components/simyo-duplicados-sim/simyo-ds-documentation-signature/simyo-ds-documentation-signature.component';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { SearchPdvComponent } from './modals/search-pdv/search-pdv.component';
import { SimyoOrderDetailBetaComponent } from './components/simyo-order-detail-beta/simyo-order-detail-beta.component';
import { SimyoSummaryClientComponent } from './components/simyo-summary-client/simyo-summary-client.component';
import { SimyoSummaryProcessingComponent } from './components/simyo-summary-processing/simyo-summary-processing.component';
import { SimyoAppointmentComponent } from './components/simyo-appointment/simyo-appointment.component';



@NgModule({
    declarations: [
        SimyoComponent,
        SimyoIndexComponent,
        SimyoShoppingCartComponent,
        SimyoSubheaderComponent,
        SimyoModalSaveComponent,
        SimyoListadoActivacionesComponent,
        SimyoOrderDetailComponent,
        SimyoNormalizaDireccionComponent,
        SimyoCoberturaComponent,
        SimyoRatesComponent,
        SimyoMovilPackComponent,
        SimyoUserDataComponent,
        SimyoConfirmationComponent,
        SimyoFinishedComponent,
        SimyoCustomerComponent,
        SimyoUserBankingDataComponent,
        SimyoTerminalComponent,
        SimyoTerminalDeliveryComponent,
        SimyoLoaderComponent,
        SimyoRatesListComponent,
        SimyoModalReprocessComponent,
        SimyoModalAddressComponent,
        SimyoDuplicadosSimComponent,
        SimyoDsConfigurationComponent,
        SimyoDsConfirmationComponent,
        SimyoDsListadoComponent,
        SimyoDsDetailComponent,
        SimyoDocumentationSignatureComponent,
        SimyoAgreementsComponent,
        SimyoTemporaryClosureComponent,
        SimyoDsDocumentationSignatureComponent,
        ConfirmComponent,
        SearchPdvComponent,
        SimyoOrderDetailBetaComponent,
        SimyoSummaryClientComponent,
        SimyoSummaryProcessingComponent,
        SimyoAppointmentComponent
    ],
    exports: [
        SimyoShoppingCartComponent,
        SimyoSubheaderComponent,
        SimyoTemporaryClosureComponent
    ],
    imports: [
        CommonModule,
        SimyoRoutingModule,
        SharedModule
    ]
})
export class SimyoModule { }
