import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DigoService } from 'src/app/services/digo.service';
import { listTypeOperation, superStatus, orderType, superStatusTranslate, typeFilterFormOperation, tabHome, simyoDocStatus, fiberIndirectTechnologies } from 'src/app/shared/constantes';
import * as moment from 'moment/moment';
import { HomeService, INavigateFromOperation, ITypeOperationForms } from '../../services/home.service';
import { environment } from 'src/environments/environment';
import { Router} from '@angular/router';
import { CustomHeader } from 'src/app/shared/components/datepicker-custom-header/datepicker-custom-header.component';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { SimyoOrderService } from 'src/app/simyo/services/simyo-order.service';
import { Ipdv } from 'src/app/shared/models/pdvResponse';

@Component({
    selector: 'app-hm-operations',
    templateUrl: './hm-operations.component.html',
    styleUrls: ['./hm-operations.component.css', '../../../../assets/css/home-theme.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HmOperationsComponent implements OnInit, OnDestroy {
    @Input() initOperation: Observable<any>;
    customHeader = CustomHeader;
    public dataSource = [];
    public pdv: Ipdv;
    public orderListPdv: any;
    public body: any = {
        limit: 20,
        page: 1,
        brands: [],
        filters: {}
    };
    public saveDataOperation: INavigateFromOperation;
    public loaded: boolean = false;
    public orderListPdvError: boolean = false;
    public filterBrandFormGroup: FormGroup;
    public customFilterFormGroup: FormGroup;
    public listTypeOperation = [];
    public superStatusTranslate = superStatusTranslate;
    public superStatus = superStatus;
    public displayedColumns: string[] = ['name', 'type', 'msisdn','status', 'doc_status', 'date', 'activation_date', 'brand'];
    public automaticLoad = true;
    public lastPage = 0;
    public allPermissions = allPermissions;
    public downloading: boolean = false;
    public downloadErrorMsg: any = null;
    private changeTabSubscription: Subscription;
    private allSubscription: Subscription[] = [];
    public dateControl = new FormControl(moment());
    public today = new Date();
    public showSimyoMsg: boolean = false;
    public requestSubscribe;

    public brands = {
        republica_movil: 'repmovil',
        simyo: 'simyo',
        orange: 'orange',
        jazztel: 'jazztel',
        guuk: 'guuk',
        masmovil: 'masmovil',
        pepephone: 'pepephone'
    };

    public typesLines = {
        broadband_new: 'Alta fibra',
        broadband_portability: 'Portabilidad fibra',
        broadband_renove: 'Renove fibra',
        mobile_new: 'Alta móvil',
        mobile_portability: 'Portabilidad móvil',
        mobile_renove: 'Renove',
        service: 'Servicio'
    };

    constructor(
        private digoService: DigoService,
        private formBuilder: FormBuilder,
        private homeService: HomeService,
        public router: Router,
        public permSv: PermissionService,
        private simyoOrderService: SimyoOrderService
    ) { }

    ngOnInit(): void {
        this.filterBrandFormGroup = this.formBuilder.group({
            simyo: [true],
            orange: [true],
            jazztel: [true],
            guuk: [true],
            pepephone: [true],
            masmovil: [true],
        });

        this.customFilterFormGroup = this.formBuilder.group({
            contract_type: [''],
            custom_search: [''],
            statuses: new FormControl({value: false, disabled: false}),
            date: new FormControl({value: false, disabled: false}),
            allBrands: new FormControl({value: true, disabled: false}),
            activationDate: new FormControl({value: false, disabled: false})
        });

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: INavigateFromOperation) => {
                this.saveDataOperation = data;
                if (this.saveDataOperation) {
                    this.orderListPdv = data;
                    this.body = this.saveDataOperation.body;
                    this.filterBrandFormGroup.patchValue(this.saveDataOperation.forms.filterBrandForm);
                    this.customFilterFormGroup.patchValue(this.saveDataOperation.forms.customFilterForm);
                    this.lastPage = this.saveDataOperation.lastPage;
                    this.dataSource = this.saveDataOperation.data;
                }
            })
        );

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data && this.pdv == null) {
                    this.pdv = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getTypeOperationForms()
            .subscribe((data: ITypeOperationForms) => {
                if (data) {
                    this.cancelRequest();
                    this.body.page = 1;
                    this.automaticLoad = true;
                    this.orderListPdv = null;
                    this.dataSource = [];
                    if (data.filtersBrands) {
                        this.filterBrandFormGroup.get('simyo').patchValue(data.filtersBrands.simyo);
                        this.filterBrandFormGroup.get('orange').patchValue(data.filtersBrands.orange);
                        this.filterBrandFormGroup.get('jazztel').patchValue(data.filtersBrands.jazztel);
                        this.filterBrandFormGroup.get('masmovil').patchValue(data.filtersBrands.masmovil);
                        this.filterBrandFormGroup.get('guuk').patchValue(data.filtersBrands.guuk);
                        this.filterBrandFormGroup.get('pepephone').patchValue(data.filtersBrands.pepephone);
                        this.customFilterFormGroup.get('allBrands').patchValue(data.filtersBrands.orange
                            && data.filtersBrands.repmovil
                            && data.filtersBrands.simyo
                            && data.filtersBrands.jazztel
                            && data.filtersBrands.masmovil
                            && data.filtersBrands.guuk
                            && data.filtersBrands.pepephone ? true : false);
                    } else {
                        this.filterBrandFormGroup.get('simyo').patchValue(true);
                        this.filterBrandFormGroup.get('orange').patchValue(true);
                        this.filterBrandFormGroup.get('jazztel').patchValue(true);
                        this.filterBrandFormGroup.get('masmovil').patchValue(true);
                        this.filterBrandFormGroup.get('guuk').patchValue(true);
                        this.filterBrandFormGroup.get('pepephone').patchValue(true);
                        this.customFilterFormGroup.get('allBrands').patchValue(true);
                    }
                    this.customFilterFormGroup.get('custom_search').patchValue('');
                    this.customFilterFormGroup.get('date').patchValue(false);
                    this.customFilterFormGroup.get('statuses').patchValue(true);
                    this.customFilterFormGroup.get('activationDate').patchValue(true);
                    switch (data.type) {
                        case typeFilterFormOperation.totalActivationsMonth:
                            this.customFilterFormGroup.get('contract_type').patchValue('');
                            break;
                        case typeFilterFormOperation.totalActivationsFibra:
                            this.customFilterFormGroup.get('contract_type').patchValue('broadband');
                            break;
                        case typeFilterFormOperation.totalActivationsPostpago:
                            this.customFilterFormGroup.get('contract_type').patchValue('postpaid');
                            break;
                        case typeFilterFormOperation.totalActivationsPrepago:
                            this.customFilterFormGroup.get('contract_type').patchValue('prepaid');
                            break;
                    }
                    this.getOrderListPdv(this.homeService.getBrandPermission(this.brands));
                    this.homeService.setTypeOperationForms(null);
                }
            })
        );

        this.changeTabSubscription = this.initOperation
            .subscribe((data) => {
                if (data?.tab?.textLabel === tabHome.OPERACIONES) {
                    this.cancelRequest();
                    this.filterBrandFormGroup.patchValue({repmovil: true, simyo: true, orange: true, jazztel: true, masmovil: true, guuk: true, pepephone: true});
                    this.customFilterFormGroup.patchValue({contract_type: '', custom_search: '', statuses: false, date: false, allBrands: true, activationDate: false});
                    this.body.filters = {};
                    this.changeFilter();
                }
            });

        this.getSimyoMsg();

        this.listTypeOperation = [{ id: '', label: 'Todas' }, ...listTypeOperation].filter(op => {
            return op.id !== 'broadband' || (op.id === 'broadband' && this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband))
        })
    }

    private getOrderListPdv(marcas) {
        if (this.saveDataOperation) {
            this.saveDataOperation = null;
        }
        this.orderListPdvError = false;
        this.body.brands = this.filterIsActive(marcas, this.filterBrandFormGroup.getRawValue());
        this.body.filters.type = this.customFilterFormGroup.get('contract_type').value === '' ? undefined : this.customFilterFormGroup.get('contract_type').value;
        this.body.filters.date = this.customFilterFormGroup.get('date').value ? 'bt|' + moment().clone().startOf('month').format('YYYY-MM-DD') + '|' + moment().format('YYYY-MM-DD') : undefined;
        this.body.filters.activation_date = this.customFilterFormGroup.get('activationDate').value ? 'bt|' + moment().clone().startOf('month').format('YYYY-MM-DD') + '|' + moment().format('YYYY-MM-DD') : undefined;
        this.body.filters.statuses = this.customFilterFormGroup.get('statuses').value ? [superStatus.active] : undefined;
        this.loaded = true;
        this.changeStateSliderToDisabled(true);
        if (this.requestSubscribe) {
            this.cancelRequest();
        }
        this.requestSubscribe = this.homeService.getOrderListPdvFromService(this.body)
            .subscribe((data: any) => {
                if (data) {
                    this.orderListPdv = data;
                    this.lastPage = data.last_page;
                    this.dataSource = this.dataSource.concat(data.data);
                }
                this.loaded = false;
                this.changeStateSliderToDisabled(false);
            }, () => {
                this.orderListPdvError = true;
                this.loaded = false;
                this.changeStateSliderToDisabled(false);
            });
    }

    private cancelRequest() {
        if (this.requestSubscribe) {
            this.requestSubscribe.unsubscribe();
        }
    }

    private changeStateSliderToDisabled(value) {
        if (value) {
            this.customFilterFormGroup.get('date').disable();
            this.customFilterFormGroup.get('activationDate').disable();
            this.customFilterFormGroup.get('statuses').disable();
            this.customFilterFormGroup.get('allBrands').disable();
        } else {
            this.customFilterFormGroup.get('date').enable();
            this.customFilterFormGroup.get('activationDate').enable();
            this.customFilterFormGroup.get('statuses').enable();
            this.customFilterFormGroup.get('allBrands').enable();
        }
    }

    public allBrands() {
        if (this.customFilterFormGroup.get('allBrands').value && this.homeService.getBrandPermission(this.brands).length !== this.filterIsActive(this.homeService.getBrandPermission(this.brands), this.filterBrandFormGroup.getRawValue()).length) {
            this.filterBrandFormGroup.get('simyo').patchValue(true);
            this.filterBrandFormGroup.get('orange').patchValue(true);
            this.filterBrandFormGroup.get('jazztel').patchValue(true);
            this.filterBrandFormGroup.get('masmovil').patchValue(true);
            this.filterBrandFormGroup.get('guuk').patchValue(true);
            this.filterBrandFormGroup.get('pepephone').patchValue(true);
            this.changeFilter();
        } else {
            this.customFilterFormGroup.get('allBrands').patchValue(true);
        }
    }

    public changeFilterBrand(brand, value) {
        if (this.homeService.getBrandPermission(this.brands).length === this.filterIsActive(this.homeService.getBrandPermission(this.brands), this.filterBrandFormGroup.getRawValue()).length && value) {
            this.customFilterFormGroup.get('allBrands').patchValue(true);
        } else {
            this.customFilterFormGroup.get('allBrands').patchValue(false);
        }
        if (this.homeService.getBrandPermission(this.brands).length === this.body.brands.length && !value) {
            this.filterBrandFormGroup.get(this.brands.simyo).patchValue(brand === this.brands.simyo);
            this.filterBrandFormGroup.get(this.brands.jazztel).patchValue(brand === this.brands.jazztel);
            this.filterBrandFormGroup.get(this.brands.orange).patchValue(brand === this.brands.orange);
            this.filterBrandFormGroup.get(this.brands.masmovil).patchValue(brand === this.brands.masmovil);
            this.filterBrandFormGroup.get(this.brands.pepephone).patchValue(brand === this.brands.pepephone);
            this.filterBrandFormGroup.get(this.brands.guuk).patchValue(brand === this.brands.guuk);
            this.changeFilter();
        } else if (Object.keys(this.filterBrandFormGroup.getRawValue()).every((k) => !this.filterBrandFormGroup.getRawValue()[k])) {
            this.filterBrandFormGroup.get(brand).patchValue(true);
            this.customFilterFormGroup.get('allBrands').patchValue(false);
        } else {
            this.changeFilter();
        }
    }

    public changeFilter() {
        if (this.customFilterFormGroup.get('activationDate').value && (this.customFilterFormGroup.get('date').value || !this.customFilterFormGroup.get('statuses').value)) {
            this.customFilterFormGroup.get('activationDate').patchValue(false);
        }
        this.body.page = 1;
        this.automaticLoad = true;
        this.orderListPdv = null;
        this.dataSource = [];
        this.getOrderListPdv(this.homeService.getBrandPermission(this.brands));
    }

    public changeFilterActivation(value) {
        if (value) {
            this.customFilterFormGroup.get('statuses').patchValue(true);
            this.customFilterFormGroup.get('date').patchValue(false);
        }
    }

    public goDetailOrder(order) {
        switch (order.brand) {
            case this.brands.simyo:
                this.homeService.setNavigateFromOperation(this.getInfoOperation());
                this.homeService.setNavigateFromOperationPosVenta(null);
                this.router.navigate(['/simyo/order/' + order.order_id.toString()]);
                break;
            case this.brands.orange:
                this.homeService.setNavigateFromOperation(this.getInfoOperation());
                this.homeService.setNavigateFromOperationPosVenta(null);
                this.router.navigate(['/orange/prepago-order/' + order.order_id.toString()]);

                /*if (order.is_esim) {
                    break;
                }
                if (order.mobiles_contract_type && order.mobiles_contract_type.length) {
                    if (order.mobiles_contract_type[0] === mobileContractType.prepaid) {
                        window.open(environment.digoEndPoint + 'recogida_pdv.itx?avanzado_nif=' + order.document_number + (order.msisdn && order.msisdn !== '' ? '&avanzado_msisdn=' + order.msisdn : ''));
                    }
                    if (order.mobiles_contract_type[0] === mobileContractType.postpaid) {
                        window.open(environment.digoEndPoint + 'pos_recogida_pdv.itx?avanzado_nif=' + order.document_number + (order.msisdn && order.msisdn !== '' ? '&avanzado_msisdn=' + order.msisdn : ''));
                    }
                }*/
                break;
            case this.brands.jazztel:
                window.open(environment.digoEndPoint + 'jazztel_list_pdv.itx');
                break;
            case this.brands.guuk:
                this.homeService.setNavigateFromOperation(this.getInfoOperation());
                this.homeService.setNavigateFromOperationPosVenta(null);
                this.router.navigate(['/guuk/order/' + order.order_id.toString()]);
                break;
            case this.brands.masmovil:
                this.homeService.setNavigateFromOperation(this.getInfoOperation());
                this.homeService.setNavigateFromOperationPosVenta(null);
                this.router.navigate(['/masmovil/order/' + order.order_id.toString()]);
                break;
            case this.brands.pepephone:
                this.homeService.setNavigateFromOperation(this.getInfoOperation());
                this.homeService.setNavigateFromOperationPosVenta(null);
                this.router.navigate(['/pepephone/order/' + order.order_id.toString()]);
                break;
            default:
                return '';
        }
    }

    public search() {
        if (!this.loaded) {
            this.body.page = 1;
            this.body.filters.custom_search = this.customFilterFormGroup.get('custom_search').value;
            this.automaticLoad = true;
            this.orderListPdv = null;
            this.dataSource = [];
            this.getOrderListPdv(this.homeService.getBrandPermission(this.brands));
        }
    }

    public getImgBrand(brand) : string {
        switch (brand) {
            case this.brands.simyo:
                return 'sm-login-color.svg';
            case this.brands.orange:
                return 'or-login-color.svg';
            case this.brands.jazztel:
                return 'jz-login-color.svg';
            case this.brands.masmovil:
                return 'mm-login-color.svg';
            case this.brands.pepephone:
                return 'pp-login-color.svg';
            case this.brands.guuk:
                return 'gk-login-color.svg';
            default:
                return '';
        }
    }

    public getStatusIcon(line) : string {
        if (line.status_description === 'Activada pendiente 1ª llamada') {
            return 'check'
        }
        switch (line.status) {
            case superStatus.cancelled:
            case superStatus.error:
            case superStatus.rejected:
            case superStatus.unsubscribe:
            case superStatus.block:
                return 'x';
            case superStatus.draft:
                return 'file-text';
            case superStatus.in_process:
                return 'clock';
            default:
                return '';
        }
    }

    public getColorIcon(status) {
        switch (status) {
            case superStatus.active:
                return 'green';
            case superStatus.cancelled:
            case superStatus.error:
            case superStatus.rejected:
            case superStatus.unsubscribe:
            case superStatus.block:
                return 'red';
            default:
                return '';
        }
    }

    public convertDate(data): string {
        return data && data !== '' ? moment(data).format('DD/MM/YYYY') : '';
    }

    @HostListener('window:scroll', ['$event'])
    private doSomethingOnWindowScroll(event: Event) {
        if (this.automaticLoad && !this.loaded) {
            const element: any = event.srcElement;
            const scrollOffset = element.children[0].scrollTop;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight - 100;

            if (scrollOffset > windowHeight) {
                this.automaticLoad = this.body.page >= 2 ? false : true;
                this.nextPage();
            }
        }
    }

    public nextPage() {
        if (this.body.page < this.lastPage) {
            this.body.page += 1;
            this.getOrderListPdv(this.homeService.getBrandPermission(this.brands));
        }
    }

    private getInfoOperation(): INavigateFromOperation {
        return {
            body: this.body,
            data: this.dataSource,
            lastPage: this.lastPage,
            forms: {
                filterBrandForm: this.filterBrandFormGroup.getRawValue(),
                customFilterForm: this.customFilterFormGroup.getRawValue()
            }
        };
    }

    private filterIsActive(brands, filters) {
        let auxBrand = JSON.parse(JSON.stringify(brands));
        if (brands && brands.length && filters) {
            for (let key of Object.keys(filters)) {
                if (!filters[key]) {
                    auxBrand = auxBrand.filter(x => x !== key)
                }
            }
        }
        return auxBrand;
    }

    public convertStatusClass(status): string {
        switch (status) {
            case simyoDocStatus.ok:
                return 'dot-complete';
            case simyoDocStatus.notSend:
            case simyoDocStatus.ko:
                return 'dot-cancel-red';
            case simyoDocStatus.pte:
                return 'dot-pending';
            default:
                return 'dot-white';
        }
    }

    public showAnimationDoc(order): boolean {
        if (
            order && order.brand === this.brands.simyo &&
            order.doc_status === simyoDocStatus.notSend &&
            moment(order.date).isBetween(moment().subtract(15, "days").toDate(), moment().subtract(2, "days").toDate())
        ) {
            return true;
        }
        return false;
    }

    private getSimyoMsg() {
        this.homeService.getSimyoDocSendPendingFromService()
            .subscribe((data: any) => {
                if (data && data.msg && data.msg.total) {
                    this.showSimyoMsg = true;
                }
            }, () => {
                this.showSimyoMsg = false;
            });
    }

    private downloadReport(date) {
        this.downloading = true;
        this.downloadErrorMsg = null;
        this.homeService.downloadReportFromService(this.homeService.getBrandPermission(this.brands), this.pdv, this.brands, date.startOf('month').format('DD/MM/YYYY'), date.endOf('month').format('DD/MM/YYYY'))
            .subscribe((data: any) => {
                if (data && data.url_download && (data.url_download.xlsx !== '' || data.url_download.csv !== '')) {
                    this.downloadFile(data.url_download.xlsx && data.url_download.xlsx !== '' ? data.url_download.xlsx : data.url_download.csv);
                } else {
                    this.downloadErrorMsg = data && data.message && data.message.info && data.message.info !== '' ? data.message.info : 'Hemos tenido algún problema al generar el informe. Inténtelo de nuevo más tarde';
                }
            }, () => {
                this.downloading = false;
                this.downloadErrorMsg = 'Hemos tenido algún problema al generar el informe. Inténtelo de nuevo más tarde';
            }, () => {
                this.downloading = false;
            });
    }

    private downloadFile(url) {
        if (url && url !== '') {
            window.location.href = url;
        }
    }

    public showButtonReport() {
        if (this.pdv && this.brands) {
            return this.homeService.getBrandPermission(this.brands).length;
        }
        return false
    }

    public chosenMonthHandler(normalizedMonth, datepicker) {
        const ctrlValue = this.dateControl.value;
        ctrlValue.month(normalizedMonth.month());
        ctrlValue.year(normalizedMonth.year());
        this.dateControl.setValue(ctrlValue);
        this.downloadReport(this.dateControl.value);
        datepicker.close();
    }

    public updateStatus(order) {
        order.update_full = true;
        order.error_update_full = false;
        this.simyoOrderService.getOrderFromService(order.order_id)
            .subscribe((data: any) => {
                if (data && data.msg && data.msg.length) {
                    this.mergeDataOrder(order, data.msg[0]);
                    order.update_full = false;
                };
            }, (error) => {
                order.update_full = false;
                order.error_update_full = true;
            });
    }

    private mergeDataOrder(oldOrder, newStateOrder) {
        if (oldOrder && newStateOrder) {
            // Lineas
            if (oldOrder.lines && oldOrder.lines.length) {
                oldOrder.lines.forEach((line, index) => {
                    if (line.type === 'broadband_new' || line.type === 'broadband_portability') {
                        line.activation_date = newStateOrder?.broadband?.activation_date;
                        line.status = newStateOrder?.broadband?.super_status;
                        line.status_description = newStateOrder?.broadband?.status_description;
                    }
                    let mobileIndex = index;
                    if (oldOrder.type === orderType.CONVERGENT) {
                        mobileIndex--;
                    };
                    if (line.type === 'mobile_new' || line.type === 'mobile_portability' || line.type === 'mobile_renove') {
                        line.activation_date = newStateOrder?.mobiles[mobileIndex]?.activation_date;
                        line.msisdn = newStateOrder?.mobiles[mobileIndex]?.new_msisdn;
                        line.status = newStateOrder?.mobiles[mobileIndex]?.super_status;
                        line.status_description = newStateOrder?.mobiles[mobileIndex]?.status_description;
                    }
                });
            }
            // Generic
            oldOrder.contract_number = newStateOrder.contract_id;
            oldOrder.doc_status = newStateOrder.doc_status;
            oldOrder.status = newStateOrder.super_status;
        }
    }

    public showOrderLoad(lines): boolean {
        if (lines && lines.length) {
            return lines.find(line => (line.status === superStatus.in_process) || (line.status === superStatus.active && !line.activation_date));
        }
        return false;
    }

    public getTypeFiber(line): string {
        if (line?.access_type && line?.access_type !== '' && (line.type === 'broadband_new' || line.type === 'broadband_portability')) {
            return fiberIndirectTechnologies.includes(line?.access_type) ? ' IND' : ' directa';
        }
        return '';
    }

    ngOnDestroy() {
        this.changeTabSubscription.unsubscribe();
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
        this.cancelRequest();
    }

}
