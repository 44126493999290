import { Directive, ElementRef, Injectable, Input, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Directive()
export class BaseComponent {
    @Input() id: string;
    @Input() label: string = '';
    @Input() labelTemplate!: TemplateRef<any>;  // Para label como template
    @Input() isRequired: boolean = false;
    @Input() formGroup: FormGroup = new FormGroup({});
    @Input() controlName: string = 'default';
    @Input() isDisabled: boolean = false;
    @Input() value: string = '';


    @ViewChild('inputElement') inputElement!: ElementRef;

    protected isFocused = false;
    protected control: FormControl = null;
    protected isEmpty = true;

    constructor(public formBuilder: FormBuilder) {
        this.formGroup = this.formBuilder.group({
            default: [''],
        });
    }

    onFocus() {
        this.isFocused = true;
    }

    onBlur() {
        this.isFocused = false;
    }

    public getControl(controlName:string) {
        return this.formGroup ? (this.formGroup?.get(controlName.toString()) as FormControl) : null;
    }

    focusInput() {
        this.inputElement.nativeElement.focus();
    }

    getValue() {
        return this.control.value;
    }
}
