import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../../modals/confirm/confirm.component';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, shareReplay, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { SimyoAppointmentService } from '../../services/simyo-appointment.service';
import { BehaviorSubject, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Slot } from '../../models/appointment';
import * as moment from 'moment';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { scrollBottom } from 'src/app/utils/scrollBottom';

@Component({
    selector: 'app-simyo-appointment',
    templateUrl: './simyo-appointment.component.html',
    styleUrls: ['./simyo-appointment.component.scss'],
})
export class SimyoAppointmentComponent implements OnInit, OnChanges {
    @Input() currentClient = null;
    @Input() order = null;
    @Input() orderId = null;
    @Input() pdv = null;
    @Output() OnChangeStatus = new EventEmitter<boolean>();
    @Output() OnChangeAppointment = new EventEmitter<string>();
    appointmentFormGroup: FormGroup;

    @ViewChild('error') errorModal: NgbModalRef;
    @ViewChild('scrollContainer', { static: false }) scrollContainer:
        | ElementRef
        | undefined;

    public slotsAppointment$: Observable<any>;
    public customAppointmentData: any;
    public reloadSlotsAppointment$$: Subject<void> = new Subject();
    public statusSlotsAppointment$: BehaviorSubject<string> = new BehaviorSubject(
        'initial'
    );

    columnWidth = 160; //El ancho de cada columna en píxeles
    get selectedSlot(): Slot | null {
       return this.simyoAppointmentService.selectedSlot;
    };
    set selectedSlot(slot: Slot | null) {
        this.simyoAppointmentService.selectedSlot = slot;
    };
    loadingAppointment = false;
    statuses = { initial: 'initial', modify: 'modify', selected: 'selected' };
    public statusAppointment$: BehaviorSubject<string> = new BehaviorSubject(
        this.statuses.initial
    );
    typeAppointment = 'manual';
    msgError = '';
    msgErrorCreateAppointment = 'Ha ocurrido un problema durante el proceso de reserva de su cita. Por favor, inténtelo nuevamente. Si el problema persiste, no dude en contactarnos para recibir asistencia.';
    msgErrorCancelAppointment = 'No es posible cancelar la cita en este momento. Por favor, continúe con la cita programada. Recibirá un SMS en breve con los pasos para poder cambiar la fecha de la cita si es necesario.';
    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private simyoAppointmentService: SimyoAppointmentService,
        private route: ActivatedRoute,
        private simyoOrderService: SimyoOrderService
    ) {
        this.appointmentFormGroup = this.formBuilder.group({
            name: [{value:'', disabled:true}, [Validators.required]],
            lastname: [{value:'', disabled:true}, [Validators.required]],
            phone: [{value:'', disabled:true}, [Validators.required]],
            observations: ['', []],
            title: [''],
            startDateTime: [''],
            useDataClient: true,
            appointment_id: ''
        });

        const route$ = this.route.queryParams.pipe(delay(0));
        let lastSlots = this.simyoAppointmentService.cachedSlots;
        let lastPostCode = this.simyoAppointmentService.cachedPostCode;
        this.slotsAppointment$ = merge(route$, this.reloadSlotsAppointment$$).pipe(
            debounceTime(600),
            map(() => this.order?.customer?.installation_address?.post_code),
            // Solo continuar si hay post_code
            filter((postCode) => postCode != null),
            // Evita ejecutar si el post_code no ha cambiado
            distinctUntilChanged(),
            tap(() => {
                this.statusSlotsAppointment$.next('loading');
            }),
            switchMap((postCode) => {
                // Si ya hay una cita o ya se consultaron slots para este código postal, no volver a llamar
                if (this.hasAppointment() || postCode === lastPostCode) {
                    this.statusSlotsAppointment$.next('initial');
                    return of(lastSlots); // Devuelve la lista almacenada
                }
                // Hacer la petición si es un post_code nuevo y no hay cita
                return this.simyoAppointmentService.getAppointmentSlots(postCode, this.orderId).pipe(
                    tap((slots) => {
                        this.simyoAppointmentService.cachedPostCode = postCode;
                        this.simyoAppointmentService.cachedSlots = slots;
                        lastPostCode = postCode;
                        lastSlots = slots;
                        this.selectedSlot = null;
                        this.statusSlotsAppointment$.next('initial');
                        scrollBottom();
                    }),
                    catchError((err) => {
                        this.statusSlotsAppointment$.next('error');
                        return of(lastSlots);
                    })
                );
            }),
            shareReplay()
        );



    }

    ngOnInit(): void {
        this.appointmentFormGroup
            .get('useDataClient')
            .valueChanges.subscribe((useDataClient) => {
                if (useDataClient == false && this.statusAppointment$.value !== 'selected') {
                    this.appointmentFormGroup.get('name').enable();
                    this.appointmentFormGroup.get('lastname').enable();
                    this.appointmentFormGroup.get('phone').enable();
                    this.appointmentFormGroup.patchValue({
                        name: this.customAppointmentData?.name ?? '',
                        lastname: this.customAppointmentData?.lastname ?? '',
                        phone: this.customAppointmentData?.phone ?? '',
                        observations: this.customAppointmentData?.observations ?? '',
                    });
                } else {
                    this.appointmentFormGroup.get('name').disable();
                    this.appointmentFormGroup.get('lastname').disable();
                    this.appointmentFormGroup.get('phone').disable();
                }
                if (useDataClient == true && this.currentClient !== null) {
                    this.appointmentFormGroup.patchValue({
                        name: this.currentClient.account_firstName,
                        lastname: this.currentClient.account_surname1,
                        phone: this.currentClient.phone,
                    });
                } else if(!useDataClient && this.customAppointmentData){
                    this.appointmentFormGroup.patchValue({
                        name: this.customAppointmentData.name,
                        lastname: this.customAppointmentData.lastname,
                        phone: this.customAppointmentData.phone,
                    });
                } 
            });

        this.statusAppointment$.subscribe(status => {
            if(status == this.statuses.selected) {
                this.appointmentFormGroup.disable();
            } else {
                this.appointmentFormGroup.enable();
            }
            this.OnChangeStatus.emit(
                this.typeAppointment == 'later' || (this.appointmentFormGroup.valid && status == this.statuses.selected)
            );
        })

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentClient.currentValue !== null) {
            this.appointmentFormGroup.patchValue({
                name: this.customAppointmentData?.name ?? this.currentClient.account_firstName,
                lastname: this.customAppointmentData?.lastname ?? this.currentClient.account_surname1,
                phone: this.customAppointmentData?.phone ?? this.currentClient.phone,
                observations: this.customAppointmentData?.observations ?? ''
            });
            this.reloadSlotsAppointment$$.next();
        }
    }

    scrollRight() {
        if (this.scrollContainer) {
            const currentScrollPosition =
                this.scrollContainer.nativeElement.scrollLeft;
            const newScrollPosition = currentScrollPosition + this.columnWidth;

            this.scrollContainer.nativeElement.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth',
            });
        }
    }

    scrollLeft() {
        if (this.scrollContainer) {
            const currentScrollPosition =
                this.scrollContainer.nativeElement.scrollLeft;
            const newScrollPosition = currentScrollPosition - this.columnWidth;

            this.scrollContainer.nativeElement.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth',
            });
        }
    }

    //Función para seleccionar un slot
    selectSlot(slot) {
        this.selectedSlot = slot;
    }

    // Función para verificar si el slot está seleccionado
    isSelected(slot: string): boolean {
        return (
            this.selectedSlot?.startDateTime === slot
        );
    }

    confirmAppointment() {
        this.customAppointmentData = this.appointmentFormGroup.getRawValue();
        if (this.statusAppointment$.value == this.statuses.modify) {
            this.showModalConfirmModifyAppointment();
            return;
        }
        this.showModalConfirmAppointment();
    }

    reloadDates() {
        this.selectedSlot = null;
        this.reloadSlotsAppointment$$.next();
    }

    checkTypeSelected(type) {
        return this.typeAppointment === type;
    }

    changeTypeAppointment(type) {
        if(this.statusAppointment$.value == this.statuses.selected) {
            this.showModalCancelAppointment(type);
            return;
        }
        this.typeAppointment = type;
        if (this.typeAppointment == 'later') {
            this.OnChangeStatus.emit(true);
        } else {
            this.OnChangeStatus.emit(false);
            this.reloadSlotsAppointment$$.next();
        }
    }

    hasAppointment() {
        return (
            this.appointmentFormGroup.get('startDateTime').value != null
            && this.appointmentFormGroup.get('startDateTime').value != ''
        );
    }

    getCurrentAppointmentLabel() {
        return this.getAppointmentLabel(this.appointmentFormGroup.getRawValue());
    }

    getSelectedAppointmentLabel() {
        return this.getAppointmentLabel(this.selectedSlot);
    }

    getAppointmentLabel(appointment) {
        if(appointment == null || appointment.startDateTime == null || appointment.startDateTime == '') return '';
        const day = moment(appointment.startDateTime);

        return `${day.format('dddd')} ${
            day.format('DD-MM-YYYY')
        } de ${appointment.title?.replace('-', 'a')}`;
    }

    modifyAppointment() {
        this.showModalModifyAppointment();
    }

    cancelAppointment() {
        this.showModalCancelAppointment();
    }

    showModalCancelAppointment(typeAppointment = null) {
        const options: NgbModalOptions = {
            backdrop: 'static',
            size: 'lg',
            centered: true,
            windowClass: 'modalAlertChange',
        };

        let modalWarningRef = this.modalService.open(ConfirmComponent, options);
        modalWarningRef.componentInstance.text = `Se va a cancelar la cita de instalación existente.</br>¿Desea llevar a cabo la cancelación?`;
        modalWarningRef.componentInstance.title = '¡Advertencia!';
        modalWarningRef.componentInstance.onConfirm
            .pipe(take(1))
            .subscribe((result) => {
                if (result !== 'SI') {
                    return;
                }
                this.statusSlotsAppointment$.next('cancel-appointment');
                let appointmentData = this.appointmentFormGroup.getRawValue();
                this.simyoAppointmentService.cancel(appointmentData.appointment_id, this.orderId).pipe(
                    take(1),
                    tap((response:any) => {
                        if(response.msg && response.msg.code && response.msg.code == 200 && response.msg.message == 'OK') {
                            if(typeAppointment) {
                                this.typeAppointment = typeAppointment;
                                if(this.typeAppointment == 'manual') {
                                    this.reloadSlotsAppointment$$.next();
                                }
                            }
                            this.statusAppointment$.next(this.statuses.initial);
                            this.appointmentFormGroup.patchValue({
                                startDateTime: null,
                                appointment_id: null
                            });
                            this.selectedSlot = null
                            this.simyoOrderService.setAppointmentDateBroadband(null);
                        } else {
                            this.openModal(this.errorModal, this.msgErrorCancelAppointment);
                        }
                        this.statusSlotsAppointment$.next('initial');
                    }),
                    catchError(error => {
                        this.statusSlotsAppointment$.next('initial')
                        this.openModal(this.errorModal, this.msgErrorCancelAppointment);
                        return of();
                    })
                ).subscribe()

            });
    }

    showModalModifyAppointment() {
        const options: NgbModalOptions = {
            backdrop: 'static',
            size: 'lg',
            centered: true,
            windowClass: 'modalAlertChange',
        };

        let modalWarningRef = this.modalService.open(ConfirmComponent, options);
        modalWarningRef.componentInstance.text = `Se va a modificar la cita de instalación existente.</br>¿Desea llevar a cabo el cambio?`;
        modalWarningRef.componentInstance.title = '¡Advertencia!';
        modalWarningRef.componentInstance.onConfirm
            .pipe(take(1))
            .subscribe((result) => {
                if (result !== 'SI') {
                    return;
                }
                this.selectedSlot = null;
                this.statusAppointment$.next(this.statuses.modify);
            });
    }

    showModalConfirmModifyAppointment() {
        const options: NgbModalOptions = {
            backdrop: 'static',
            size: 'lg',
            centered: true,
            windowClass: 'modalAlertChange',
        };

        let modalWarningRef = this.modalService.open(ConfirmComponent, options);
        modalWarningRef.componentInstance.text = `Se va a cambiar la cita de instalación <strong>${this.getCurrentAppointmentLabel()}</strong> por</br><strong>${this.getSelectedAppointmentLabel()}</strong>.</br>¿Desea llevar a cabo el cambio?`;
        modalWarningRef.componentInstance.title = '¡Advertencia!';
        modalWarningRef.componentInstance.onConfirm
            .pipe(take(1))
            .subscribe((result) => {
                if (result !== 'SI') {
                    return;
                }
                this.statusSlotsAppointment$.next('cancel-appointment');
                let appointmentData = this.appointmentFormGroup.getRawValue();
                this.simyoAppointmentService.cancel(appointmentData.appointment_id, this.orderId).pipe(
                    take(1),
                    tap((response:any) => {
                        if(response.msg && response.msg.code && response.msg.code == 200) {
                            this.statusAppointment$.next(this.statuses.initial);
                            this.appointmentFormGroup.patchValue({
                                startDateTime: this.selectedSlot.startDateTime,
                                title: this.selectedSlot.title
                            });
                            this.createAppointment();
                        } else {
                            if(response.msg) {
                                if(response.msg.code == 802) {
                                    //Ya existe un work order para el cliente.

                                }
                            }
                            this.statusSlotsAppointment$.next('initial');
                            this.openModal(this.errorModal, this.msgErrorCancelAppointment);
                        }
                    }),
                    catchError(error => {
                        this.statusSlotsAppointment$.next('initial')
                        this.openModal(this.errorModal, this.msgErrorCancelAppointment);
                        return of({error: true});
                    })
                ).subscribe()

            });
    }

    showModalConfirmAppointment() {
        const options: NgbModalOptions = {
            backdrop: 'static',
            size: 'lg',
            centered: true,
            windowClass: 'modalAlertChange',
        };

        let modalWarningRef = this.modalService.open(ConfirmComponent, options);
        modalWarningRef.componentInstance.text = `La cita de instalación seleccionada es <strong>${this.getSelectedAppointmentLabel()}</strong>.</br>¿Deseas confirmar esta cita?`;
        modalWarningRef.componentInstance.title = 'Confirmar Cita';
        modalWarningRef.componentInstance.onConfirm
            .pipe(take(1))
            .subscribe((result) => {
                if (result !== 'SI') {
                    return;
                }
                this.appointmentFormGroup.patchValue({
                    startDateTime: this.selectedSlot.startDateTime,
                    title: this.selectedSlot.title
                });
                this.createAppointment();

            });
    }

    public openModal(content, message) {
        this.msgError = message;
        this.modalService.open(content, {size: 'lg', centered: true});
    }

    public createAppointment() {
        this.statusSlotsAppointment$.next('create-appointment');
        let appointmentData = this.appointmentFormGroup.getRawValue();
        this.simyoAppointmentService.create(
            this.orderId,
            appointmentData.startDateTime,
            this.order?.customer?.installation_address?.post_code,
            this.pdv?.sfids?.simyo,
            appointmentData.name,
            appointmentData.lastname,
            this.currentClient.account_doc,
            this.currentClient.account_types,
            appointmentData.phone,
            appointmentData.observations,
            this.currentClient.email,
            this.currentClient.account_firstName,
            `${this.currentClient.account_surname1}${this.currentClient.account_surname2 ? ' ' + this.currentClient.account_surname2 : ''}`
        ).pipe(
            take(1),
            tap(response => {
                if(response.msg && response.msg.code && response.msg.code == 200 && response.msg.id !== undefined && response.msg.id !== null && response.msg.id !== '') {
                    this.appointmentFormGroup.patchValue({appointment_id: response.msg.id});
                    this.customAppointmentData = this.appointmentFormGroup.getRawValue();
                    this.statusAppointment$.next(this.statuses.selected);
                    this.simyoOrderService.setAppointmentDateBroadband({id: response.msg.id, startDateTime: appointmentData.startDateTime});
                } else {
                    this.openModal(this.errorModal, this.msgErrorCreateAppointment);
                    this.statusAppointment$.next(this.statuses.initial);
                    this.appointmentFormGroup.patchValue({
                        startDateTime: null
                    });
                    this.reloadDates();
                }

                this.statusSlotsAppointment$.next('initial');
            }),
            catchError(error => {
                this.statusAppointment$.next(this.statuses.initial);
                this.msgError = (error as Error).message.replace(/^Error:\s*/, '');
                this.openModal(this.errorModal, this.msgError);
                this.appointmentFormGroup.patchValue({
                    startDateTime: null,
                    appointment_id: null
                });
                this.reloadDates();
                this.statusSlotsAppointment$.next('initial');
                return of();
            })
        ).subscribe()
    }
}
