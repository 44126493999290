<div class="digo-datepicker" [formGroup]="formGroup">
    <input class="input"
    type="text"
    [id]="id"
    [name]="controlName"
    [formControlName]="controlName"
    maxlength="10"
    (focus)="onFocus()"
    (blur)="onBlur();formatDate($event.target.value);"
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate"
    (change)="OnChange.emit($event)"/>
    <label class="label" [for]="controlName" *ngIf="label !== ''" [class.active]="isEmpty == false">{{label}}{{isRequired ? '*': ''}}</label>
    <fa-icon class="icon-calendar" matDatepickerToggleIcon [icon]="faCalendarDay"  (click)="picker.open()"></fa-icon>
    <div class="underline" [ngClass]="{'selected': isFocused}">
        <div class="underline-selected"></div>
    </div>
    <input-error class="error" [id]="id" [control]="control"></input-error>
</div>

<mat-datepicker #picker (opened)="selectedDate(picker)" (monthSelected)="selectedDate(picker)" min>
        <mat-datepicker-actions>
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-action-date-picker" mat-raised-button (click)="clearStartDate(picker)">Limpiar</button>
                <button class="btn btn-action-date-picker" mat-raised-button (click)="todayClicked(picker)">Hoy</button>
            </div>
        </mat-datepicker-actions>
</mat-datepicker>
