<div class="w-100 p-4 container" style="background-color: white;" [formGroup]="clienteFormGroup">
    <p>Formulario de prueba para los input nativos</p>
    <div class="row row-cols-2">
        <ng-container>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Campo para introducir el nº de documento, requerido, solo valida dni, tiene un maximo de caracteres (9), y solo permite caracteres alfanumericos </p>
                <digo-input
                id="doc"
                label="Nº de documento (solo dni)"
                [isRequired]="true"
                [formGroup]="clienteFormGroup"
                controlName="doc"
                [maxlength]="maxLengthByDoc"
                type="text"
                (OnKeyPress)="alphanumericOnly($event)"
                />
            </div>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Campo de texto con un máximo de 32 caracteres y requerido</p>
                <digo-input
                id="firstName"
                [label]="requireCif ? 'Razón social' : 'Nombre'"
                [isRequired]="true"
                [formGroup]="clienteFormGroup"
                controlName="firstName"
                maxlength="32"
                type="text"
                />
            </div>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Busqueda de dirección por google Y rellena el codigo postal</p>
                <digo-input
                id="nombreVia"
                [label]="'Nombre de la vía'"
                [isRequired]="true"
                [formGroup]="clienteFormGroup"
                controlName="nombreVia"
                type="maps"
                autocomplete="off"
                (OnInput)="onAddressChange($event);sendEvent($event);clienteFormGroup.get('tipoVia').patchValue('');"
                (OnFocusIn)="onAddressChange($event)"
                (OnFocusOut)="onAddressFocusOut()"
                (OnAutocompleteSelected)="onAutocompleteSelected($event)"
                />
            </div>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Requerido. Campo numerico con un min 0 y max 99999, patron numerico y permite solo digitos positivos</p>
                <digo-input
                id="codigoPostal"
                [label]="'Código Postal'"
                [isRequired]="true"
                [formGroup]="clienteFormGroup"
                controlName="codigoPostal"
                type="number"
                min="0"
                max="99999"
                pattern="\d+"
                (OnInput)="onChangeZip($event.target.value)"
                (OnKeyDown)="onlyPositiveDigits($event)"
                />
            </div>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>No Requerido. campo texto con longitud de min 9 y max 9</p>
                <digo-input
                id="phone"
                [label]="'Teléfono de contacto'"
                [isRequired]="false"
                [formGroup]="clienteFormGroup"
                controlName="phone"
                type="text"
                minlength="9"
                maxlength="9"
                />
            </div>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Requerido. campo texto con patron email</p>
                <digo-input
                id="email"
                [label]="'Correo electrónico'"
                [isRequired]="true"
                [formGroup]="clienteFormGroup"
                controlName="email"
                type="text"
                />
            </div>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Requerido. campo texto con patron email y tiene que coincidir con el campo email. No se autocompleta. No se permite pegar</p>
                <digo-input
                id="email_confirm"
                [label]="'Confirmar correo electrónico'"
                [isRequired]="true"
                [formGroup]="clienteFormGroup"
                controlName="email_confirm"
                type="text"
                autocomplete="off"
                [activeBlockPaste]="true"
                />
            </div>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Input deshabilitado</p>
                <digo-input
                id="planta"
                [label]="'Planta'"
                [isRequired]="false"
                [formGroup]="clienteFormGroup"
                controlName="planta"
                type="text"
                autocomplete="off"
                [activeBlockPaste]="true"
                [isDisabled]="true"
                />
            </div>
        </ng-container>
        <ng-container>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Selecto de genero, es requerido</p>
                <digo-select
                id="gender"
                [label]="'Sexo'"
                [options]="listGenders"
                [formGroup]="clienteFormGroup"
                controlName="sex"
                optionValue="gender_id"
                optionLabel="value"
                [isRequired]="true"
                (OnChange)="onchangeTest($event)"
                />
            </div>
            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Selector de provincias. Se completa con el codigo postal y está deshabilitado. Es requerido</p>
                <digo-select
                id="province"
                [label]="'Provincia'"
                [options]="datosProvincia"
                [formGroup]="clienteFormGroup"
                controlName="provincia"
                optionValue="code"
                optionLabel="code"
                [isDisabled]="true"
                [isRequired]="true"
                />
            </div>

            <div class="col mt-1 d-flex flex-column justify-content-between">
                <p>Selector con objeto</p>
                <digo-select
                id="gender"
                [label]="'Sexo'"
                [options]="listTest"
                optionValue="gender_id"
                optionLabel="value"
                [isRequired]="true"
                (OnChange)="onchangeTest($event)"
                />
            </div>
        </ng-container>
        <ng-container>
            <div class="col mt-1 d-flex flex-column justify-content-between">
            <p>Date picker </p>
            <digo-datepicker
                id="birthday"
                label="Fecha de nacimiento"
                [formGroup]="clienteFormGroup"
                controlName="birthday"
                [minDate]="minDate"
                [maxDate]="maxDate"
                />
                </div>
        </ng-container>
        <div class="col mt-1 d-flex flex-column justify-content-between">
            <p>Select buscador </p>
            <digo-input
            id="search"
            [labelTemplate]="titleSearch"
            [isRequired]="true"
            [formGroup]="clienteFormGroup"
            controlName="search"
            type="text"
            />
        </div>
        <div class="col mt-1 d-flex flex-column justify-content-between">
            <digo-input
            id="search"
            [labelTemplate]="titleSearch"

            isSearch="true"
            type="text"
            />
        </div>
        <div class="col mt-1 d-flex flex-column justify-content-between">
            <digo-textarea #observations id="observations-1" rows="3" [isRequired]="true" [isDisabled]="false" label="Observaciones" value="Esto es una prueba"></digo-textarea>
            <p>Valor del textarea {{observations.getValue()}}</p>
        </div>


    </div>
</div>
<ng-template #titleSearch>
    <i-feather name="search" class="icon--fe" stroke-width="2.5"></i-feather>
    <b> Buscador</b> <span class="search--cliente"> (Nombre / DNI / Nº de teléfono)</span>
</ng-template>
