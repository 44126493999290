<div class="custom-textarea" [formGroup]="formGroup">
    <textarea
        #inputElement
        class="textarea"
        [id]="id"
        [required]="isRequired"
        [placeholder]="placeholder"
        [name]="controlName"
        [formControlName]="controlName"
        [maxlength]="maxlength"
        [minlength]="minlength"
        [autocomplete]="autocomplete"
        [rows]="rows"

        appBlockPaste
        [activeBlockPaste]="activeBlockPaste"

        (focus)="onFocus()"
        (blur)="onBlur()"
        (keypress)="OnKeyPress.emit($event)"
        (keydown)="OnKeyDown.emit($event)"
        (input)="OnInput.emit($event)"
        (keyup.enter)="OnKeyUpEnter.emit($event)">
    </textarea>
    <label class="label" [for]="controlName" *ngIf="label !== '' || labelTemplate" [class.active]="isEmpty == false">
        <ng-container *ngIf="labelTemplate; else plainLabel">
            <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
          </ng-container>
          <ng-template #plainLabel>
            {{ label }}
          </ng-template>
          {{isRequired ? '*': ''}}
    </label>
    <div class="underline" [ngClass]="{'selected': isFocused}">
        <div class="underline-selected"></div>
    </div>
    <input-error class="error" [id]="id" [control]="control"></input-error>
</div>
