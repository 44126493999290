<div class="content">
    <div class="row">
        <div class="col-lg-9">

        </div>
        <div class="col-lg-3">
            <div class="resumen d-flex justify-content-between align-items-center">
                <div>
                    <digo-input
                        id="search-client"
                        [labelTemplate]="titleSearch"
                        (input)="searchClient($event.target.value)"
                        type="text"
                        />
                    <ng-template #titleSearch>
                        <i-feather name="search" class="icon--fe" stroke-width="2.5"></i-feather>
                        <span class="search--cliente">Buscar por nombre o DNI</span>
                    </ng-template>
                </div>

                <!-- Filter -->
                <small class="text-muted">
                    <a href="#" data-bs-toggle="collapse" data-bs-target="#filtros" [attr.aria-expanded]="isCollapsed" aria-controls="filtros" title="Filtros avanzados">
                        <img src="./assets/img/filter.svg" class="img-fluid" width="23">
                    </a>
                </small>
            </div>
        </div>
    </div>
</div>

<div (window:scroll)="doSomethingOnWindowScroll($event)">
    <div class="row">
        <div class="col-lg-12">
            <div id="filtros" class="filters-collapse collapse py-3" [ngbCollapse]="isCollapsed">

                <form [formGroup]="formGroup">
                    <div class="row">
                        <div class="col-lg-9">
                            <digo-input
                                id="full_name"
                                label="Nombre y/o apellidos"
                                [formGroup]="formGroup"
                                controlName="full_name"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-3">
                            <digo-input
                                id="doc"
                                label="NIF/NIE/Pasaporte/CIF"
                                [formGroup]="formGroup"
                                controlName="document"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-4">
                            <digo-input
                                id="msisdn"
                                label="Nº teléfono móvil"
                                [formGroup]="formGroup"
                                controlName="msisdn_movil"
                                type="text"
                                maxlength="9"
                                minlength="9"
                                (keypress)="numberOnly($event)"
                            />
                        </div>

                        <div class="col-lg-4">
                            <digo-datepicker
                                id="min_date"
                                label="Fecha de solicitud desde"
                                [formGroup]="formGroup"
                                controlName="min_date"
                                [maxDate]="maxDate"
                                (OnDateInput)="setMinDate($event)"
                            />
                        </div>

                        <div class="col-lg-4">
                            <digo-datepicker
                                id="max_date"
                                label="Fecha de solicitud hasta"
                                [formGroup]="formGroup"
                                controlName="max_date"
                                [minDate]="minDate"
                                (OnDateInput)="setMaxDate($event)"
                            />
                        </div>

                        <div class="col-lg-4">
                            <digo-select
                                id="status"
                                label="Estado"
                                [formGroup]="formGroup"
                                controlName="status"
                                [options]="listSuperStatuses"
                                optionValue="value"
                                optionLabel="label"
                                [allowDefaultEmpty]="true"
                            />
                        </div>

                        <div class="col-lg-4">
                            <digo-select
                                id="sort_by_date"
                                label="Ordenar por fecha"
                                [formGroup]="formGroup"
                                controlName="sort_by_date"
                                [options]="listSortByDate"
                                optionValue="id"
                                optionLabel="label"
                                [allowDefaultEmpty]="true"
                            />
                        </div>
                    </div>

                    <button class="btn btn-black-outline mb-1 btn-sm" type="button" (click)="advanceSearch()">
                        Aplicar filtro
                    </button>
                </form>

                <br>
            </div>
        </div>
    </div>
</div>

<div class="content" *ngIf="(orange$ | async)?.orderListFiltered?.length">
    <div class="row">
        <div class="col-lg-12">
            <!--- \\\\\\\Cliente -->
            <div class="card gedf-card mb-3" *ngFor="let order of (orange$ | async)?.orderListFiltered; let i = index">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <ul class="nav nav-pills card-header-pills topnav" id="customer-tab-{{ i }}" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link text-list active" data-bs-toggle="tab" href="#cliente-{{ i }}" role="tab" aria-controls="cliente" aria-selected="true">
                                        Cliente
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                            <div *ngIf="order.created_at">
                                <!-- Fecha de solicitud -->
                                <span class="info-secundaria dir-text2 d-inline-block">
                                    Fecha de solicitud: {{ order.created_at | date: 'dd/MM/yyyy, HH:mm' }} <br>
                                </span>
                                <!-- Usuario -->
                                <span class="badge badge-light badge-shape" *ngIf="order.user">
                                    {{ order.user?.username}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body bg-white">
                    <div class="tab-content" id="customer-tab-content-{{ i }}">
                        <div class="tab-pane fade show active" id="cliente-{{ i }}" role="tabpanel">
                            <div class=" mb-0">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list mb-0">
                                            <span class="text-list" *ngIf="order.customer_full_name">
                                                <b>{{ order.customer_full_name }}</b>
                                                <b *ngIf="order.document_type && order.document_number">
                                                    - {{ order?.document_type }}: {{ order.document_number }}
                                                </b>
                                                <br>
                                            </span>
                                            <!-- MSISDN -->
                                            <span class="font-list" *ngIf="order.msisdn && order.msisdn !== ''">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Nº de Orange: <b>{{ order.msisdn }}</b>
                                            </span>
                                            <!-- Bono contratado -->
                                            <span class="font-list" *ngIf="order.bono && order.bono !== ''">
                                                <br>
                                                <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                Bono adicional: <b>{{ order.bono }} ({{ (+order.contracted_price % 1 !== 0 ? (+order.contracted_price).toFixed(2) : +order.contracted_price) + ' €' }})</b>
                                            </span>
                                        </h5>
                                    </div>
                                    <div  class="col-lg-4 d-flex justify-content-end">
                                        <span class="font-list text-list">
                                            <span class="{{ convertStatusClass(order.super_status) }}"></span>
                                            Estado: <b>{{ order.status }}</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-toolbar justify-content-end mb-3">
                            <div class="btn-group">
                                <a [routerLink]="['/orange/bonos-adicionales-order/', order.order_bono_id]" class="btn btn-simyo-o2 btn-sm">
                                    Ver más detalles
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loaded" class="mt-2 mb-5">
    <h5  class="tar-name-preload text-center">
        Obteniendo ordenes...
    </h5>
    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
    </div>
    <br>
</div>

<div *ngIf="errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">
        Se ha producido un error, inténtalo de nuevo
    </h5>
</div>

<div *ngIf="loaded && !(orange$ | async)?.orderListFiltered?.length && !errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">
        No se han encontrado ordenes.
    </h5>
</div>

<div class="mt-4 mb-4 d-flex justify-content-center" *ngIf="body.page < lastPage && loaded && !errorSearch">
    <button class="btn btn-black-outline d-block w-100 mb-1" (click)="loadMore()">
        Cargar más
    </button>
    <br>
</div>

