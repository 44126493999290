<div class="appointment">
    <div class="card-header">
        <h6 class="font-weight-bold subtitulo-pack2">Cita con instalador</h6>
    </div>

    <div class="card-body bg-white">
        <div class="container-options">
            <button class="btn-option" (click)="changeTypeAppointment('manual')" [ngClass]="{'active': checkTypeSelected('manual')}">Cita manual</button>
            <button class="btn-option" (click)="changeTypeAppointment('later')" [ngClass]="{'active': checkTypeSelected('later')}">Cita posterior con llamada</button>
        </div>
        <div class="container mt-3" *ngIf="typeAppointment == 'later'">
            <label >Nos pondremos en contacto contigo para programar una cita</label>
        </div>

        <div class="container"  [formGroup]="appointmentFormGroup"  *ngIf="typeAppointment == 'manual'">
            <ng-container *ngIf="(slotsAppointment$|async) as slotsAppointment">
                <ng-container *ngIf="slotsAppointment.length > 0">
                    <div class="appointment-container mt-3" *ngIf="[statuses.initial, statuses.modify].includes((statusAppointment$|async))">
                        <div class="d-flex justify-content-between">
                            <label>Selecciona el día y la hora de la cita<span *ngIf="hasAppointment()">  -  <strong>Cita actual: {{getCurrentAppointmentLabel()}}</strong></span></label>
                            <label class="text-simyo pe-auto" role="button" (click)="reloadDates()">Actualizar fechas</label>
                        </div>

                        <div class="calendar-container mt-2">
                            <div class="overflow-hidden">
                                <div class="d-flex calendar-list" #scrollContainer>
                                <div *ngFor="let appointment of slotsAppointment" class="day-column text-center">
                                    <div class="day-header pb-1 pt-1">
                                    <span>{{ appointment.dayOfWeek|titlecase }}</span>
                                    <span>{{ appointment.day }}</span>
                                    </div>
                                    <div class="day-content-with-border">
                                    <div *ngFor="let slot of appointment.slots" class="slot-container">
                                        <div class="slot-button-container" [ngClass]="{ 'slot-selected': isSelected(slot.startDateTime) }">
                                        <button class="btn p-0" (click)="selectSlot(slot)">
                                            <i-feather name="clock" class="icon-clock" [ngClass]="{ 'free-state': !isSelected(slot.startDateTime), 'selected-state': isSelected(slot.startDateTime)}"></i-feather>
                                            <span class="slot-text">{{ slot.title }}</span>
                                        </button>
                                        </div>
                                    </div>
                                    <div *ngIf="appointment.slots.length == 0" class="slot-container">
                                        <div>
                                        <button class="btn p-0 slot-block">
                                            <i-feather name="clock" class="icon-clock blocked-state"></i-feather>
                                            <span class="no-slot-text">Sin disponibilidad</span>
                                        </button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <!-- Botones para desplazar las columnas -->
                            <div class="text-center arrows-column">
                                <div class="header">
                                    <div class="d-flex justify-content-center arrow-buttons-container">
                                        <button class="btn btn-outline-secondary arrow-button" (click)="scrollLeft()"><i-feather name="chevron-left" class="arrow-icon"></i-feather></button>
                                        <button class="btn btn-outline-secondary arrow-button" (click)="scrollRight()"><i-feather name="chevron-right" class="arrow-icon"></i-feather></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="(statusAppointment$|async) == statuses.selected">
                        <div class="container mt-3" >
                            <label>Tu cita ha sido reservada para el <strong>{{getCurrentAppointmentLabel()}}</strong></label>
                            <div>
                                <label class="text-simyo me-3" role="button" (click)="modifyAppointment()">Modificar</label>
                                <label class="text-simyo" role="button" (click)="cancelAppointment()">Cancelar cita</label>
                            </div>
                        </div>
                    </ng-container>

                    <hr/>
                    <h6 class="font-weight-bold mt-4 mb-4">Introduce los datos de contacto para el instalador</h6>

                    <div class="checkbox icheck-primary">
                        <input type="checkbox" id="is_same_data_client" class="accept-check" formControlName="useDataClient"/>
                        <label for="is_same_data_client"> Utilizar los mismos datos del cliente </label>
                    </div>

                    <div>
                        <div class="row">
                            <div class="col-lg-4 mt-0">
                                <div class="form-group mt-0 mb-0">
                                   <!--  <mat-form-field>
                                        <mat-label>Nombre</mat-label>
                                        <input matInput="" type="text" oninvalid="" formControlName="name" >
                                        <mat-error *ngIf="appointmentFormGroup.get('name')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field> -->
                                    <digo-input
                                            id="firstName"
                                            label="Nombre"
                                            [formGroup]="appointmentFormGroup"
                                            controlName="name"
                                            [maxlength]="64"
                                            type="text"
                                            [isRequired]="true"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-4 mt-0">
                                <div class="form-group mt-0 mb-0">
                                    <!-- <mat-form-field>
                                        <mat-label>Primer Apellido</mat-label>
                                        <input matInput="" type="text" oninvalid="" formControlName="lastname" >
                                        <mat-error *ngIf="appointmentFormGroup.get('lastname')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field> -->
                                    <digo-input
                                            id="firstName"
                                            label="Primer Apellido"
                                            [formGroup]="appointmentFormGroup"
                                            controlName="lastname"
                                            [maxlength]="64"
                                            type="text"
                                            [isRequired]="true"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-4 mt-0">
                                <div class="form-group mt-0 mb-0">
                                    <!-- <mat-form-field>
                                        <mat-label>Teléfono</mat-label>
                                        <input matInput="" type="text" oninvalid="" formControlName="phone" >
                                        <mat-error *ngIf="appointmentFormGroup.get('phone')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field> -->
                                    <digo-input
                                            id="firstName"
                                            label="Teléfono"
                                            [formGroup]="appointmentFormGroup"
                                            controlName="phone"
                                            [maxlength]="64"
                                            type="text"
                                            [isRequired]="true"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12 mt-0">
                                <div class="form-group mt-0">
                                    <!-- <mat-form-field>
                                        <mat-label>Observaciones</mat-label>
                                        <input matInput="" type="text" oninvalid="" formControlName="observations">
                                        <mat-error *ngIf="appointmentFormGroup.get('observations')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field> -->
                                    <digo-input
                                            id="firstName"
                                            label="Observaciones"
                                            [formGroup]="appointmentFormGroup"
                                            controlName="observations"
                                            type="text"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2 d-flex justify-content-end" *ngIf="appointmentFormGroup.valid && selectedSlot !== null && (statusAppointment$|async) != statuses.selected">
                        <button type="button" class="btn btn-simyo-o2 btn-sm py-2 px-3" (click)="confirmAppointment()">
                            Confirmar
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="slotsAppointment.length == 0 && (statusSlotsAppointment$|async) == 'initial'">
                    <div class="col-xl-12 mt-4">
                        <div class="text-center" style="padding-bottom: 5px;" >
                            <h5 class="message">
                                No se han encontrado citas disponibles.
                            </h5>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="(statusSlotsAppointment$|async) == 'error'">
                    <div class="col-xl-12 mt-4">
                        <div class="text-center" style="padding-bottom: 5px;" >
                            <h5 class="message">
                                Ha ocurrido un error al obtener las citas.
                            </h5>
                            <label class="text-simyo pe-auto" role="button" (click)="reloadDates()">Actualizar fechas</label>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="col-xl-12 loading"  *ngIf="(statusSlotsAppointment$|async) == 'loading'">
                <div class="text-center" style="padding-bottom: 5px;" >
                    <h5 class="tar-name-preload">
                        <img src="./assets/img/spinner-home.svg" width="30" alt=""> Obteniendo citas...
                    </h5>
                </div>
            </div>

        </div>
    </div>
    <div class="col-xl-12 loading"  *ngIf="(statusSlotsAppointment$|async) == 'create-appointment'">
        <div class="text-center" style="padding-bottom: 5px;" >
            <h5 class="tar-name-preload">
                <img src="./assets/img/spinner-home.svg" width="30" alt=""> Reservando cita...
            </h5>
        </div>
    </div>
    <div class="col-xl-12 loading"  *ngIf="(statusSlotsAppointment$|async) == 'cancel-appointment'">
        <div class="text-center" style="padding-bottom: 5px;" >
            <h5 class="tar-name-preload">
                <img src="./assets/img/spinner-home.svg" width="30" alt=""> Cancelando cita...
            </h5>
        </div>
    </div>
</div>


<ng-template #error let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Error</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>
    <div class="p-5">{{msgError}}
    </div>
</ng-template>
