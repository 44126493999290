<div class="modal-header">
    <h5 class="modal-title fw-bold ms-2" id="exampleModalLabel">Marcos retributivos</h5>
    <button type="button" class="close close-m" mat-dialog-close>
        <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row mb-2">
            <div class="col-12 col-xl">
                <!-- AMENA -->
                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.amena.permite_activaciones) && data?.autoInvoiceData?.mr?.amena">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/am-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120">
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-4 text-right">
                                    <a [href]="data?.autoInvoiceData.mr.amena" target="_blank" class="font-weight-bold black">Marco retributivo Amena</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- SIMYO -->
                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender) && data?.autoInvoiceData?.mr?.simyo">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/sm-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120">
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-4 text-right">
                                    <a [href]="data?.autoInvoiceData?.mr?.simyo_old" target="_blank" class="font-weight-bold black">Marco Retributivo Simyo anterior</a>
                                </p>
                                <p class="pt-4 text-right">
                                    <a [href]="data?.autoInvoiceData?.mr?.simyo" target="_blank" class="font-weight-bold black">Marco Retributivo multimarca vigente</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ORANGE -->
                <div class="card--notify mb-2" *ngIf="(permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)) && data?.autoInvoiceData?.mr?.orange">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/or-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120">
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-4">
                                    <a [href]="data?.autoInvoiceData?.mr?.orange" target="_blank" class="fw-bold black">Marco retributivo Orange</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Jazztel -->
                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) && data?.autoInvoiceData?.mr?.jazztel">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/jz-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120">
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-4 text-right">
                                    <a [href]="data?.autoInvoiceData?.mr?.jazztel_old" target="_blank" class="fw-bold black">Marco Retributivo Jazztel anterior</a>
                                </p>
                                <p class="pt-4 text-right">
                                    <a [href]="data?.autoInvoiceData?.mr?.jazztel" target="_blank" class="fw-bold black">Marco Retributivo multimarca vigente</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
