<div class="modal-header bg-simyo-grey">
    <div class="w-100 text-center">
        <h5 class="commissions-modal-title white">Aviso importante</h5>
    </div>
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
    </button>
</div>

<div class="modal-body pt-0">
    <div class="m-4 row">
        <p class="main-text-1 mb-0 mt-2">Debido a la actualización del sistema de comisiones, es necesario que firmes el nuevo contrato. </p>
        <p class="main-text-2">Te enviaremos un enlace a tu email para completar la firma.</p>

        <div class="row mt-3">
            <div class="col-12">
                <div class="email-container">
                    <div>Email de contacto:</div>
                    <div class="ml-3"><strong>{{ pdv.info.email }}</strong></div>
                </div>
                </div>
                <div class="col-12">
                <span class="wrong-email-text">
                    Si el email de contacto no es correcto contacta con tu GPV.
                </span>
            </div>
        </div>

        <div class="w-100 d-flex justify-content-end mb-2">
            <a class="btn btn-black--outline pl-4 pr-4 font-button" (click)="sendCommissionsLink()"
            [class.disabled]="isSendingCommissions || hasSentCommissions"
            [attr.aria-disabled]="isSendingCommissions || hasSentCommissions">
                <strong *ngIf="!isSendingCommissions && !hasSentCommissions">Enviar</strong>
                <strong *ngIf="isSendingCommissions">Enviando...</strong>
                <strong *ngIf="hasSentCommissions">Enviado</strong>
            </a>
        </div>
        
        <p *ngIf="errorMessage" class="text-danger mt-2" style="font-size: 12px;">{{ errorMessage }}</p>
    </div>
</div>