import { Injectable } from "@angular/core";
import { SimyoService } from "./simyo.service";
import { SimyoOrderService } from "./simyo-order.service";
import {mobileContractType} from 'src/app/shared/constantes';
import { take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SimyoEvaluateRateService {

    private rateMobileObservable: any;

    constructor(        
        private simyoService: SimyoService,
        private orderService: SimyoOrderService,
    ){
        
        this.rateMobileObservable = new BehaviorSubject<any>([]);
    }

    // updateRatesMobiles(ratesMobiles){
    //     ratesMobiles = this.simyoService.calculateMainLine(ratesMobiles);
    //     this.rateMobileObservable.next(ratesMobiles);
    //     return ratesMobiles;
    // }

    evaluateRateMobile(allRates, actualRate, maxBroadband) {
        // ratesMobiles.forEach(actualRate => {
            const foundMobile = allRates.MOBILE.find( rateMobile => (rateMobile?.contract_type === mobileContractType.postpaid ? rateMobile.rate_ids.postpaid : rateMobile.rate_ids.prepaid) === (actualRate?.type_pay === mobileContractType.postpaid ? actualRate?.rate_ids?.postpaid : actualRate?.rate_ids?.prepaid) );
            const foundAdditional = allRates.ADDITIONALS.find( rateMobile => (rateMobile?.contract_type === mobileContractType.postpaid ? rateMobile.rate_ids.postpaid : rateMobile.rate_ids.prepaid) === (actualRate?.type_pay === mobileContractType.postpaid ? actualRate?.rate_ids?.postpaid : actualRate?.rate_ids?.prepaid) );
            const foundAdditionalRefered = allRates.MOBILE.find( rateMobile => (rateMobile?.contract_type === mobileContractType.postpaid ? rateMobile.rate_ids.postpaid : rateMobile.rate_ids.prepaid) === foundAdditional?.rate_ids?.refered );
            const foundFtth = allRates.FTTH_NEW.find(ftth => ((ftth?.type_discount?.rate_id === maxBroadband?.rate_id) || (ftth?.type_normal?.rate_id === maxBroadband?.rate_id)));
            // Tenemos una tarifa cuyo real price es diferente al seleccionado
            if (foundMobile || foundAdditional) {
                if (foundMobile) {
                    actualRate.rate_info.real_price = foundMobile.rate_info?.real_price;
                    actualRate.rate_info.contracted_price = foundMobile.rate_info?.real_price;

                    actualRate.rate_info.ftth_1_price = foundMobile.rate_info?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_igic = foundMobile.rate_info?.prices?.igic?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_ipsi_8 = foundMobile.rate_info?.prices?.ipsi_8?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_ipsi_10 = foundMobile.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_1_price_iva = foundMobile.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.ftth_300_price = foundMobile.rate_info?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_igic = foundMobile.rate_info?.prices?.igic?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_ipsi_8 = foundMobile.rate_info?.prices?.ipsi_8?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_ipsi_10 = foundMobile.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_300_price_iva = foundMobile.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.ftth_600_price = foundMobile.rate_info?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_igic = foundMobile.rate_info?.prices?.igic?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_ipsi_8 = foundMobile.rate_info?.prices?.ipsi_8?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_ipsi_10 = foundMobile.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_600_price_iva = foundMobile.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.price = foundMobile.rate_info?.real_price;
                    actualRate.rate_info.price_igic = foundMobile.rate_info?.prices?.igic?.price;
                    actualRate.rate_info.price_ipsi_8 = foundMobile.rate_info?.prices?.ipsi_8?.price;
                    actualRate.rate_info.price_ipsi_10 = foundMobile.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.price_iva = foundMobile.rate_info?.prices?.iva?.price;
                }
                if (foundAdditional ) {
                    actualRate.rate_info.real_price = foundAdditional.rate_info?.real_price;
                    actualRate.rate_info.contracted_price = foundAdditional.rate_info?.real_price;

                    actualRate.rate_info.ftth_1_price = foundAdditional.rate_info?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_igic = foundAdditional.rate_info?.prices?.igic?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_ipsi_8 = foundAdditional.rate_info?.prices?.ipsi_8?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_ipsi_10 = foundAdditional.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_1_price_iva = foundAdditional.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.ftth_300_price = foundAdditional.rate_info?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_igic = foundAdditional.rate_info?.prices?.igic?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_ipsi_8 = foundAdditional.rate_info?.prices?.ipsi_8?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_ipsi_10 = foundAdditional.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_300_price_iva = foundAdditional.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.ftth_600_price = foundAdditional.rate_info?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_igic = foundAdditional.rate_info?.prices?.igic?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_ipsi_8 = foundAdditional.rate_info?.prices?.ipsi_8?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_ipsi_10 = foundAdditional.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_600_price_iva = foundAdditional.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.price = foundAdditional.rate_info?.real_price;
                    actualRate.rate_info.price_igic = foundAdditional.rate_info?.prices?.igic?.price;
                    actualRate.rate_info.price_ipsi_8 = foundAdditional.rate_info?.prices?.ipsi_8?.price;
                    actualRate.rate_info.price_ipsi_10 = foundAdditional.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.price_iva = foundAdditional.rate_info?.prices?.iva?.price;

                    if(!maxBroadband || !foundAdditional.rate_info?.available_in?.includes(foundFtth?.rate_info?.speed)){
                        actualRate.rate_info.real_price = foundAdditionalRefered.rate_info?.real_price;
                        actualRate.rate_info.contracted_price = foundAdditionalRefered.rate_info?.real_price;
    
                        actualRate.rate_info.ftth_1_price = foundAdditionalRefered.rate_info?.ftth_1_price;
                        actualRate.rate_info.ftth_1_price_igic = foundAdditionalRefered.rate_info?.prices?.igic?.ftth_1_price;
                        actualRate.rate_info.ftth_1_price_ipsi_8 = foundAdditionalRefered.rate_info?.prices?.ipsi_8?.ftth_1_price;
                        actualRate.rate_info.ftth_1_price_ipsi_10 = foundAdditionalRefered.rate_info?.prices?.ipsi_10?.price;
                        actualRate.rate_info.ftth_1_price_iva = foundAdditionalRefered.rate_info?.prices?.iva?.price;
    
                        actualRate.rate_info.ftth_300_price = foundAdditionalRefered.rate_info?.ftth_300_price;
                        actualRate.rate_info.ftth_300_price_igic = foundAdditionalRefered.rate_info?.prices?.igic?.ftth_300_price;
                        actualRate.rate_info.ftth_300_price_ipsi_8 = foundAdditionalRefered.rate_info?.prices?.ipsi_8?.ftth_300_price;
                        actualRate.rate_info.ftth_300_price_ipsi_10 = foundAdditionalRefered.rate_info?.prices?.ipsi_10?.price;
                        actualRate.rate_info.ftth_300_price_iva = foundAdditionalRefered.rate_info?.prices?.iva?.price;
    
                        actualRate.rate_info.ftth_600_price = foundAdditionalRefered.rate_info?.ftth_600_price;
                        actualRate.rate_info.ftth_600_price_igic = foundAdditionalRefered.rate_info?.prices?.igic?.ftth_600_price;
                        actualRate.rate_info.ftth_600_price_ipsi_8 = foundAdditionalRefered.rate_info?.prices?.ipsi_8?.ftth_600_price;
                        actualRate.rate_info.ftth_600_price_ipsi_10 = foundAdditionalRefered.rate_info?.prices?.ipsi_10?.price;
                        actualRate.rate_info.ftth_600_price_iva = foundAdditionalRefered.rate_info?.prices?.iva?.price;
    
                        actualRate.rate_info.price = foundAdditionalRefered.rate_info?.real_price;
                        actualRate.rate_info.price_igic = foundAdditionalRefered.rate_info?.prices?.igic?.price;
                        actualRate.rate_info.price_ipsi_8 = foundAdditionalRefered.rate_info?.prices?.ipsi_8?.price;
                        actualRate.rate_info.price_ipsi_10 = foundAdditionalRefered.rate_info?.prices?.ipsi_10?.price;
                        actualRate.rate_info.price_iva = foundAdditionalRefered.rate_info?.prices?.iva?.price;
                    }
                }
            }
        // });
        // this.updateRatesMobiles();
        return actualRate;
    }
    
    
    evaluateRateFtth(allRates, rateFtth, show_ftth_reduced) {
        const foundFtth = allRates.FTTH_NEW.find(ftth => ((ftth?.type_discount?.rate_id === rateFtth?.rate_id) || (ftth?.type_normal?.rate_id === rateFtth?.rate_id)));
        if (foundFtth) {
            if ((foundFtth.type_discount?.rate_id === rateFtth?.rate_id && show_ftth_reduced)
            || (foundFtth.type_normal?.rate_id === rateFtth?.rate_id && show_ftth_reduced)) {
                rateFtth.rate_info.rate_id = foundFtth.type_discount.rate_id;
                rateFtth.rate_info.contracted_price = foundFtth.type_discount.price;
                rateFtth.rate_info.price = foundFtth.type_discount.price;
                rateFtth.rate_info.price_igic = foundFtth.type_discount.prices.igic.price;
                rateFtth.rate_info.price_ipsi_8 = foundFtth.type_discount.prices.ipsi_8.price;
                rateFtth.rate_info.price_ipsi_10 = foundFtth.type_discount.prices.ipsi_10.price;
                rateFtth.rate_info.price_iva = foundFtth.type_discount.prices.iva.price;
                foundFtth.rate_id = foundFtth.type_discount.rate_id;
                foundFtth.rate_info.price = foundFtth.type_discount.price;
            } 
            if ((foundFtth.type_normal?.rate_id === rateFtth?.rate_id && !show_ftth_reduced)
            || (foundFtth.type_discount?.rate_id === rateFtth?.rate_id && !show_ftth_reduced)) {
                rateFtth.rate_info.contracted_price = foundFtth.type_normal.price;
                rateFtth.rate_info.price = foundFtth.type_normal.price;
                rateFtth.rate_info.price_igic = foundFtth.type_normal.prices.igic.price;
                rateFtth.rate_info.price_ipsi_8 = foundFtth.type_normal.prices.ipsi_8.price;
                rateFtth.rate_info.price_ipsi_10 = foundFtth.type_normal.prices.ipsi_10.price;
                rateFtth.rate_info.price_iva = foundFtth.type_normal.prices.iva.price;
                foundFtth.rate_id = foundFtth.type_normal.rate_id;
                foundFtth.rate_info.price = foundFtth.type_normal.price;
            }
            this.simyoService.setRateFtth(foundFtth);
            this.simyoService.getRateFtth()
            .pipe(take(1))
            .subscribe((data: any) => {
                this.orderService.setBroadbandRate(data);
            });
        }
    }
}