<ng-container *ngIf="(orange$ | async) as orange">
    <div id="userDataForm" class="container content" [formGroup]="userDataFormGroup">
        <h5 class="title-step text-center" >
            <img src="./assets/img/users.svg" class="img-fluid ico-title" width="20"> Datos personales
        </h5>
        <div class="card">
            <ul class="list-group list-group-flush">
                <li class="list-group-item cuerpo-pack">
                    <div class="row">
                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('document_type', ['required'])}">
                            <!-- <mat-form-field class="pt-1">
                                <mat-label>Tipo documento</mat-label>
                                <select matNativeControl required formControlName="document_type" [ngModel]="orange?.user_data_form_values.document_type"
                                        (ngModelChange)="changeTypeDocument($event); setMaxLengthByDoc($event);">
                                    <option *ngFor="let documentType of orange?.master?.document_types" [ngValue]="documentType" [hidden]="documentType.name === 'CIF' || documentType.name === 'Otro'">
                                        {{ documentType.name }}
                                    </option>
                                </select>
                                <mat-error *ngIf="checkErrorFieldForm('document_type', ['required'])">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field> -->
                            <digo-select
                                id="document"
                                label="Tipo documento"
                                [formGroup]="userDataFormGroup"
                                controlName="document_type"
                                [options]="document_types"
                                optionValue="document_type_id"
                                optionLabel="name"
                                (OnChange)="changeTypeDocument($event.target.value);setMaxLengthByDoc($event.target.value);"
                                />

                        </div>

                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('document_number', ['required', 'doc'])}">
                           <!--  <mat-form-field>
                                <mat-label>Nº de documento</mat-label>
                                <input matInput type="text" formControlName="document_number" [maxLength]="maxLengthByDoc" required [ngModel]="orange?.user_data_form_values.document_number" (keypress)="alphanumericOnly($event)">
                                <mat-error *ngIf="checkErrorFieldForm('document_number', ['required'])">
                                    Campo obligatorio.
                                </mat-error>
                                <mat-error *ngIf="checkErrorFieldForm('document_number', ['required']) == false && checkErrorFieldForm('document_number', ['doc', 'pattern'])">
                                    Número de documento erróneo
                                </mat-error>
                            </mat-form-field> -->
                            <digo-input
                                id="document_number"
                                label="Nº de documento"
                                [formGroup]="userDataFormGroup"
                                controlName="document_number"
                                isRequired="true"
                                type="text"
                                (keypress)="alphanumericOnly($event)"
                                [maxlength]="maxLengthByDoc"
                            />
                        </div>

                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('nacionality', ['required'])}">
                            <!-- <mat-form-field class="pt-1">
                                <mat-label>Nacionalidad</mat-label>
                                <select matNativeControl required formControlName="nacionality" [ngModel]="(orange$ | async)?.user_data_form_values.nacionality">
                                    <ng-container *ngFor="let country of countries">
                                        <option [ngValue]="null" hidden></option>
                                        <option [ngValue]="country">
                                            {{ country.name }}
                                        </option>
                                    </ng-container>
                                </select>
                                <mat-error *ngIf="checkErrorFieldForm('nacionality', ['required'])">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field> -->
                            <digo-select
                                id="country"
                                label="Nacionalidad"
                                [formGroup]="userDataFormGroup"
                                controlName="nacionality"
                                [options]="countries"
                                isRequired="true"
                                optionValue="country_id"
                                optionLabel="name"
                                />
                        </div>

                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('birth_date', ['required', 'date_minimum','date_maximum'])}">
                           <!--  <app-datepicker-custom
                                title="Fecha de nacimiento"
                                [form]="userDataFormGroup"
                                name="birth_date"
                                ></app-datepicker-custom> -->
                                <digo-datepicker
                                    id="birth_date"
                                    label="Fecha de nacimiento"
                                    [formGroup]="userDataFormGroup"
                                    controlName="birth_date"
                                />
                        </div>

                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('name', ['required','blankSpace'])}">
                           <!--  <mat-form-field>
                                <mat-label>Nombre</mat-label>
                                <input matInput type="text" required formControlName="name" [ngModel]="orange?.user_data_form_values.name">
                                <mat-error *ngIf="checkErrorFieldForm('name', ['required','blankSpace'])">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field> -->
                            <digo-input
                                id="name"
                                label="Nombre"
                                [formGroup]="userDataFormGroup"
                                controlName="name"
                                isRequired="true"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('first_surname', ['required','blankSpace'])}">
                            <!-- <mat-form-field>
                                <mat-label>Primer apellido</mat-label>
                                <input matInput type="text" required formControlName="first_surname" [ngModel]="orange?.user_data_form_values.first_surname">
                                <mat-error *ngIf="checkErrorFieldForm('first_surname', ['required','blankSpace'])">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field> -->

                            <digo-input
                                id="first_surname"
                                label="Primer apellido"
                                [formGroup]="userDataFormGroup"
                                controlName="first_surname"
                                isRequired="true"
                                type="text" />
                        </div>

                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('second_surname', ['required'])}">
                            <!-- <mat-form-field>
                                <mat-label>Segundo apellido</mat-label>
                                <input matInput type="text" formControlName="second_surname" [ngModel]="orange?.user_data_form_values.second_surname">
                                <mat-error *ngIf="checkErrorFieldForm('second_surname', ['required'])">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field> -->
                            <digo-input
                                id="second_surname"
                                label="Segundo apellido"
                                [formGroup]="userDataFormGroup"
                                controlName="second_surname"
                                isRequired="true"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('sex', ['required'])}">
                            <!-- <mat-form-field class="pt-1">
                                <mat-label>Sexo</mat-label>
                                <select matNativeControl required formControlName="sex" [ngModel]="orange?.user_data_form_values.sex">
                                    <option *ngFor="let sex of orange?.master?.genders" [ngValue]="sex">
                                        {{ sex.name }}
                                    </option>
                                </select>
                                <mat-error *ngIf="checkErrorFieldForm('sex', ['required'])">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field> -->
                            <digo-select
                                id="sex"
                                label="Sexo"
                                [formGroup]="userDataFormGroup"
                                controlName="sex"
                                [options]="orange?.master?.genders"
                                optionValue="sex"
                                optionLabel="name"
                                isRequired="true"
                                />
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <h5 class="title-step mt-5 text-center" >
            <img src="./assets/img/users.svg" class="img-fluid ico-title" width="20"> Datos de contacto
            <ng-container>
                <img src="./assets/img/info.svg" class="img-fluid icon-list me-1 cursor-pointer"
                [ngbTooltip]= mailInfo
                [placement]="'right'"
                width="16" tooltipClass="my-custom-class">
            </ng-container>
        </h5>
        <div class="card">
            <ul class="list-group list-group-flush">
                <li class="list-group-item cuerpo-pack">
                    <div class="row">
                        <div class="col-lg-2 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('postal_code', ['required'])}">
                           <!--  <mat-form-field>
                                <mat-label>Código Postal</mat-label>
                                <input matInput type="number" formControlName="postal_code" required pattern="\d+" min="0" max="99999"
                                    (input)="onChangeZip($event.target.value)" [ngModel]="orange?.user_data_form_values.postal_code">
                                <mat-error *ngIf="checkErrorFieldForm('postal_code', ['required'])">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field> -->
                            <digo-input
                                id="codigoPostal"
                                [label]="'Código Postal'"
                                [isRequired]="true"
                                [formGroup]="userDataFormGroup"
                                controlName="postal_code"
                                type="number"
                                min="0"
                                max="99999"
                                pattern="\d+"
                                (OnInput)="onChangeZip($event.target.value)"
                                (OnKeyDown)="onlyPositiveDigits($event)"
                                />
                        </div>

                        <div class="col-lg-3 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('province', ['required'])}">
                            <!-- <mat-form-field class="pt-1">
                                <mat-label>Provincia</mat-label>
                                <select matNativeControl formControlName="province" required [ngModel]="orange?.user_data_form_values.province">
                                    <option *ngFor="let province of orange?.master?.provinces" [ngValue]="province">
                                        {{province.name}}
                                    </option>
                                </select>
                                <mat-error *ngIf="checkErrorFieldForm('province', ['required'])">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field> -->
                            <digo-select
                                id="province"
                                label="Provincia"
                                [formGroup]="userDataFormGroup"
                                controlName="province"
                                [options]="orange?.master?.provinces"
                                optionValue="province_id"
                                optionLabel="name"
                                isRequired="true"
                            />
                        </div>

                        <div class="col-lg-7 mt-3" [ngClass]="{'custom-error': checkErrorFieldForm('email', ['required','invalid','pattern'])}">
                            <!-- <mat-form-field>
                                <mat-label>
                                    Correo electrónico
                                </mat-label>
                                <input appBlockPaste (input)="onChanges();" (focusout)="isValidEmail()" required matInput type="text" formControlName="email" [ngModel]="orange?.user_data_form_values.email">
                                <span *ngIf="validEmailLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                <mat-error *ngIf="checkErrorFieldForm('email', ['required'])">
                                    {{ mailInfo }}
                                </mat-error>
                                <mat-error *ngIf="checkErrorFieldForm('email', ['required']) == false && checkErrorFieldForm('email', ['invalid'])">
                                    {{ errorEmailValidation || mailInfo }}
                                </mat-error>
                                <mat-error *ngIf="checkErrorFieldForm('email', ['required']) == false && checkErrorFieldForm('email', ['pattern'])">
                                    Formato incorrecto.
                                </mat-error>
                            </mat-form-field> -->

                            <digo-input
                                id="email"
                                label="Correo electrónico"
                                [formGroup]="userDataFormGroup"
                                controlName="email"
                                isRequired="true"
                                type="text"
                            />

                        </div>
                       
                    </div>
                </li>
            </ul>
        </div>

        <h5 class="title-step mt-5 text-center" >
            <img src="./assets/img/check-user.svg" class="img-fluid ico-title" width="20"> Protección de datos
        </h5>
        <div class="card">
            <ul class="list-group list-group-flush">
                <li class="list-group-item cuerpo-pack">
                    <div class="container" [formGroup]="agreementsFormGroup">
                        <div class="area-check">
                            <div class="checkbox icheck-primary">
                                <input type="checkbox" id="check1" class="accept-check" formControlName="check1"/>
                                <label for="check1">
                                    Deseo recibir comunicaciones comerciales de ORANGE ajustadas a mi perfil comercial según los datos derivados del Servicio como tráfico, navegación y localización.
                                </label>
                            </div>

                            <div class="checkbox icheck-primary">
                                <input type="checkbox" id="check2" class="accept-check" formControlName="check2"/>
                                <label for="check2">
                                    Deseo recibir publicidad de compañías con las que ORANGE colabora que ofrecen productos y servicios de telecomunicaciones, financieros, seguros, tecnología, energía, audiovisual, seguridad, educación, ocio, automoción, publicidad y gran consumo.
                                </label>
                            </div>

                            <div class="checkbox icheck-primary">
                                <input type="checkbox" id="check3" class="accept-check" formControlName="check3"/>
                                <label for="check3">
                                    Consiento la cesión de mis datos a sociedades participadas y empresas del Grupo Orange, incluido mi perfil comercial, para la remisión por su parte de acciones comerciales personalizadas (el listado completo de empresas y su actividad está disponible en www.orange.es.
                                </label>
                            </div>

                            <div class="checkbox icheck-primary">
                                <input type="checkbox" id="check4" class="accept-check" formControlName="check4"/>
                                <label for="check4">
                                    Consiento la elaboración de un perfil comercial, basado en información proveniente de terceros, que permita a ORANGE la adopción de decisiones automatizadas.
                                </label>
                            </div>

                            <div class="checkbox icheck-primary">
                                <input type="checkbox" id="check5" class="accept-check" formControlName="check5"/>
                                <label for="check5">
                                    Consiento la comunicación de mis datos, incluido mi perfil comercial, a compañías con las que Orange colabora en los siguiente sectores: telecomunicaciones, financieros, seguros, tecnología, energía, audiovisual, seguridad, educación, ocio, automoción, publicidad y gran consumo, para que puedan facilitarme información comercial adaptada a mis gustos y preferencias.
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    <!-- Notificación -->
    <div class="alert" *ngIf="showErrorOrder" [ngClass]="{'alert-danger': showErrorOrder}" role="alert">
        <p *ngIf="showErrorOrder">
            {{ showErrorOrderMsg || 'No se ha podido realizar la tramitación. Por favor, inténtelo de nuevo más tarde.' }}
        </p>
    </div>
        <!-- Continuar proceso -->
        <div class=" d-flex justify-content-center">
            <br>
            <button class="btn btn-black d-block w-100 mb-1 pt-3 fw-bold" style="padding-bottom: 20px !important; width: 100% !important;" (click)="next(orange?.orderDraftId)" role="button" [disabled]="isCreateLoading">
                Continuar <img *ngIf="!isCreateLoading" src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="15">
                <span *ngIf="isCreateLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            </button>
            <br><br>
        </div>
    </div>
</ng-container>
