<a *ngIf="showBackToFastSearch" (click)="resetComponent(); backToFastSearch.emit(true)" class="color-primary back-automatic--link">
    <img src="./assets/img/arrow-right-orange.svg" class="img-fluid ico-title back-automatic--img" width="15">
    Volver al buscador
</a>

<myaddress-component
    *ngIf="showComponente"
    showMap="false"
    origin="DIGO"
    [environment]="'PRO'"
    coverage="false"
    hiddenVertical="true"
    (input)="addrInput($event)"
    (addrselected)="addressSelected.emit($event)"
    (addrcreated)="addressCreated($event)"
    (sendInitialized)="sendInitialized($event)"
    (change)="changeAddress($event)"
></myaddress-component>

<div *ngIf="loading" class="loading" align="center" [ngStyle]="{'position': (initialized ? 'absolute' : 'relative')}">
    <img class="" src="./assets/img/spinner-home.svg" width="50" alt="">
</div>
