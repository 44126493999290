import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DigoService } from 'src/app/services/digo.service';

@Component({
  selector: 'app-hm-modal-commissions',
  templateUrl: './hm-modal-commissions.component.html',
  styleUrls: ['./hm-modal-commissions.component.css']
})
export class HmModalCommissionsComponent implements OnInit {
  @Input() pdv: any; 
  @Output() modalClosed = new EventEmitter<void>();

  isSendingCommissions = false;
  hasSentCommissions = false;
  errorMessage: string | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    private digoService: DigoService
  ) {}

  ngOnInit() {}

  sendCommissionsLink() {
    if (this.isSendingCommissions || this.hasSentCommissions) return;

    this.isSendingCommissions = true;
    this.errorMessage = null;

    const data = {
      idPdv: this.pdv.info.idpdv,
      contactEmail: this.pdv.info.email
    };

    this.digoService.sendCommisionsContract(data)
      .pipe(
        tap(() => this.hasSentCommissions = true), 
        catchError(error => {
          this.errorMessage = 'Hubo un problema al enviar la solicitud. Inténtelo de nuevo.';
          return of(null); 
        }),
        finalize(() => this.isSendingCommissions = false)
      )
      .subscribe();
  }

  closeModal() {
    this.activeModal.dismiss();
    this.modalClosed.emit(); // Notifica al padre que el modal se cerró
  }
}