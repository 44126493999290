<section class="procesos">
    <div class="row my-2">
        <!-- Mensaje informativo -->
        <div class="col-xl-12 mt-2" *ngIf="pdv?.info?.document_type && (pdv?.info?.document_type === 'DNI' || pdv?.info?.document_type === 'CIF' || pdv?.info?.document_type === 'NIE')">
            <div class="col-xl-12 mb-2 info-notify" style="border: 1px solid;">
                <p class="mt-3" style="font-size: 0.9rem; padding: 0 15px;" *ngIf="pdv?.info?.document_type === 'DNI' || pdv?.info?.document_type === 'CIF'">
                    Es muy importante que si cambia algún dato de tu punto de venta, nos lo comuniques a la mayor brevedad <b>para evitar incidencias ante la Agencia Tributaria.</b> Los datos más importantes son: {{ pdv?.info?.document_type }}, nombre, dirección, actividad económica y datos bancarios.
                </p>
                <p class="mt-3" style="font-size: 0.9rem;" *ngIf="pdv?.info?.document_type === 'NIE'">
                    En caso de que <b>tu NIE cambie o se convierta en DNI</b>, es importante que nos lo comuniques con urgencia <b>para evitar incidencias y sanciones ante la Agencia Tributaria.</b> Te recordamos que también deberás de avisarnos si cambian otros datos como por ejemplo tu nombre, dirección o datos de pago.
                </p>
            </div>
        </div>

        <!-- Simyo rappel -->
        <div class="col-xl-8 my-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
            <ng-container *ngIf="rappelSimyoData">
                <div class="card-footer--sidebar margin--tramos" *ngIf="rappelSimyoData.mensual">
                    <div *ngIf="rappelSimyoData.mensual.current">
                        <div class="row">
                            <div class="col-6">
                                <span>
                                    <b style="font-size: 15px;">Tramo mensual: <b class="orange">{{ rappelSimyoData.mensual?.current?.text || 'Sin categoría' }}</b></b>
                                    <br>
                                    <b class="orange mt-2" style="display: inline-flex; width: 90px; font-size: 14px;">{{ monthNames[today.month()] }} </b>
                                    <span class="badge badge-warning-orange">{{ rappelSimyoData.mensual.current.current_activations }} activaciones</span>
                                </span>
                                <br>
                            </div>
                            <div class="col-6 pr-0 pl-0" align="right" style="text-align: end;">
                                <img src="./assets/img/sm-login-color.svg" class="img-fluid logo--tarifa mr-1" width="70" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                                <img src="./assets/img/jz-login-color.svg" class="img-fluid logo--tarifa mb-1 mr-2" width="70" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                                <img src="./assets/img/mm-login-color.svg" class="img-fluid logo--tarifa mr-2" style="margin-bottom: 1px;" width="70" *ngIf="permSv.hasPermFromV2(allPermissions.masmovil.vende_masmovil)">
                                <img src="./assets/img/pp-login-color.svg" class="img-fluid logo--tarifa mb-1" width="90" *ngIf="permSv.hasPermFromV2(allPermissions.pepephone.vende_pepephone)">
                                <img src="./assets/img/gk-login-color.svg" class="img-fluid logo--tarifa" width="70" *ngIf="permSv.hasPermFromV2(allPermissions.guuk.vende_guuk)">
                            </div>
                        </div>
                        <div class="progress mt-3 pro-com">
                            <div class="progress-bar progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{ 'width.%': getWidth(rappelSimyoData.mensual) }" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <small class="text-muted" *ngIf="rappelSimyoData?.mensual.current?.next_level">
                            Te quedan <b>{{ rappelSimyoData.mensual.current.next_level.min_activations - rappelSimyoData.mensual.current.current_activations }}</b> activaciones para pasar al tramo mensual <span
                            class="orange">{{ rappelSimyoData.mensual.current.next_level.text }}</span>
                        </small>
                        <ng-container *ngIf="today?.date() > dayToShowCategoryRappelPreviousMonth">
                            <br>
                            <small class="text-muted">
                                Categoría mes anterior <b class="orange">{{ (pdv.info.clasificacion || 'Sin categoría')|titlecase }}</b>
                            </small>
                        </ng-container>
                    </div>
                </div>

                <div class="card-footer--sidebar" *ngIf="rappelSimyoData.bimensual">
                    <div *ngIf="rappelSimyoData.bimensual.current">
                        <div class="row">
                            <div class="col-6">
                                <span>
                                    <b style="font-size: 15px;">Tramo bimensual: <b class="orange">{{ rappelSimyoData.bimensual?.current?.text || 'Sin categoría' }} </b></b>
                                    <ng-container *ngIf="rappelSimyoData.bimensual.current.activations_per_month | keyvalue: keyvalueOriginalOrder as months">
                                        <ng-container *ngFor="let month of months; let i = index">
                                            <br>
                                            <b [ngClass]="{'orange': true, 'mt-1': i===0}" style="display: inline-flex; width: 90px; font-size: 14px;">{{ month.key }} </b>
                                            <span  [ngClass]="{'badge': true, 'badge-warning-orange': rappelSimyoData.bimensual.current.are_there_activations_in_both_months, 'badge-warning-grey': !rappelSimyoData.bimensual.current.are_there_activations_in_both_months}">
                                                {{ month.value }} activaciones
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </span>
                                <br>
                            </div>
                            <div class="col-6 pr-0 pl-0" align="right" style="text-align: end;">
                                <img src="./assets/img/sm-login-color.svg" class="img-fluid logo--tarifa mr-1" width="70" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                                <img src="./assets/img/jz-login-color.svg" class="img-fluid logo--tarifa mb-1 mr-2" width="70" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                                <img src="./assets/img/mm-login-color.svg" class="img-fluid logo--tarifa mr-2" style="margin-bottom: 1px;" width="70" *ngIf="permSv.hasPermFromV2(allPermissions.masmovil.vende_masmovil)">
                                <img src="./assets/img/pp-login-color.svg" class="img-fluid logo--tarifa mb-1" width="90" *ngIf="permSv.hasPermFromV2(allPermissions.pepephone.vende_pepephone)">
                                <img src="./assets/img/gk-login-color.svg" class="img-fluid logo--tarifa" width="70" *ngIf="permSv.hasPermFromV2(allPermissions.guuk.vende_guuk)">
                            </div>
                        </div>
                        <ng-container *ngIf="rappelSimyoData.bimensual.current.are_there_activations_in_both_months">
                            <div class="progress mt-4 pro-com">
                                <div class="progress-bar progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{ 'width.%': getWidth(rappelSimyoData.bimensual) }" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <small class="text-muted" *ngIf="rappelSimyoData?.bimensual.current?.next_level">
                                Te quedan <b>{{ rappelSimyoData.bimensual.current.next_level.min_activations - rappelSimyoData.bimensual.current.current_activations }}</b> activaciones para pasar al tramo bimensual <span
                                class="orange">{{ rappelSimyoData.bimensual.current.next_level.text }}</span>
                            </small>
                        </ng-container>
                        <div *ngIf="!rappelSimyoData.bimensual.current.are_there_activations_in_both_months" class="mt-2">
                            <div class="progress pro-com" style="margin-top: 35px;">
                                <div class="progress-bar progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <small class="commissions-text-on-progress">
                                <b style="font-size: 12px;">*Recuerda que debes tener activaciones en los dos meses para conseguir el rappel</b>
                            </small>
                        </div>
                        <ng-container *ngIf="today?.date() > dayToShowCategoryRappelPreviousMonth">
                            <div class="mt-2">
                                <small class="text-muted">
                                    Categoría bimensual anterior <b class="orange">{{ (pdv.info.clasificacion_bimensual || 'Sin categoría')|titlecase }}</b>
                                </small>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="!rappelSimyoData && !rappelSimyoDataError">
                <div class="card--sidebar mt-2">
                    <div class="my-5">
                        <h5 class="tar-name-preload text-center">Obteniendo rappel...</h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="card--sidebar mt-5" *ngIf="rappelSimyoDataError">
                <h5 class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
        </div>

        <!-- Mis comisiones -->
        <div class="my-2" [ngClass]="{'col-xl-12': !(permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)),'col-xl-4': permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)}">
            <div [hidden]="commissionsDataError || !commissionsData" style="display: grid; min-height: 100%;">
                <div class="card--sidebar">
                    <div class="card-body" style="height:242px;">
                        <p class="com-bar2 mt-1 mb-0">
                            <b>
                                <img src="./assets/img/euro.svg" class="img-fluid avisos--sidebar--icon" width="16"> Mis comisiones
                            </b>
                        </p>
                        <div style="height:90%;">
                            <canvas #barCanvasCommissions></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card--sidebar mt-2 py-4 px-3" *ngIf="commissionsDataError && !commissionsData">
                <h5 class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
            <div *ngIf="!commissionsDataError && !commissionsData">
                <div class="card--sidebar">
                    <div class="my-5">
                        <h5 class="tar-name-preload text-center">Obteniendo comisiones...</h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Autofacturas -->
        <div class="col-xl-12">
            <div class="card--sidebar mt-2" *ngIf="!autoInvoiceDataError && autoInvoiceData">
                <div class="card-body--sidebar">
                    <div class="row">
                        <div class="col-12 col-md pt-1">
                            <i-feather name="file" class="icon--fe" stroke-width="2.5"></i-feather>
                            <b> Mis autofacturas</b>
                        </div>
                        <div class="col-12 col-md-auto" *ngIf="autoInvoiceData.mr != null">
                            <div (click)="openMRDialog()" class="link-black-factura cursor-pointer">
                                <img src="./assets/img/pdf.svg" width="42" style="position: relative; top: -1px;"> Marcos Retributivos
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer--sidebar">
                    <table mat-table [dataSource]="dataSource" *ngIf="dataSource.length">
                        <!-- Index Column -->
                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> # </th>
                            <td mat-cell *matCellDef="let element; let i = index" class="link-black-factura fw-bold"> {{ i + 1 }} </td>
                        </ng-container>

                        <!-- Nº de factura -->
                        <ng-container matColumnDef="invoice_number">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Nº de factura </th>
                            <td mat-cell *matCellDef="let element"> {{ element.invoice_number }} </td>
                        </ng-container>

                        <!-- Fecha emisión -->
                        <ng-container matColumnDef="issue_date">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Fecha emisión </th>
                            <td mat-cell *matCellDef="let element"> {{ element.issue_date }} </td>
                        </ng-container>

                        <!-- Conceptos/descripción -->
                        <ng-container matColumnDef="concepts">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Conceptos/descripción </th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngFor="let concept of element.concepts" class="col-12 p-0">
                                    {{ concept }}
                                </div>
                            </td>
                        </ng-container>

                        <!-- Periodo facturación -->
                        <ng-container matColumnDef="billing_period">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Periodo facturación </th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngFor="let period of element.billing_period" class="col-12 p-0">
                                    {{ period }}
                                </div>
                            </td>
                        </ng-container>

                        <!-- Importe base -->
                        <ng-container matColumnDef="base_amount">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Importe base </th>
                            <td mat-cell *matCellDef="let element">  {{ element.base_amount }} € </td>
                        </ng-container>

                        <!-- Importe total -->
                        <ng-container matColumnDef="total_amount">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Importe total </th>
                            <td mat-cell *matCellDef="let element"> {{ element.total_amount }} € </td>
                        </ng-container>

                        <!-- Descargas -->
                        <ng-container matColumnDef="download">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Descargas </th>
                            <td mat-cell *matCellDef="let element">
                                <a *ngIf="element.link_pdf" [href]="element.link_pdf" target="_blank">
                                    <img src="./assets/img/pdf.svg" width="42" style="position: relative; top: -1px;">
                                </a>
                                <a *ngIf="element.link_xls" [href]="element.link_xls" target="_blank" class="ms-2">
                                    <img src="./assets/img/xls.svg" width="42" style="position: relative; top: -1px;">
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div *ngIf="!dataSource.length">
                        <h5 class="tar-name-preload text-center">Aún no se ha generado ninguna factura</h5>
                    </div>
                    <div *ngIf="dataSource.length < autoInvoiceData?.invoices?.length">
                        <button class="btn btn-black--outline btn-sm mb-1 mt-4 w-100" (click)="nextPage()">Cargar más</button>
                    </div>
                </div>
                <div class="card--sidebar mt-2">
                    <div class="card-header--sidebar2">
                        <div class="row">
                            <div class="col-12 col-md">
                                <i-feather name="info" class="icon--fe" stroke-width="2.5"></i-feather>
                                <b> ¿Dudas con tus autofacturas?</b>
                            </div>
                            <div class="col-12 col-md-auto">
                                <a (click)="openSupportDialog()" class="link-white cursor-pointer" style="font-weight: 400;">
                                    Ir a soporte <i-feather name="arrow-right" class="icon--fe" stroke-width="2.5"></i-feather>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card--sidebar mt-2 py-4" *ngIf="autoInvoiceDataError && !autoInvoiceData">
                <h5 class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
            <div *ngIf="!autoInvoiceDataError && !autoInvoiceData" class="card--sidebar">
                <div class="my-5">
                    <h5 class="tar-name-preload text-center">Obteniendo autofacturas...</h5>
                    <div class="d-flex justify-content-center">
                        <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- Informe anual -->
        <div class="col-xl-12" *ngIf="!autoInvoiceDataError && autoInvoiceData">
            <div class="card--sidebar mt-2">
                <div class="card-header--sidebar mb-2">
                    <div class="row">
                        <div class="col-12 col-md">
                            <i-feather name="file" class="icon--fe" stroke-width="2.5"></i-feather>
                            <b> Informe anual de operaciones {{ lastYear }} <span class="g-light">(Modelo 347 correspondiente a los importes del pdv <b>{{pdv.info.name}})</b></span></b>
                        </div>
                        <div class="col-12 col-md-auto">
                        <a [href]="autoInvoiceData.anual_operation_report" target="_blank" class="link-black-factura">
                            <img src="./assets/img/pdf.svg" width="42" style="position: relative; top: -1px;"> Descargar
                        </a>
                        </div>
                    </div>

                </div>
                <div class="card-header--sidebar">
                    <div class="row">
                        <div class="col-12 col-md">
                            <i-feather name="file" class="icon--fe" stroke-width="2.5"></i-feather>
                            <b> Informe anual de operaciones {{ lastYear }} <span class="g-light">(Modelo 347 global para el CIF <b>{{pdv.info.empresaNif}}</b>)</span></b>
                        </div>
                        <div class="col-12 col-md-auto">
                        <button class="btn btn-black btn-send btn-sm py-0" (click)="openModalSendReport()">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<ng-template #modalSendReport let-modal>
    <div class="modal-header">
        <h5 class="modal-title white ml-3">Envío del informe anual de operaciones {{ lastYear }} <span class="g-light">(Modelo 347)</span></h5>
        <button type="button" class="close" (click)="modal.dismiss()">
            <span aria-hidden="true"><img src="./assets/img/times.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>
    <div class="modal-body mx-3">
        <div class="row">
            <div class="col-lg-12">
                <p>Se va a enviar el informe a la cuenta de correo vinculada a la empresa ({{pdv.info.empresaEmail}})</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="container">
            <div class="row mt-0 mb-0">
                <div class="col-12 col-sm col-xl d-flex justify-content-end">
                    <button [disabled]="isSendingReport" (click)="modal.close()" class="btn btn-black--outline btn-sm mr-3 px-5">
                       Cancelar
                    </button>
                    <button  [disabled]="isSendingReport" (click)="sendReport347()" class="btn btn-black btn-sm px-5">
                        <span>{{ isSendingReport ? 'Enviando' : 'Enviar'}}</span>
                        <span *ngIf="isSendingReport" class="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <div class="row mt-1 mb-0">
                <div *ngIf="responseSendingReport" class="col-12 col-sm col-xl d-flex justify-content-end">
                    <p class="mb-0 message" [ngClass]="{'send-ok': responseSendingReport.code, 'send-ko': !responseSendingReport.code}">{{responseSendingReport.message}}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

