
<div class="custom-select" [formGroup]="formGroup">
    <select class="select"
    [formControlName]="controlName"
    (change)="OnChange.emit($event)"
    *ngIf="!isObject(listOptionsFormated)">
        <option value="" hidden *ngIf="hasEmptyValue == false"></option>
        <option *ngFor="let option of listOptionsFormated" [value]="parseData(option.key)" >{{option.value}}</option>
    </select>

    <select class="select"
    [formControlName]="controlName"
    (change)="OnChange.emit($event)"
    *ngIf="isObject(listOptionsFormated)">
        <option value="" hidden *ngIf="hasEmptyValue == false"></option>
        <option *ngFor="let option of listOptionsFormated | keyvalue" [value]="parseData(option.key)" >{{option.value}}</option>
    </select>

    <label class="label" [for]="controlName" *ngIf="label !== ''"  [class.active]="allowDefaultEmpty == true || isEmpty == false">{{label}}{{isRequired ? '*': ''}}</label>
    <div class="underline" [ngClass]="{'selected': isFocused}">
        <div class="underline-selected"></div>
    </div>
    <input-error class="error" [id]="id" [control]="control"></input-error>
</div>
