import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from '../../baseComponent';

@Component({
  selector: 'digo-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.css']
})
export class CustomTextareaComponent extends BaseComponent implements OnChanges {

    @Input() maxlength: string;
    @Input() minlength: string;
    @Input() customeMessageError: string;
    @Input() placeholder: string = '';
    @Input() autocomplete: string = 'on';
    @Input() activeBlockPaste = false;
    @Input() rows: number = 2;
    @Input() value: string = '';

    @Output() OnKeyPress = new EventEmitter();
    @Output() OnInput = new EventEmitter();
    @Output() OnKeyDown = new EventEmitter();
    @Output() OnFocusIn = new EventEmitter();
    @Output() OnFocusOut = new EventEmitter();
    @Output() OnAutocompleteSelected = new EventEmitter();
    @Output() OnKeyUpEnter = new EventEmitter();
    @Output() OnClickIcon = new EventEmitter();


    constructor(public formBuilder: FormBuilder) {
        super(formBuilder);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.isDisabled) {
            if(changes.isDisabled.currentValue) {
                this.control?.disable();
            } else {
                this.control?.enable();
            }
        }
        if(this.formGroup && this.controlName) {
            this.control = this.getControl(this.controlName);
            this.control.valueChanges.subscribe(data => {
                this.isEmpty = data == '';
            })
        }
        if(changes.value) {
            this.control.setValue(changes.value.currentValue);
        }
    }

}
