import {
    AfterViewInit,
    Component,
    HostListener,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationStart,
    Router,
} from '@angular/router';
import { catchError, filter, finalize, take, tap } from 'rxjs/operators';
import { DigoService } from './services/digo.service';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './shared/components/alert-dialog/alert-dialog.component';
import { of, Subscription } from 'rxjs';
import { HomeService } from './home/services/home.service';
import * as moment from 'moment/moment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { PermissionService } from './shared/services/permission.service';
import { Ipdv } from './shared/models/pdvResponse';
import { SimyoTemporaryClosureComponent } from './simyo/components/simyo-temporary-closure/simyo-temporary-closure.component';
import { HttpErrorResponse } from '@angular/common/http';
import { allPermissions } from './shared/permissions';
import { BannerComponent } from './home/components/hm-points/modals/banner/banner.component';
import { CookieService } from './home/services/cookie.service';
import { PointsService } from './home/services/points.service';
import { AcceptedPointsProgramComponent } from './home/components/hm-points/modals/accepted-points-program/accepted-points-program.component';
import { tabHome } from './shared/constantes';
import { SimyoAppointmentService } from './simyo/services/simyo-appointment.service';
import { MyaddressService } from './services/myaddress.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HmModalCommissionsComponent } from './home/components/hm-modal-commissions/hm-modal-commissions.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
    @ViewChild('simyoNotAvailableModal') simyoNotAvailableModal: NgbModalRef;
    title = 'nuevoFrontal';
    actualUrl: string;
    pdv: any;
    digoEndpoint: string;
    public loadIframeMode = false;
    public prevUrl: string = '';
    public today = moment();
    public ultimoBlur = null;
    public tiempoInactivo = 0;
    // Fechas para bloquar Simyo
    // public fromSimyoNotAvailable = moment('2021-06-11 20:30:00');
    // public untilSimyoNotAvailable = moment('2021-06-12 09:30:00');
    private allSubscription: Subscription[] = [];
    private simyoUrlsBlocked = [
        '/simyo/convergente',
        '/simyo/solo-movil',
        '/simyo/solo-fibra',
        '/simyo/duplicados',
    ];
    private operationLimitNightUrlsBlocked = [
        '/simyo',
        'republica-movil',
        '/amena',
    ];

    constructor(
        public router: Router,
        public matDialog: MatDialog,
        private permSv: PermissionService,
        private digoService: DigoService,
        private route: ActivatedRoute,
        private homeService: HomeService,
        private modalService: NgbModal,
        private _location: Location,
        private cookieService: CookieService,
        private pointsService: PointsService,
        public simyoAppointmentService: SimyoAppointmentService,
        private myaddressService: MyaddressService
    ) {
        this.myaddressService.interceptFetchRequests();
        this.digoEndpoint = environment.digoEndPoint;

        this.route.queryParams.subscribe((params) => {
            sessionStorage.setItem(
                'showInfoCustomer',
                (params.info_customer != null &&
                    (params.info_customer === '1' ||
                        params.info_customer === 1)) ||
                    (sessionStorage.getItem('showInfoCustomer') &&
                        sessionStorage.getItem('showInfoCustomer') === '1')
                    ? '1'
                    : '0'
            );
        });

        router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationStart ||
                        event instanceof NavigationEnd
                )
            )
            .subscribe((event: any) => {
                if (event instanceof NavigationStart) {
                    // Gestión de los datos si nos movemos de operaciones al detalle de un pedido y viceversa
                    if (
                        !(
                            (this.prevUrl === '/' &&
                                (event.url.includes('simyo/order') ||
                                    event.url.includes(
                                        'orange/bonos-adicionales-order'
                                    ) ||
                                    event.url.includes(
                                        'orange/prepago-order'
                                    ) ||
                                    event.url.includes(
                                        'republica-movil/order'
                                    ))) ||
                            (event.url === '/' &&
                                (this.prevUrl.includes('simyo/order') ||
                                    this.prevUrl.includes(
                                        'orange/bonos-adicionales-order'
                                    ) ||
                                    this.prevUrl.includes(
                                        'orange/prepago-order'
                                    ) ||
                                    this.prevUrl.includes(
                                        'republica-movil/order'
                                    )))
                        )
                    ) {
                        this.homeService.setNavigateFromOperationPosVenta(null);
                        this.homeService.setNavigateFromOperation(null);
                    }
                    this.actualUrl = event.url;
                    if (event.id === 1) {
                        if (!this.actualUrl.includes('jwt')) {
                            this.callPdvData();
                        }
                    } else {
                        this.checkPermissions();
                    }
                }

                if (event instanceof NavigationEnd) {
                    if (
                        this.prevUrl &&
                        this.prevUrl.includes('frame=true') &&
                        !event.url.includes('frame=true')
                    ) {
                        this.router.navigate([event.url], {
                            relativeTo: this.route,
                            queryParams: { frame: true },
                        });
                    }
                    this.prevUrl = event.url;
                }
            });

        route.queryParams.subscribe((params) => {
            if (
                params &&
                params.frame &&
                params.frame === 'true' &&
                this.actualUrl.includes('simyo')
            ) {
                this.loadIframeMode = true;
            }
        });

        this.allSubscription.push(
            this.digoService.getRenewPDV().subscribe((data: any) => {
                if (data != null && data === true) {
                    this.digoService.setRenewPDV(false);
                    this.callPdvData();
                }
            })
        );

        setInterval(() => {
            this.digoService.keepAlive().subscribe({
                next: (resp: any) => {
                    if (!resp || resp !== 200) {
                        this.digoService.goLogin();
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.digoService.goLogin();
                },
                complete: () => {},
            });
        }, 300000); //5 minutos, 300000 milisegundos.

        let appointment_date = localStorage.getItem('appointment');
        if(appointment_date) {
            let appointment = JSON.parse(appointment_date);
            this.simyoAppointmentService
            .cancel(appointment.id, appointment.order_id)
            .pipe(
                finalize(() => {
                    localStorage.removeItem('appointment');
                })
            ).subscribe()
        }
    }

    @HostListener('window:focus', ['$event'])
    onFocus(): void {
        if (this.ultimoBlur !== null && this.ultimoBlur._isValid) {
            this.ultimoBlur = moment().diff(this.ultimoBlur) / 60000;
            if (this.ultimoBlur >= 20) {
                this.digoService.goLogin();
            }
        }
    }

    @HostListener('window:blur', ['$event'])
    onBlur(): void {
        this.ultimoBlur = moment();
    }

    ngAfterViewInit() {
        this.liveQuery('a', 'click', (event, element) => {
            if (element && element.getAttribute('href')) {
                this.checkExternalNavigation(
                    event,
                    element.getAttribute('href')
                );
            }
        });
    }

    callPdvData() {
        this.digoService.getPdvFromService().subscribe((data: Ipdv) => {
            if (data.info.idpdv.toString().includes('XML:')) {
                data.permissions.AMENA = 'TUVO_ACTIVACIONES';
                data.permissions.RENOVACION_ANTICIPADA_BONOS =
                    'BLOQUEAR_RENOVACION_DE_BONOS_ANTICIPADA';
                data.permissions.SIMYO_DUPLICADOS_DE_SIM =
                    'BLOQUEAR_DUPLICADOS';
            }
            this.digoService.setPDV(data);
            this.pdv = data;
            this.checkPermissions();
            if(this.actualUrl == '/') {
                this.showBannerPoints();
            }
            if (this.pdv?.info?.isCSQ) {
                this.router.navigate(['/simyo/solo-movil']);
            }
        });
    }

    checkPermissions() {
        if (this.pdv) {
            if (
                (this.actualUrl.includes('amena') && !this.permSv.hasPermFromV2(allPermissions.amena.permite_activaciones)) ||
                (this.actualUrl.includes('amena') && environment.production && !this.permSv.hasPermFromV2(allPermissions.amena.patch_old_amena)) ||
                (this.actualUrl.includes('republica-movil') && !this.permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas) && !(this.actualUrl.includes('listado') || this.actualUrl.includes('order')) && this.permSv.hasPermFromV2(allPermissions.rep_movil.tuvo_activaciones)) ||
                (this.actualUrl.includes('simyo') && (this.permSv.hasPermFromV2(allPermissions.simyo.bloquea_ventas) || !this.permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal) && !(this.actualUrl.includes('listado') || this.actualUrl.includes('order')) && this.permSv.hasPermFromV2(allPermissions.simyo.tuvo_activaciones)))
            ) {
                this.router.navigate(['/']);
                console.log(
                    'No tiene permisos para ingresar a esta zona. Redirigiendo a Home'
                );
            }
        }

        if (this.simyoUrlsBlocked.includes(this.actualUrl) && this.permSv.hasPermFromV2(allPermissions.simyo.nbss_block)) {
            this.modalService.open(SimyoTemporaryClosureComponent, {size: 'lg', centered: true, backdrop: 'static'});
            this.router.navigate(['/']);
        }
        /* Bloquear Simyo durante un periodo de tiempo limitado
        if (
            this.today.isSameOrAfter(this.fromSimyoNotAvailable) && this.today.isBefore(this.untilSimyoNotAvailable) &&
            (this.actualUrl === '/simyo/solo-movil' ||
            this.actualUrl === '/simyo/convergente' ||
            this.actualUrl === '/simyo/solo-fibra' ||
            this.actualUrl === '/simyo/tarifas')
        ) {
            this.modalService.open(this.simyoNotAvailableModal, {size: 'md', centered: true, backdrop: 'static', keyboard : false});
        }
        */

        //Modal de aviso de comisiones
        if(this.actualUrl === '/'  && this.permSv.hasPermFromV2(allPermissions.others.comissions_permitir)){
            this.allSubscription.push(
                this.digoService.checkPdvCommisionsContract(this.pdv.info.idpdv)
                    .pipe(
                        tap((result: any) => {
                            if(result.msg == 'ok') {
                                this.openCommissionsModal();
                            }
                        }),
                        catchError(error => {
                            console.error("Error al validar con el endpoint:", error);
                            return of(false);
                        })
                    ).subscribe()
            );
        }

       if(this.permSv.hasPermFromV2(allPermissions.others.access_night) === false && this.operationLimitNightUrlsBlocked.some(url => this.actualUrl.includes(url))){
            this.router.navigate(['/']);
        }
    }

    checkExternalNavigation(event, url) {
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo) && url.includes(this.digoEndpoint) && !url.includes('signin.itx?ac=999')) {
            let message = 'Usuario demostración. Función no disponible';

            if (url.includes('seguridad.itx')) {
                message =
                    'Usuario demostración. No se puede cambiar la contraseña';
            }

            event.preventDefault();
            this.matDialog.open(AlertDialogComponent, {
                data: { message },
            });
        }
    }

    liveQuery(selector, eventType, callback, context = null) {
        (context || document).addEventListener(
            eventType,
            (event) => {
                const nodeList = document.querySelectorAll(selector);
                const matches = [];

                nodeList.forEach((nodeItem) => matches.push(nodeItem));

                if (matches) {
                    let element = event.target;
                    let index: any = -1;

                    while (
                        element &&
                        (index = matches.indexOf(element) === -1)
                    ) {
                        element = element.parentElement;
                    }

                    if (index > -1) {
                        callback.call(element, event, element);
                    }
                }
            },
            false
        );
    }

    backClicked() {
        this._location.back();
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map((subs) => subs.unsubscribe());
        }
    }

    showBannerPoints() {
        if(this.permSv.hasPermFromV2(allPermissions.others.access_points) && this.pointsService.checkIsAcceptedPointsProgram(this.pdv.info.points_terms) === false) {
            let cookieShowBannerPoints = this.cookieService.getCookie('showBannerPoints');
            let showBannerPoints = this.checkIsJson(cookieShowBannerPoints) ? JSON.parse(cookieShowBannerPoints) : {};
            if (showBannerPoints.hasOwnProperty(this.pdv.info.username)) {
                showBannerPoints[this.pdv.info.username] = ++showBannerPoints[this.pdv.info.username];
            } else {
                showBannerPoints[this.pdv.info.username] = 1;
            }
            this.cookieService.setCookie({
                name: 'showBannerPoints',
                value: JSON.stringify(showBannerPoints),
                session: true,
            });
            if (showBannerPoints[this.pdv.info.username] <= 5) {
                const dialogRef = this.matDialog.open(BannerComponent, {
                    backdropClass: 'md-backdrop',
                    maxWidth: 800,
                    panelClass: 'md-container-white',
                    width: '100%',
                });
                dialogRef
                    .afterClosed()
                    .pipe(take(1))
                    .subscribe((result) => {
                        if(result === true) this.homeService.setGoToTab(tabHome.PUNTOS);
                    });
                return;
            }
        }
    }

    private checkIsJson(data) {
        const jsonRegex = /^\s*{\s*".*"\s*:\s*.*\s*}\s*$/;
        return jsonRegex.test(data);
    }

    public hasOneDayPassed(lastShownTime: string): boolean {
        const lastShownDate = new Date(lastShownTime);
        const now = new Date();
        const timeDiff = now.getTime() - lastShownDate.getTime();
        return timeDiff > 24 * 60 * 60 * 1000; // 24 horas en milisegundos
    }

    openCommissionsModal() {
        const modalRef = this.modalService.open(HmModalCommissionsComponent, {
            size: 'xl',
            centered: true,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'commissions-modal-width'
        });

        modalRef.componentInstance.pdv = this.pdv; 
    }

    
}
