<ng-container *ngIf="!finished">
    <div id="loading-js" *ngIf="isLoading" class="loading-js">
        <div class="loader">
            <img class="" src="./assets/img/loader.svg" width="60" alt="">
        </div>
    </div>
    <div class="stepline"></div>
    <mat-horizontal-stepper [@.disabled]="true" labelPosition="bottom" #stepper="matHorizontalStepper" class="row simyo-steppper"
                            [linear]="true" [disableRipple]="true" (selectionChange)="onStepChange($event)">
        <ng-template matStepperIcon="edit" let-index="index">{{index + 1}}</ng-template>
        <app-simyo-modal-save></app-simyo-modal-save>
        <mat-step [stepControl]="validateStep()" label="Fibra" state="number"
                  [editable]="isEditable"
                  *ngIf="typeOrder === orderType.CONVERGENT || typeOrder === orderType.BROADBAND">
            <div class="container content">
                <div class="row">
                    <div [class]="typeOrder !== orderType.MOBILE && customerFormGroup.get('customer_doctype').value === '3' ? 'col-md-10 offset-md-1' : 'col-md-8 offset-md-2'">
                        <h5 class="title-step text-center" >
                            <img src="./assets/img/map-pin.svg" class="img-fluid ico-title" width="20">
                            Dirección de instalación
                        </h5>

                        <div *ngIf="!enviroment.production && muestraNormalizacion" (click)="setAddress(selectedTechnology)" class="btn btn-simyo-o2 d-block w-100 mb-1 default-address">
                            <span class="me-2">Dirección por defecto</span>
                            <select class="col-3 me-2" (click)="$event.stopPropagation()" [(ngModel)]="selectedTechnology">
                                <option *ngFor="let technology of arrayTechnologyTypes" [value]="technology">
                                    {{technology}}
                                </option>
                            </select>
                            <img src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="15">
                        </div>

                        <div class="jumbotron-norm" style="margin-bottom: 4rem!important;" [hidden]="!muestraNormalizacion">
                            <app-simyo-normaliza-direccion *ngIf="!enableMyAddress" [formGroup]="normalizadorFormGroup"></app-simyo-normaliza-direccion>
                            <app-simyo-my-address *ngIf="enableMyAddress && showMyAddress" (addressSelected)="myAddressSelected($event)" (backToFastSearch)="backToFastSearch($event)"></app-simyo-my-address>
                            <app-simyo-cobertura [retryCoverageEvents]="retryCoverageSubject.asObservable()" [formGroup]="coberturaFormGroup" [address]="myAddressResponse"></app-simyo-cobertura>
                        </div>

                        <div *ngIf="!muestraNormalizacion">
                            <app-resultado class="simyo"></app-resultado>
                            <div id="Errores_ninguna" *ngIf="muestraErrorPeticion">
                                <!-- No hay cobertura de Simyo -->
                                <div *ngIf="thereIsCoverage === false && !permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                                    <ng-container *ngTemplateOutlet="errorSimyo"></ng-container>
                                </div>
                                <!-- Error al solicitar cobertura Simyo -->
                                <div *ngIf="thereIsCoverage === null">
                                    <ng-container *ngTemplateOutlet="errorSimyo"></ng-container>
                                </div>
                                <!-- No tiene cobertura con Simyo pero vende jazztel -->
                                <div *ngIf="thereIsCoverage === false && permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                                    <ng-container *ngTemplateOutlet="contratacionesJazztel"></ng-container>
                                </div>
                            </div>

                            <div class="mt-2 mb-5" *ngIf="thereIsCoverage === undefined">
                                <h5  class="tar-name-preload text-center">
                                    Consultando cobertura...
                                </h5>
                                <div class="d-flex justify-content-center">
                                    <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
                                </div>
                            </div>

                            <div *ngIf="!clientIsCover && thereIsCoverage && !msgErrorPeticion">
                                <div class="alert alert-success-blue alert-list mb-3 d-flex justify-content-between align-items-center" role="alert">
                                    <div>
                                        Hay <b>{{ fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'cobertura de fibra indirecta' : 'cobertura de fibra' }}</b> para la dirección de instalación
                                        <img *ngIf="!enviroment.production" (click)="setUser()" src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="23">
                                    </div>
                                    <button type="button" class="btn-close btn-close-white ms-auto" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <app-simyo-customer [pdv]="pdv" [formGroup]="customerFormGroup" [resultadoCobertura]="resultadoCobertura"></app-simyo-customer>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="!muestraNormalizacion && thereIsCoverage && clientIsCover && rate == null">
                        <app-simyo-rates [fibraRate]="fibraRateSpeed" [showTypeContent]="showTypeContent" [coberturaFormGroup]="coberturaFormGroup" [normalizadorFormGroup]="normalizadorFormGroup" (requestRemovePostpaidsLines)="removeAllAdditionalPostpaids($event)" (onChangeRate)="changeIsAdditionalIfNeed($event)"></app-simyo-rates>
                    </div>

                    <ng-container *ngIf="!muestraNormalizacion && thereIsCoverage && clientIsCover && rate">
                        <ng-container *ngIf="typeOrder !== 'convergent'; then othersResult else othersResult"></ng-container>
                    </ng-container>

                </div>
            </div>
        </mat-step>

        <mat-step *ngFor="let mobilePackForm of movilPackForms.controls; let i = index "
                  [editable]="isEditable"
                  [stepControl]="mobilePackForm"
                  label="{{ (i + 1) + 'ª línea móvil' }}"
                  state="number">
            <app-simyo-customer *ngIf="!clientIsCover" [pdv]="pdv" [formGroup]="customerFormGroup" [resultadoCobertura]="resultadoCobertura"></app-simyo-customer>
            <app-simyo-movil-pack [index]="i" [formGroup]="mobilePackForm" [clienteFormGroup]="clienteFormGroup" [customerFormGroup]="customerFormGroup" [stepper]="stepper" (requestAddMobileLine)="addMobilePack()" (requestRemovePostpaidsLines)="removeAllAdditionalPostpaids($event)" (requestResetDocument)="requestResetDocument($event)" *ngIf="clientIsCover"></app-simyo-movil-pack>
        </mat-step>

        <mat-step [stepControl]="clienteFormGroup" [editable]="isEditable" label="Cliente" state="number">
            <app-simyo-user-data #tabUserData [formGroup]="clienteFormGroup" [stepper]="stepper" [typeOrder]="typeOrder"></app-simyo-user-data>
        </mat-step>

        <mat-step [editable]="isEditable" label="Confirmación" state="number">
            <ng-template matStepLabel>
                <div class="stepper-header-overlay" (click)="validateUserData()"></div>
                Confirmación
              </ng-template>
            <app-simyo-confirmation [pdv]="pdv" [stepper]="stepper" [orderCreateIsFinished]="orderCreateIsFinished" [movilPackForms]="movilPackForms" [clienteFormGroup]="clienteFormGroup" [fibraFormGroup]="fibraFormGroup" [signatureFormGroup]="signatureFormGroup" (onActivationRequest)="onActivationRequest($event)" (onActivationLoader)="activationLoader($event)"></app-simyo-confirmation>
        </mat-step>
    </mat-horizontal-stepper>
</ng-container>
<ng-container *ngIf="finished">
    <app-simyo-finished [orderLoader]="orderLoader" [signatureFormGroup]="signatureFormGroup"></app-simyo-finished>
</ng-container>


<!-- PLANTILLAS -->
<ng-template #errorSimyo>
    <div class="jumbotron-norm p-4">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div [ngClass]="{'col-8': thereIsCoverage === null, 'col-12': thereIsCoverage === false}" [innerHTML]="msgErrorPeticion" style="align-self: center; font-size: 1.1rem;"></div>
                    <div class="col-4 d-flex justify-content-center" *ngIf="thereIsCoverage === null">
                        <div class="btn-group d-flex justify-content-center">
                            <button (click)="retryCheckCoverage()" class="btn btn-black--outline btn-sm">Comprobar cobertura</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #contratacionesJazztel>
    <div class="jumbotron-norm p-4">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-3 d-flex justify-content-center" style="align-self: center;" >
                        <img src="./assets/img/jz-login-color.svg" class="img-fluid" width="200">
                    </div>
                    <div class="col-9">
                        <div class="card-body--digo">
                            <h5 class="mb-0">Lo sentimos, no tienes cobertura</h5>
                            <h5>de fibra a través de la red de Simyo, anímate e inténtalo en Jazztel.</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center" >
                <a href="https://pangea.orange.es" target="_blank" class="btn btn-black--outline mb-3">
                    Acceder a contrataciones {{ pdv?.info?.usuariopangea && pdv?.info?.usuariopangea !== '' ? '(' + pdv?.info?.usuariopangea + ')' : '' }}
                </a>
            </div>
        </div>
    </div>
</ng-template>

<!-- No convergente -->
<ng-template #othersResult>
    <div class="col-lg-12 mt-3">
        <h5 class="title-step text-center" >
            <img src="./assets/img/package.svg" class="img-fluid ico-title" width="20">
            Tarifa fibra
        </h5>
        <div class="card card-default card-horizontal mb-3">
            <div class="card-heading">
                <div class="row">
                    <div class="col-lg-6 align-self-center">
                        <h5  class="tar-name car-tar-1">
                            {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'FIBRA IND ' : 'FIBRA ') + rate?.name }}
                        </h5>
                    </div>
                    <div class="col-lg-6 align-self-center">
                        <h5  class="tar-price car-tar-2">{{ +rate?.rate_info?.price|toFixedDecimals }}<span>€/mes</span></h5>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5 align-self-center">
                        <h5  class="tar-name-5 pt-1" *ngIf="rate.name">
                            <b>{{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + rate.name }}</b>
                        </h5>
                    </div>
                    <div class="col-md-auto"><span class="vl mobile"></span></div>
                    <div class="col-md-5 align-self-center">
                        <h5  class="tar-name-5 pt-1">
                            <b>INSTALACIÓN GRATIS</b>
                        </h5>
                        <h5  class="tar-name-5 pt-1">
                            <b>ROUTER EN CESIÓN GRATIS</b>
                        </h5>
                    </div>
                    <div class="col-md-1 btn-change align-self-center">
                        <a class="btn btn-simyo-a btn-sm" (click)="changeRate(orderType.BROADBAND)">Cambiar</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <br>
            <a (click)="next()" class="btn btn-simyo-o2 d-block w-100 mb-1" role="button">
                Continuar <img src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="15">
            </a>
            <br>
        </div>
    </div>
</ng-template>
