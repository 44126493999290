<div class="animated fadeIn delay-1s"  *ngIf="resultadoCobertura !== null">
    <!----- Dirección de la normalización ------->
    <div class="jumbotron-dir">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <!----- Dirección ------->
                <span class="dir-text">
                    {{resultadoCobertura.get('tipoViaCobertura').value}} {{resultadoCobertura.get('nombreViaCobertura').value}}
                    <span *ngIf="resultadoCobertura.get('numeroCobertura').value && resultadoCobertura.get('numeroCobertura').value !== 'No tiene'">
                         Número {{resultadoCobertura.get('numeroCobertura').value}}
                    </span>
                    <span *ngIf="resultadoCobertura.get('plantaCobertura').value && resultadoCobertura.get('plantaCobertura').value !== 'No tiene'">
                         {{ includeFloor(resultadoCobertura.get('plantaCobertura').value) }} {{ isNumber(resultadoCobertura.get('plantaCobertura').value) }}
                    </span>
                    <span *ngIf="resultadoCobertura.get('portalCobertura').value && resultadoCobertura.get('portalCobertura').value !== 'No tiene'">
                         Portal {{resultadoCobertura.get('portalCobertura').value}}
                    </span>
                    <span *ngIf="resultadoCobertura.get('bisCobertura').value && resultadoCobertura.get('bisCobertura').value !== 'No tiene'">
                         Bis {{resultadoCobertura.get('bisCobertura').value}}
                    </span>
                    <span *ngIf="resultadoCobertura.get('bloqueCobertura').value && resultadoCobertura.get('bloqueCobertura').value !== 'No tiene'">
                         Bloque {{resultadoCobertura.get('bloqueCobertura').value}}
                    </span>
                    <span *ngIf="resultadoCobertura.get('escaleraCobertura').value && resultadoCobertura.get('escaleraCobertura').value !== 'No tiene'">
                         Escalera {{resultadoCobertura.get('escaleraCobertura').value}}
                    </span>
                    <span *ngIf="resultadoCobertura.get('tipoPuertaCobertura').value && resultadoCobertura.get('tipoPuertaCobertura').value !== 'No tiene'">
                         Tipo puerta {{resultadoCobertura.get('tipoPuertaCobertura').value}}
                    </span>
                    <span *ngIf="resultadoCobertura.get('puertaCobertura').value && resultadoCobertura.get('puertaCobertura').value !== 'No tiene'">
                         Puerta {{resultadoCobertura.get('puertaCobertura').value}}
                    </span>
                </span>
                <br>
                <!----- CP / Ciudad ------->
                <span class="dir-text2">{{resultadoCobertura.get('codigoPostalCobertura').value}} {{resultadoCobertura.get('localidadCobertura').value}}</span>
                <br>
                <!----- Provincia ------->
                <span class="dir-text2">{{provinceNameSimyo ? provinceNameSimyo : resultadoCobertura.get('provinciaCobertura').value}}</span>
            </div>
            <small class="text-muted ml-4" *ngIf="!(migrationData && migrationData.migrationFibra)">
                <a class="btn btn-green btn-sm" (click)="onEdit()" role="button">Cambiar dirección</a>
            </small>
        </div>
    </div>
</div>
