import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {JwtComponent} from './core/components/jwt/jwt.component';

const routes: Routes = [
    {path: 'jwt/:jwt', component: JwtComponent},
    {path: 'jwt/:jwt/:brand', component: JwtComponent},
    {path: 'jwt/:jwt/:brand/:state', component: JwtComponent},
    { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
   // { path: 'amena', loadChildren: () => import('./amena/amena.module').then(m => m.AmenaModule) },
    { path: 'republica-movil', loadChildren: () => import('./republica-movil/republica-movil.module').then(m => m.RepublicaMovilModule) },
    { path: 'simyo', loadChildren: () => import('./simyo/simyo.module').then(m => m.SimyoModule) },
    { path: 'orange', loadChildren: () => import('./orange/orange.module').then(m => m.OrangeModule) },
    { path: 'masmovil', loadChildren: () => import('./masmovil/masmovil.module').then(m => m.MasmovilModule) },
    { path: 'pepephone', loadChildren: () => import('./pepephone/pepephone.module').then(m => m.PepephoneModule) },
    { path: 'guuk', loadChildren: () => import('./guuk/guuk.module').then(m => m.GuukModule) },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
