<div class="custom-input" *ngIf="type !== TypeInput.maps" [formGroup]="formGroup">
    <input
        #inputElement
        class="input"
        [type]="type"
        [id]="id"
        [required]="isRequired"
        [placeholder]="placeholder"
        [name]="controlName"
        [formControlName]="controlName"
        [maxlength]="maxlength"
        [minlength]="minlength"
        [min]="min"
        [max]="max"
        [pattern]="pattern"
        [autocomplete]="autocomplete"
        [mask]="mask" 

        appBlockPaste
        [activeBlockPaste]="activeBlockPaste"

        (focus)="onFocus()"
        (blur)="onBlur()"
        (keypress)="OnKeyPress.emit($event)"
        (keydown)="OnKeyDown.emit($event)"
        (input)="OnInput.emit($event)"
        (keyup.enter)="OnKeyUpEnter.emit($event)">
    <label class="label" [ngClass]="{'with-icon': isSearch, 'active': (isEmpty == false || floatLabel == false), 'no-float': floatLabel == false}" [for]="controlName" *ngIf="label !== '' || labelTemplate">
        <ng-container *ngIf="labelTemplate; else plainLabel">
            <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
          </ng-container>
          <ng-template #plainLabel>
            {{ label }}
          </ng-template>
          {{isRequired ? '*': ''}}
    </label>

    <mat-icon *ngIf="isSearch" (click)="OnClickIcon.emit($event)" class="cursor-pointer icon-search" [ngClass]="{'selected': isFocused}">arrow_right_alt</mat-icon>
    <div class="underline" [ngClass]="{'selected': isFocused}">
        <div class="underline-selected"></div>
    </div>
    <input-error class="error" [id]="id" [control]="control"></input-error>
</div>

<div class="custom-input" *ngIf="type === TypeInput.maps"  [formGroup]="formGroup">
    <input
        class="input"
        type="text"
        [id]="id"
        [required]="isRequired"
        [placeholder]="placeholder"
        [name]="controlName"
        [formControlName]="controlName"

        [maxlength]="maxlength"
        [minlength]="minlength"
        [min]="min"
        [max]="max"
        [pattern]="pattern"
        [autocomplete]="autocomplete"

        matGoogleMapsAutocomplete
        [types]="['address']"
        [country]="country"

        (focus)="onFocus()"
        (blur)="onBlur()"
        (keypress)="OnKeyPress.emit($event)"
        (keydown)="OnKeyDown.emit($event)"
        (input)="OnInput.emit($event)"
        (focusin)="OnFocusIn.emit($event)"
        (focusout)="OnFocusOut.emit($event)"
        (onAutocompleteSelected)="OnAutocompleteSelected.emit($event)">
    <label class="label" [for]="controlName" *ngIf="label !== ''">{{label}}{{isRequired ? '*': ''}}</label>
    <div class="underline" [ngClass]="{'selected': isFocused}">
        <div class="underline-selected"></div>
    </div>
    <input-error [id]="id" [control]="this.control"></input-error>

</div>
